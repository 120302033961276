import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {
    ProductDetailsAction,
    initialState,
    IProductDetailsInfo,
    IProductsColorRelation,
    ColorItem,
    IInitedInfo,
} from "./initialState";
import { getProductDetail } from "@/src/models/products";
import { isNew, jointImg, showPrice } from "@/lib/commonService";
import { OUT_OF_STORE_LIMIT } from "@/config/constConf";
import { getProductMododelImgs } from "@/src/utils/assemblePdctSrc";
import { cloneDeepWith } from "lodash";
interface IColorId {
    colorId: string;
    packageId: number | null;
}
export type IinitProps = IProductDetailsInfo & IColorId;
export type ProductDetailsStore = ReturnType<typeof createProductDetailsStore>;
export const createProductDetailsStore = (initProps?: IinitProps) => {
    let setedOptions: Record<string, any> = {};
    if (initProps) {
        const colorList = getColorList(initProps.products_color_relation);
        const productDetails = init(initProps);
        setedOptions = {
            productDetail: productDetails,
            colorList: colorList,
            ...getActiveColorObj(
                initProps.colorId,
                cloneDeepWith(colorList),
                productDetails,
            ),
            packageId: initProps.packageId,
        };
    }
    const initialProps = { ...initialState, ...setedOptions };
    return create<ProductDetailsAction>()(
        devtools(
            // persist(
            (set, get) => ({
                ...initialProps,
                getProductsDetail: async (id: number) => {
                    const result: IProductDetailsInfo =
                        await getProductDetail(id);
                    const { products_color_relation } = result;
                    set({
                        productDetail: init(result),
                        colorList: getColorList(products_color_relation),
                    });
                },
                changeColor(colorId: string | number, status?: string) {
                    set({
                        ...get(),
                        ...getActiveColorObj(
                            colorId.toString(),
                            cloneDeepWith(get().colorList),
                            get().productDetail,
                            status,
                        ),
                    });
                },
                changeFollow(colorId: string | number) {
                    const activeObj = changeFollow(get().activeObj);
                    const colorList = changeFollowOnList(
                        colorId,
                        cloneDeepWith(get().colorList),
                    );
                    set({ activeObj, colorList });
                },
                setPackgeId(id: number) {
                    set({ packageId: id, isRiskControl: false });
                },
                changeUnderMarket(colorId: string | number) {
                    const activeObj = changeUnderMarket(
                        cloneDeepWith(get().activeObj),
                    );
                    const colorList = changeUnderMarketOnLIst(
                        colorId,
                        cloneDeepWith(get().colorList),
                    );
                    set({ activeObj, colorList });
                },
                changeStatus(colorId: string | number) {
                    const activeObj = changeStatus(
                        cloneDeepWith(get().activeObj),
                    );
                    const colorList = changeStatusOnList(
                        colorId,
                        cloneDeepWith(get().colorList),
                    );
                    set({ activeObj, colorList });
                },
            }),
            // {
            //     name: "product-details-storage",
            //     storage: createJSONStorage(() => sessionStorage),
            // },
            // ),
            {
                name: "product-details-store",
            },
        ),
    );
};

//数据初始化
export function init(
    info: IProductDetailsInfo,
): Partial<IInitedInfo & IProductDetailsInfo> {
    if (!info) {
        return {};
    }

    const {
        products_status,
        products_type,
        master_categories_id,
        products_reviews: review_num,
        products_description_relation,
        products_months_ordered,
        products_date_added_stortime,
        products_sex,
        products_frame,
    } = info;

    const p_under_market = products_status === 0;
    const no_rx = products_type === 2;
    const isNeckglasses = master_categories_id === 12;
    const isSun =
        master_categories_id === 6 ||
        (master_categories_id === 7 && products_type === 1);
    const avg_rating = products_description_relation?.products_avg_rating || 5;

    const is_hot = products_months_ordered >= 50;
    const is_new = isNew(products_date_added_stortime);
    const is_kids = products_sex === 3;

    const rimless = products_frame === "Rimless";
    const try_on_list = info.products_color_relation?.filter(
        (item) => item.try_on == 1 && item.color_status != 0,
    );

    const is_show_3d_try_on = try_on_list.length > 0;
    const { products_name_attribute } = products_description_relation;
    return {
        p_under_market,
        no_rx,
        isNeckglasses,
        review_num,
        avg_rating,
        is_hot,
        is_kids,
        is_new,
        isSun,
        rimless,
        is_show_3d_try_on,
        products_name_attribute,
        meedPackage: !isSun && !rimless,
        ...info,
    };
}

//颜色列表初始化
export function getColorList(colorData: IProductsColorRelation[]) {
    if (!colorData) {
        return [];
    }
    let colorsData = colorData.map((item, index) => {
        const {
            color_name,
            color_id,
            product_price,
            color_number,
            color_qty,
            color_status,
            follow,
            color_name_trans,
            ...rest
        } = item;
        const obj = {
            index,
            name: color_name,
            id: color_id,
            price: product_price,
            colorNum: color_number,
            qty: color_qty,
            status: color_status,
            follow: follow ? !follow?.status : false,
            outOfStore: isOutStore(item),
            is_favorite: item.is_favorite,
            colorUnderMarket: color_status == 0,
            is_notify:
                !product_price.discount_qty_limit &&
                color_qty <= OUT_OF_STORE_LIMIT,
            onsale: showPrice(product_price),
            color_name_trans: color_name_trans || color_name,
        };
        return { ...item, ...obj };
    });
    colorsData.forEach((item, index) => {
        if (item.outOfStore || item.colorUnderMarket) {
            colorsData.splice(index, 1);
            colorsData.push(item);
        }
    });
    return colorsData;
}

//判断售罄
function isOutStore(activeColor: IProductsColorRelation) {
    let res;
    if (!activeColor.product_price) {
        return false;
    }
    const limit = activeColor.product_price.discount_qty_limit;
    const totalStore = activeColor.color_qty;
    res = !(totalStore > limit && totalStore > OUT_OF_STORE_LIMIT);
    return res;
}

//获取颜色对象
export function getActiveColorObj(
    colorId: string,
    colorList: ColorItem[],
    productDetail: Partial<IInitedInfo & IProductDetailsInfo>,
    status?: string,
) {
    let activeObj; // : ColorItem | undefined;
    if (
        colorId &&
        colorList.findIndex((item) => item.id === parseInt(colorId)) >= 0
    ) {
        activeObj = colorList.filter(
            (item) => item.id === parseInt(colorId),
        )[0];
        if (status == "open") {
            if (activeObj?.outOfStore || activeObj?.colorUnderMarket) {
                activeObj = colorList[0];
            } else {
                activeObj = colorList.filter(
                    (item) => item.id === parseInt(colorId),
                )[0];
            }
        } else {
            activeObj = colorList.filter(
                (item) => item.id === parseInt(colorId),
            )[0];
        }
    } else {
        activeObj = colorList[0];
    }

    return {
        activeObj,
        modelImgList: getProductMododelImgs(
            productDetail?.products_id!.toString(), // 大胆点儿，肯定有
            "large",
            activeObj?.products_color_model,
            productDetail.products_model_show || [],
        ),
        productImgList: changeColorImg({
            productId: productDetail.products_id!,
            data: productDetail,
            index: activeObj?.index,
        }),
        video: getVideoResByProductDetail(productDetail) ?? "",
    };
}

// 获取video的方式
export function getVideoResByProductDetail(
    productDetail: Partial<IInitedInfo & IProductDetailsInfo>,
) {
    return productDetail.youtube_url
        ? `https://www.youtube.com/embed/${productDetail.youtube_url}?rel=0&loop=0`
        : "";
}

interface IChangeColorImg {
    productId: number;
    data: any;
    index?: number;
    id?: number;
}
export function changeColorImg({
    productId,
    data,
    index = 0,
    id,
}: IChangeColorImg) {
    const color = data.products_color_relation || [];
    const initBanner: Record<string, any> = color.length
        ? color[id ?? index]
        : {};

    const imgNum = initBanner.color_image_total;
    let bannerData = [];
    for (let i = 1; i <= imgNum; i++) {
        bannerData.push(
            jointImg({
                productId,
                size: "middle",
                imgTotal: i,
                name: initBanner.color_image,
            }),
        );
    }

    return bannerData;
}

//改变订阅状态
function changeFollow(activeObj: Partial<ColorItem>) {
    const { follow, ...rest } = activeObj;
    return { follow: !follow, ...rest };
}

function changeFollowOnList(
    colorId: string | number,
    colorList: ColorItem[] = [],
) {
    colorList.forEach((element) => {
        if (colorId === element.id) {
            element.follow = !element.follow;
        }
    });

    return colorList;
}

//改变下架状态
function changeUnderMarket(activeObj: Partial<ColorItem>) {
    activeObj.colorUnderMarket = !activeObj.colorUnderMarket;
    return activeObj;
}

function changeUnderMarketOnLIst(
    colorId: string | number,
    colorList: ColorItem[] = [],
) {
    colorList.forEach((element) => {
        if (colorId === element.id) {
            element.colorUnderMarket = !element.colorUnderMarket;
        }
    });

    return colorList;
}

//改变售罄状态
function changeStatus(activeObj: Partial<ColorItem>) {
    activeObj.is_notify = !activeObj.is_notify;
    return activeObj;
}

function changeStatusOnList(
    colorId: string | number,
    colorList: ColorItem[] = [],
) {
    colorList.forEach((element) => {
        if (colorId === element.id) {
            element.is_notify = !element.is_notify;
        }
    });

    return colorList;
}
