import React, { CSSProperties, useMemo } from "react";
import classnames from "classnames";
import stl from "./color.module.scss";
import { IStyleProps } from "@/src/app/interface";
import { colorSelectInit } from "@/config/filterConfig";
import GlassColorClient, { IAddtionProps } from "./GlassColorClient";
import { salePercent, showPrice } from "@/lib/commonService";
// import GlassColorClient from "./GlassColorClient";
// TODO：专题页颜色选择

export interface IProductPrice {
    color_id: number;
    discount_expire?: null;

    discount_price: number | string;
    discount_qty_limit: number;
    discount_start?: null;
    price: number | string;
    // ↓这个是产品详情模块的额外属性
    products_id?: number;
    status?: number;
    site_id?: number;
}
export interface IColorItem {
    color_id: number;
    color_image?: string;
    color_name: string;
    color_name_trans?: string;
    frame_image?: string;
    frame_image_total?: number;
    is_favorite?: boolean;
    is_primary?: number;
    product_price?: IProductPrice;
    products_id?: number;
}

export interface ColorData {
    index: number;
    name: string;
    img: string;
    id: number;
    star: number;
    end: number;

    price: number;
    countPrice: number;
    onSale: boolean;
    follow: number;
    color_qty: number;
    discount_qty_limit: number;
    salePer: number;
    is_favorite: boolean;
    color_image: string;
    showDiscount: boolean;
    product_price: any;
}

type GlassColorItem = IColorItem;
interface IProps extends IStyleProps, IAddtionProps {
    scale?: number; // 缩放比例: 部分场景下glassColor的图标大小是比普通的大的，为了统一管理和功能，在此添加scale, 表示缩放比例，传入的缩放比例会被应用成=>原始尺寸 * 缩放比例
}

export function translateIColorDataToIColorItem(
    itemList: ColorData[],
): IColorItem[] {
    return itemList.map((item) => {
        // TODO color_image这里的逻辑需要确认
        const { id, img, name, star, end, ...rest } = item;
        const obj = {
            color_id: id,
            // color_image: img,
            color_name: name,
            discount_start: star,
            discount_expire: end,
            ...rest,
        };
        return obj;
    });
}

// 处理对colorItem结构的转换
export function getColorData(data: any[] = []): ColorData[] {
    const res = [];
    for (let i of data) {
        const onSale = showPrice(i);
        const {
            color_image,
            color_name,
            color_id,
            discount_start,
            discount_expire,
            ...rest
        } = i;
        const obj = {
            index: color_image,
            name: color_name,
            img: color_image,
            id: color_id,
            countPrice: onSale.price,
            onSale: onSale.showDiscount,
            star: discount_start,
            end: discount_expire,
            salePer: i.discount_price
                ? salePercent(i.price, i.discount_price)
                : 0,
            ...rest,
        };
        res.push(obj);
    }
    return res;
}

const GlassColor: React.FC<IProps & GlassColorItem> = (props) => {
    const { colorClass, colorStyle } = useMemo(
        () => colorSelectInit(props.color_name),
        [props.color_name],
    );

    const scaleStyle = useMemo<CSSProperties>(() => {
        return { "--scale": props.scale || 1 } as CSSProperties;
    }, [props.scale]);

    return (
        <GlassColorClient
            selected={props.selected}
            color_id={props.color_id}
            onChange={props.onChange}
            style={scaleStyle}
        >
            <span
                className={classnames(
                    stl["color-item"],
                    `color-class-${colorClass}`,
                    stl[`${colorClass}`],
                    `props-color-${props.color_name}`,
                    stl[props.color_name],
                    `color-id-${props.color_id}`,
                    props.className,
                )}
                style={{
                    ...scaleStyle,
                    ...colorStyle,
                    ...props.style,
                }}
            ></span>
        </GlassColorClient>
    );
};

export default GlassColor;
