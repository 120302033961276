import React, { useCallback, useEffect, useMemo, useState } from "react";
import stl from "./telRegister.module.scss";
import { useTranslation } from "@/src/i18n/client";
import { FmImage, FmInput, FmPicker } from "@/ui-component";
import { getCountries, telephone } from "@/src/models/loginModel";
import classnames from "classnames";
interface IProps {
    sendDataError: (data: any) => void;
    sendDataTelephone: (tel: string) => void;
    sendDataPhStadus: (phstatus: boolean) => void;
    sendDataCountryId: (data: string) => void;
}
interface IActiveCountry {
    countries_id: number;
    countries_iso_code_2: string;
    countries_iso_code_3: string;
    countries_name: string;
    country_sort?: number;
    country_tip?: Record<string, any>;
    flag: string;
    idd_code: number;
    shipping: Record<string, any>[];
    shipping_new?: Record<string, any>[];
    zones: Record<string, any>[];
}
const TelRegister: React.FC<IProps> = (props) => {
    const {
        sendDataError,
        sendDataTelephone,
        sendDataPhStadus,
        sendDataCountryId,
    } = props;
    const { t } = useTranslation(["login", "common"]);
    const [entry_telephone, setEntry_telephone] = useState("");
    const [pickerOpen, setPickerOpen] = useState(false);
    const [country_id, setCountry_id] = useState("");
    const [telephoneError, setTelephoneError] = useState("");

    const [activeCountry, setActiveCountry] = useState<IActiveCountry>({
        countries_id: 0,
        countries_name: "",
        flag: "",
        countries_iso_code_2: "",
        countries_iso_code_3: "",
        idd_code: 0,
        shipping: [],
        zones: [],
    });
    const [countries, setCountries] = useState<any[]>([]);
    const [phStatus, setPhStadus] = useState(false);

    const getCountry = useCallback(() => {
        getCountries().then((arg) => {
            const countries_list = Array.isArray(arg) ? arg : arg.list;
            setCountries(countries_list);
            if (!activeCountry.countries_iso_code_3) {
                setCountry_id(countries_list[0].countries_id);
                setActiveCountry(countries_list[0]);

                sendDataCountryId(countries_list[0].countries_id);
            }
        });
    }, [activeCountry.countries_iso_code_3, sendDataCountryId]);

    useEffect(() => {
        getCountry();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onChange = useCallback(
        (val: string, name: "entry_telephone") => {
            if (!val) {
                sendDataPhStadus(false);
                sendDataError?.("");
            }
            setEntry_telephone(val);
            sendDataTelephone(val);
        },
        [sendDataError, sendDataPhStadus, sendDataTelephone],
    );
    const countriesMemo = useMemo(() => {
        const _countries: any[] = [];
        countries.forEach((element) => {
            element.idd_code &&
                _countries.push({
                    startAdornment: (
                        <>
                            +{element.idd_code}
                            <FmImage
                                image={element.flag}
                                style={{
                                    width: "0.8rem",
                                    marginLeft: "0.1rem",
                                }}
                            />
                        </>
                    ),
                    label: (
                        <div
                            style={{
                                pointerEvents: "none",
                                display: "flex",
                                justifyContent: "space-between",
                                width: "2rem",
                            }}
                            onClick={(e) => {
                                e.stopPropagation(); // 阻止事件传播
                                e.preventDefault();
                            }}
                        >
                            +{element.idd_code}
                            <FmImage
                                image={element.flag}
                                style={{
                                    width: "0.8rem",
                                    marginLeft: "0.1rem",
                                }}
                                height={22}
                                width={44}
                            />
                        </div>
                    ),
                    value: element.countries_iso_code_3,
                });
        });

        return _countries;
    }, [countries]);
    const countrysChange = useCallback(
        (open: boolean, val: string | number) => {
            setPickerOpen(open);
            if (!val) {
                return;
            }
            const activeOne = countries.find(
                (item) => item.countries_iso_code_3 === val,
            );
            setCountry_id(activeOne.countries_id);
            setActiveCountry(activeOne);
            sendDataCountryId(activeOne.countries_id);
        },
        [countries, sendDataCountryId],
    );
    const telephoneBlur = useCallback(() => {
        if (entry_telephone) {
            let params = {
                countryId: country_id,
                telephone: Number(entry_telephone),
            };
            telephone(params)
                .then((res) => {
                    if (res == "验证通过") {
                        setTelephoneError("");
                        setPhStadus(false);
                    } else {
                        setTelephoneError(t("login:error.tel"));
                        setPhStadus(true);
                    }
                    sendDataError?.(telephoneError);
                    sendDataPhStadus(phStatus);
                    sendDataTelephone(entry_telephone);
                })
                .catch((e) => {
                    setTelephoneError(t("login:error.tel"));
                    setPhStadus(true);
                    sendDataError?.(telephoneError);
                    sendDataPhStadus(phStatus);
                    sendDataTelephone(entry_telephone);
                });
        }
    }, [
        country_id,
        entry_telephone,
        phStatus,
        sendDataError,
        sendDataPhStadus,
        sendDataTelephone,
        t,
        telephoneError,
    ]);

    return (
        <FmInput
            startAdornment={
                <FmPicker
                    confirm={t("common:btn._Confirm")}
                    cancel={t("common:btn.cancel")}
                    dataSource={countriesMemo}
                    value={activeCountry.countries_iso_code_3}
                    onChange={countrysChange}
                    open={pickerOpen}
                    classes={{
                        input: stl["hidden-input"],
                        root: stl["picker-input-root"],
                        icon: stl["picker-end-icon"],
                        adornedEnd: "adornedEndadornedEnd",
                    }}
                    inputClasses={{ helperText: "hidden" }}
                    inputClassName={stl["picker-input-content-root"]}
                ></FmPicker>
            }
            value={entry_telephone}
            name={"entry_telephone"}
            onChange={(e) => onChange(e.target.value, "entry_telephone")}
            helperText={telephoneError}
            type={"number"}
            error={!!telephoneError}
            onBlur={telephoneBlur}
            onFocus={() => setTelephoneError("")}
            label={t("common:signIn.PhoneNumber")}
            placeholder={t("common:signIn.PhoneNumber")}
            classes={{
                root: stl["tel-register-root"],
                notchedOutline: classnames(
                    stl["notched-outline"],
                    stl["tel-register-outline"],
                ),
                error: "errorrrrr",
            }}
        />
    );
};

export default TelRegister;
