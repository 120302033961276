import React, { useCallback, useState } from "react";

import { gaReferral, referralClick } from "@/src/utils/gtagEvents";
import { ExtendButton, FmButton, FmImage, FmToast } from "@/ui-component";
import { ISignInModalAction, MODALPAGE } from "@/store/modules/signInModals";
import classnames from "classnames";
import stl from "./userNav.module.scss";
import fbstl from "./fbLogin.module.scss";
import { IStyleProps } from "@/src/app/interface";
import FacebookLoginBt from "./FacebookLogin";
import { useTranslation } from "@/src/i18n/client";
import {
    facebookLogin,
    recordLogin,
    isLogin as checkedLogin,
} from "@/src/models/loginModel";
import { bindTouristId } from "@/src/models/basket";
import { useFBLoading } from "@/src/hooks/useFBLoading";
interface INoLoginProps extends Partial<ISignInModalAction>, IStyleProps {
    userInfo: Record<string, any>;
    isShowFirmooAssets: any;
    loginCallback: () => void;
    openModal: ISignInModalAction["openModal"];
    handleClose: () => void;
    pageChange: (url: string, _url?: Record<string, any>) => void;
    makeMoneyHref: string;
}

const showToastSeconds = 3; // 3秒

const NoLogin: React.FC<INoLoginProps> = (props) => {
    const { t } = useTranslation("common");
    const { openModal, handleClose, loginCallback, pageChange } = props;
    const [toastChildren, setToastChildren] = useState("");
    const [toastShow, setToastShow] = useState(false);
    const { showReTry, showFBloading, onRetry } = useFBLoading();

    // 打开login/register panel
    const handleOpenLoginOrRegisterPanel = useCallback(
        (logincb: () => any, modalPage: number = MODALPAGE.LOGINPANEL) => {
            handleClose();
            openModal(logincb, modalPage);
        },
        [handleClose, openModal],
    );

    const handlePageToMyOrders = useCallback(() => {
        // 登录态 ？ 页面跳转 ： 页面登录(modalPage  === 0) & 页面跳转
        if (checkedLogin()) {
            pageChange("/account");
            return;
        }
        const loginCb = () => {
            pageChange("/account");
        };
        handleOpenLoginOrRegisterPanel(loginCb);
    }, [handleOpenLoginOrRegisterPanel, pageChange]);

    const handleToMyAccount = useCallback(() => {
        // 1.logined ? 去到router路由页面 ： 打开登录页面 & 登录 & 跳转router路由页面
        const logincb = () => {
            window.location.href = "/account/myAccount"; // modalpage.default === 0
        };
        handleOpenLoginOrRegisterPanel(logincb);
    }, [handleOpenLoginOrRegisterPanel]);

    const handleMakeMoney = useCallback(() => {
        const logincb = () => {
            referralClick({
                page: "index",
                is_old_customer: props.userInfo.is_old_customer,
            });
            gaReferral("referral_rentrance_navigation", "top");
            pageChange(props.makeMoneyHref, {});
        };
        handleOpenLoginOrRegisterPanel(logincb);
    }, [
        handleOpenLoginOrRegisterPanel,
        pageChange,
        props.makeMoneyHref,
        props.userInfo.is_old_customer,
    ]);

    const handleError = useCallback(
        (err: any) => {
            const now = Date.now();
            recordLogin(undefined, err || "error", now, now, "1");
            setTimeout(() => {
                handleClose();
            }, showToastSeconds * 1000);
            setToastChildren(t("common:signIn:FBError"));
            setToastShow(true);
        },
        [handleClose, t],
    );

    const handleResponse = useCallback(
        async (data: any) => {
            const now = Date.now();
            try {
                await facebookLogin(data.profile);
                recordLogin(undefined, undefined, now, now, "1");
                await bindTouristId();
                setToastChildren(t("common:signIn:LoginSuccess"));
                setToastShow(true);
                loginCallback();
            } catch (e: any) {
                recordLogin(undefined, e || "error", now, now, "1");
                setToastChildren(e?.message);
                setToastShow(true);
                setTimeout(() => {
                    handleClose();
                }, showToastSeconds * 1000);
            }
        },
        [handleClose, loginCallback, t],
    );
    return (
        <>
            <FmToast
                visible={toastShow}
                seconds={showToastSeconds}
                onChange={(v) => setToastShow(v)}
            >
                {toastChildren}
            </FmToast>
            <div
                className={classnames(
                    stl["user-nav-container"],
                    stl["user-no-login"],
                    "user-no-login",
                    props.className,
                )}
            >
                <div className={stl["user-login-button-container"]}>
                    <FmButton
                        className={classnames(stl["sign-btn"])}
                        onClick={() =>
                            handleOpenLoginOrRegisterPanel(loginCallback)
                        }
                        title={t("common:signInHome")}
                        fullWidth
                        size={"large"}
                    >
                        {t("common:signInHome")}
                    </FmButton>
                    <FmButton
                        className={classnames(stl["sign-btn"])}
                        onClick={() =>
                            handleOpenLoginOrRegisterPanel(loginCallback, 1)
                        }
                        title={t("common:nav:CreateAccount")}
                        variant={"outlined"}
                        fullWidth
                        size="large"
                    >
                        {t("common:nav:CreateAccount")}
                    </FmButton>
                </div>
                <p className={stl["prompt"]}>{t("common:nav:Alternatively")}</p>
                <div className={stl["fb-sign"]}>
                    <FmImage image={"/__firmoo/static/images/pics/FB@2x.png"} />
                    <p className={stl["face-book"]}>
                        {t("common:btn:Facebook")}
                    </p>
                    <div className={stl["facebook-hidden"]}>
                        <FacebookLoginBt
                            scope="email"
                            onSuccess={handleResponse}
                            onError={handleError}
                        />
                    </div>
                    {showFBloading && (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            viewBox="0 0 30 30"
                            className={fbstl[`circle`]}
                        >
                            <circle
                                cx="15"
                                cy="15"
                                r="14"
                                stroke="#4a67a2"
                                strokeWidth="2"
                                fill="transparent"
                                strokeDasharray="61.5752, 26.3894"
                            />
                        </svg>
                    )}
                    {showReTry && (
                        <ExtendButton onClick={onRetry}>
                            <div
                                style={{ marginTop: "3px", marginLeft: "5px" }}
                            >
                                <svg
                                    viewBox="0 0 1024 1024"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    p-id="8650"
                                    width="28"
                                    height="28"
                                >
                                    <path
                                        d="M715.434667 137.898667a42.666667 42.666667 0 1 0-60.330667 60.330666l15.061333 15.104H512a298.666667 298.666667 0 1 0 285.184 387.626667 42.666667 42.666667 0 1 0-81.493333-25.386667A213.333333 213.333333 0 1 1 512 298.666667h168.533333l-20.266666 20.266666a42.666667 42.666667 0 0 0 60.330666 60.330667l87.893334-87.893333a42.666667 42.666667 0 0 0 0-60.373334l-93.056-93.098666z"
                                        fill="#4a67a2"
                                        p-id="8651"
                                    ></path>
                                </svg>
                            </div>
                        </ExtendButton>
                    )}
                </div>
                <div
                    className={classnames(stl["bar"], stl["no-sign-bar"])}
                ></div>
                <div
                    className={classnames(
                        stl["user-nav-list-un"],
                        "user-nav-list-un",
                    )}
                >
                    <FmButton
                        onClick={handleToMyAccount}
                        variant={"text"}
                        className={stl["click-login-drawer-btn"]}
                    >
                        {t("common:nav:MyAccount")}
                    </FmButton>
                    <FmButton
                        onClick={handlePageToMyOrders}
                        variant={"text"}
                        className={stl["click-login-drawer-btn"]}
                    >
                        {t("common:nav:MyOrders")}
                    </FmButton>
                    <FmButton
                        onClick={handleMakeMoney}
                        className={classnames(stl["click-login-drawer-btn"], {
                            ["hidden"]: !props.isShowFirmooAssets,
                        })}
                        variant={"text"}
                    >
                        {t("common:nav:MakeMoney")}
                    </FmButton>
                </div>
            </div>
        </>
    );
};

export default NoLogin;
