import React from "react";
import { FmSkeleton } from "@/ui-component";
import stl from "./SearchArticlesSkeleton.module.scss";

function SearchArticlesItem() {
    return (
        <div className={stl["search-article-item"]}>
            <FmSkeleton variant="rectangular">
                <p>A$9 Glasses | A$9 A Pair Glasses</p>
            </FmSkeleton>
            <FmSkeleton variant="rectangular">
                <p>
                    Only A$9 a pair. We stock a large selection of affordable
                    perscription eyeglasses frames and lenses to help our valued
                    costumer save more!
                </p>
            </FmSkeleton>
            <FmSkeleton variant="rectangular">
                <p>Read more</p>
            </FmSkeleton>
        </div>
    );
}
const SearchArticlesSkeleton: React.FC = () => {
    return (
        <div className={stl["search-article-skeleton-container"]}>
            {Array(2)
                .fill("")
                .map((item, index) => (
                    <SearchArticlesItem key={index} />
                ))}
        </div>
    );
};

export default SearchArticlesSkeleton;
