export interface IReviewAndFq extends ICommonReviewAndFqProps {
    isLoading: boolean;
    askList: any[];
    askNum: number;
    commentList: any[];
    commentNum: number;
    commentTags: any[];
    star: any[];
    commentTagsArtificial: any;
    amount: any;
    starNum: number;
}

interface IReviewsComment {
    master_id: number;
    parent_id: number;
    reviews_rating: number;
    reviews_id: number;
    customers_id: number;
    orders_id: number;
    webp_url?: string;
    date_added: number;
    products_color: string;
    customers_name: string;
    reviews_text: string;
    reply_text: string;
    is_zan: number;
    number_zan: number;
    reply_number: number;
    reply_customer: string;
    reply_info: any[];
    display: number;
    status: number;
    reviewsRead: number;
}

interface ITags {
    name: string;
    id: number;
    number: number;
}

// request请求进来的数据
export interface IRequestReviewAndFq {
    reviews_ask: any[];
    reviews_comment: IReviewsComment[];
    reviews_ask_tag: any[];
    tags: ITags[];
    reviews_comment_tag: ITags[];
    reviews_comment_number: number;
    reviews_ask_number: number;
    reviews_all: number;
    start: {
        amount: number;
        one: number;
        two: number;
        three: number;
        four: number;
        five: number;
    };
}

export interface ICommonReviewAndFqProps {
    pagination: Record<string, any>;
    errors: Record<string, any>;
}

export type State = IReviewAndFq;

export type IReviewAndFqAndAction = State & Action;
export type IRequestAndFqAndAction = IRequestReviewAndFq & Action;
export type Action = {
    getReviewAndFq: (id: number, visitor_id?: string) => Promise<void>;
};

export const initialState: State = {
    commentTags: [],
    askList: [], // 0
    askNum: 0,
    commentList: [], // 0
    commentNum: 0,
    commentTagsArtificial: [],
    isLoading: false,
    star: [],
    amount: 0,
    starNum: 0,
    pagination: {},
    errors: {},
};
