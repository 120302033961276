import { useEffect, useRef, useCallback } from "react";
import { throttle } from "lodash";

// 定义useOptimizedScroll的类型
interface UseOptimizedScrollOptions {
    onScroll?: () => void;
    onScrollEnd?: () => void;
    delay?: number;
}

const useOptimizedScroll = ({
    onScroll,
    onScrollEnd,
    delay = 200,
}: UseOptimizedScrollOptions): void => {
    const isScrolling = useRef<boolean>(false);
    const scrollTimeout = useRef<number | null>(null);

    const handleScroll = useCallback(() => {
        const raf =
            window.requestAnimationFrame ||
            ((callback) => setTimeout(callback, 16));

        if (onScroll) {
            if (!isScrolling.current) {
                isScrolling.current = true;
                raf(() => {
                    onScroll();
                    isScrolling.current = false;
                });
            } else if (!window.requestAnimationFrame) {
                // Fallback to lodash throttle if requestAnimationFrame is not supported
                throttle(onScroll, 16)();
            }
        }

        if (onScrollEnd) {
            if (scrollTimeout.current !== null) {
                clearTimeout(scrollTimeout.current);
            }
            scrollTimeout.current = window.setTimeout(onScrollEnd, delay);
        }
    }, [onScroll, onScrollEnd, delay]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll, { passive: true });

        return () => {
            window.removeEventListener("scroll", handleScroll);
            if (scrollTimeout.current !== null) {
                clearTimeout(scrollTimeout.current);
            }
        };
    }, [handleScroll]);
};

export default useOptimizedScroll;
