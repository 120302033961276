"use client";
import React from "react";
import { ExtendButton, FmBadge } from "@/ui-component";
import classnames from "classnames";
import { loginState, order } from "@/store";
import stl from "./styles/appBar.module.scss";
import { useUserInfoContext } from "@/src/provider/UserInfo/context";
import { headerStore } from "@/store/pageModules";

interface IProps {}
const ShoppingCart: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const isLogin = loginState.useLoginStore((state) => state.isLogin);
    const closeOtherPanels = headerStore.useHeaderStore(
        (state) => state.closeOtherPanels,
    );
    const data = useUserInfoContext((state) => state.data);
    const [totalNum] = order.useOrderStore((state) => [state.totalNum]);
    // const [bascketNum, setBasketNum] = useState(0);
    //获取购物车数量
    // const getBasketTotal = useCallback(async () => {
    //     // 先从session 里面读取如果有那么就直接获取
    //     try {
    //         const bascketNum = await getBascketNum();

    //         setBasketNums(bascketNum);
    //     } catch (e) {
    //         console.error(e);
    //     }
    // }, []);

    // useEffect(() => {
    //     if (!isLogin) {
    //         getBasketTotal();
    //     }
    // }, []);

    return (
        <FmBadge
            badgeContent={
                isLogin && (totalNum || data.baskets_count) !== 0
                    ? totalNum || data.baskets_count
                    : null
            }
            classes={{
                badge: classnames(stl["badge-class"], stl.badge),
            }}
            onClick={() => {
                closeOtherPanels();
            }}
        >
            <ExtendButton>{props.children}</ExtendButton>
        </FmBadge>
    );
};

export default ShoppingCart;
