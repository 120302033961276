"use client";
import React, {
    CSSProperties,
    ReactNode,
    forwardRef,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useTheme } from "@mui/material/styles";
import { Tab, Box, TabProps, TabsProps } from "@mui/material";
import stl from "../../styles/tab.module.scss";
import classnames from "classnames";
import { IStyleProps } from "../../interface";
import FmThemeProvider from "../FmThemeProvider";
import TabsPanelClient from "./TabsPanelClient";
import TabsClient from "./TabsClient";

// 需要一个主色调和次色调主题
interface TabPanelProps extends IStyleProps {
    children?: ReactNode;
    dir?: string;
    index: number;
    value: number; // !这是个坑：value如果是number, 则会默认是tabIndex做为value
}

export interface ITab extends IStyleProps {
    label: ReactNode;
    children: ReactNode;
    value: number;
    classes?: TabProps["classes"];
}
export interface ITabProps extends IStyleProps, TabsProps {
    tabPanelClassName?: string;
    swipeableViewsClassName?: string;
    tabsClassName?: string;
    tabs: ITab[];
    carousel?: boolean; // 是否让panel以carousel的形式展现
    hiddenIndicator?: boolean;
}
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, className, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            <Box className={classnames(stl["panel-box-container"], className)}>
                {children}
            </Box>
        </div>
    );
};

const FmTabs = forwardRef(function FmTabs(props: ITabProps, ref) {
    const theme = useTheme();
    const {
        onChange,
        tabPanelClassName,
        tabsClassName,
        tabs,
        classes,
        carousel = false,
        hiddenIndicator = false,
        swipeableViewsClassName,
        className,
        ...rest
    } = props;
    const tabsBar = useMemo(() => {
        return props.tabs.map((tab, index) => {
            const { root, selected, ...restClasses } = tab.classes || {};
            return (
                <Tab
                    key={tab.value}
                    label={tab.label}
                    id={`full-width-tab-${index}`}
                    className={tab.className}
                    classes={{
                        root: classnames(stl.root_tab, "root_tab", root),
                        selected: classnames(
                            stl["selected"],
                            "selected-color",
                            selected,
                        ),
                        ...restClasses,
                        // indicator: classnames(stl["indicator"], "indicator"),
                    }}
                    style={tab.style}
                />
            );
        });
    }, [props.tabs]);

    const tabPanels = useMemo(() => {
        return tabs.map((tab, index) => {
            return (
                <TabPanel
                    key={tab.value}
                    value={props.value}
                    index={index}
                    dir={theme.direction}
                    className={classnames(
                        "temp-tabpanel",
                        stl["temp-tabpanel"],
                        tabPanelClassName,
                    )}
                >
                    {tab.children}
                </TabPanel>
            );
        });
    }, [tabs, props.value, theme.direction, tabPanelClassName]);

    const tabsPanel = useMemo(() => {
        return (
            <TabsPanelClient
                carousel={carousel}
                value={props.value}
                onChange={onChange}
                swipeableViewsClassName={swipeableViewsClassName}
            >
                {tabPanels}
            </TabsPanelClient>
        );
    }, [carousel, onChange, swipeableViewsClassName, props.value, tabPanels]);
    const tabsRef = React.useRef<HTMLDivElement>(null);
    const [tabsContainerHeight, setTabsContainerHeight] = useState(0);
    useEffect(() => {
        setTabsContainerHeight(() => {
            return tabsRef.current?.clientHeight ?? 0;
        });
    }, [tabsContainerHeight]);
    const containerHeight = useMemo(() => {
        return {
            "--tabs-container-height": `${tabsContainerHeight}px`,
        } as CSSProperties;
    }, [tabsContainerHeight]);
    return (
        <Box
            className={classnames(stl["tab-box-container"], className)}
            style={{ ...containerHeight, ...props.style }}
        >
            <div ref={tabsRef} className={stl["header-container"]}>
                <TabsClient
                    classes={{
                        ...classes,
                        root: classnames(
                            stl.tab_wrapper,
                            `tab-wrapper-onchange-is-${props.value}`,
                            tabsClassName,
                        ),
                        indicator: classnames(
                            stl["indicator"],
                            classes?.indicator,
                            {
                                [stl["hiddenIndicator"]]: props.hiddenIndicator,
                            },
                        ),
                    }}
                    onChange={onChange}
                    {...rest}
                >
                    {tabsBar}
                </TabsClient>
            </div>
            {tabsPanel}
        </Box>
    );
});

const ThemeTabs = (props: ITabProps) => (
    <FmThemeProvider>
        <FmTabs {...props} />
    </FmThemeProvider>
);
export default ThemeTabs;
