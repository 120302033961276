"use client";
import React, { useCallback, useEffect, useState } from "react";
import { getLocalItem } from "@/lib/commonService";
import { MYFIT } from "@/config/constConf";
import BarMenu from "./BarMenu";
import { ExtendButton } from "@/ui-component";
import { useHeaderStore } from "@/store/pageModules/header";
import { useFindMyFitStore } from "@/store/modules/findMyFit";
interface IProps {
    // Define your props here
}

const LeftMenu: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    // Component logic goes here
    const [open, setOpen] = useState(false); // 菜单栏的显示和隐藏
    const setHeaderOptions = useHeaderStore((state) => state.setHeaderOptions);

    const handleToggle = useCallback(
        (isOpen: boolean): void => {
            if (isOpen) {
                // 打开了菜单栏弹窗，应该关闭其他弹窗
                setHeaderOptions({ isShowUserContent: false });
            }
            setOpen(isOpen);
        },
        [setHeaderOptions],
    );

    // TODO：这一坨有必要在这里设置吗？
    // 常规使用store里的函数
    const setFrameInfo = useFindMyFitStore((state) => state.setFrameInfo);

    // 打开抽屉获取myfit
    useEffect(() => {
        const myfit = getLocalItem(MYFIT);
        if (myfit) {
            setFrameInfo(myfit);
        }
    }, [setFrameInfo]);

    return (
        <>
            <ExtendButton onClick={() => handleToggle(true)}>
                {props.children}
            </ExtendButton>

            {/* 菜单栏抽屉-left */}
            <BarMenu
                anchor={"left"}
                open={open}
                onClose={() => handleToggle(false)}
            />
        </>
    );
};

export default LeftMenu;
