import { getProductPreviewCommentOrQuestion } from "@/src/models/review_es";
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import {
    IReviewAndFqAndAction,
    initialState,
    IReviewAndFq,
    IRequestReviewAndFq,
} from "./initialState";
export type ReviewAndFqStore = ReturnType<typeof createReviewAndFqStore>;
function initedFunc(
    initProps?: Partial<IRequestReviewAndFq>,
): Partial<IReviewAndFq> {
    if (!initProps) {
        return {};
    }
    const {
        reviews_ask,
        reviews_ask_number,
        reviews_comment,
        reviews_all,
        reviews_comment_tag,
        tags,
        start,
    } = initProps;
    return {
        askList: reviews_ask,
        askNum: reviews_ask_number,
        commentList: reviews_comment,
        commentNum: reviews_all,
        commentTags: reviews_comment_tag,
        commentTagsArtificial: tags,
        amount: start?.amount,
        starNum: calcStar(start),
        star: mapStartToArr(start),
    };
}
export const createReviewAndFqStore = (
    initProps?: Partial<IRequestReviewAndFq>,
) => {
    const initedState = initedFunc(initProps);
    const init = { ...initialState, ...initedState };
    return create<IReviewAndFqAndAction>()(
        devtools(
            persist(
                (set, get) => ({
                    ...init,
                    getReviewAndFq: async (id: number, visitor_id?: string) => {
                        const productReivewsComment =
                            await getProductPreviewCommentOrQuestion(
                                id,
                                visitor_id,
                            );
                        set({
                            isLoading: false,
                            askList: productReivewsComment.reviews_ask,
                            askNum: productReivewsComment.reviews_ask_number,
                            commentList: productReivewsComment.reviews_comment,
                            commentNum: productReivewsComment.reviews_all,
                            commentTags:
                                productReivewsComment.reviews_comment_tag,
                            commentTagsArtificial: productReivewsComment.tags,
                            amount: productReivewsComment.start.amount,
                            starNum: calcStar(productReivewsComment.start),
                            star: mapStartToArr(productReivewsComment.start),
                        });
                    },
                }),
                {
                    name: "review-and-fq-storage",
                    storage: createJSONStorage(() => sessionStorage),
                },
            ),
            {
                name: "review-and-fq-store",
            },
        ),
    );
};

export function calcStar(obj: any): number {
    const { amount = 1, one = 0, two = 0, three = 0, four = 0, five = 0 } = obj;
    let star = (one + two * 2 + three * 3 + four * 4 + five * 5) / amount;
    star = Math.round(star * 10) / 10;
    return star || 5;
}

const mapEnNumToNum: Record<string, any> = {
    one: 1,
    two: 2,
    three: 3,
    four: 4,
    five: 5,
};

export function mapStartToArr(data: any): any[] {
    let arr: Record<string, any>[] = [];
    for (let i in data) {
        if (mapEnNumToNum[i]) {
            let obj = {
                key: mapEnNumToNum[i],
                value: data[i],
            };
            arr.push(obj);
        }
    }
    arr = arr.sort((item1, item2) => item2.key - item1.key);
    arr.unshift({ key: "" });
    return arr;
}
