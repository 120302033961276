// type和初始值initialState
export interface PageInfoState {
    showPagination: boolean;
    showPrice: boolean;
    showFilter: boolean;
    hideBubble: boolean;
    is_secondary: boolean; //二次营销
    is_personalize: boolean; //千人千面
    error: string;
    layout_type: number;
    openNewTab: boolean;
    pageData: any;
    is_sku: boolean;
    page_id: number;
    isSingleCol: boolean;
    isPackage: boolean;
}

export type State = PageInfoState;

export type Action = {
    setPageInfo: (pageInfo: any) => void;
    updateProductsListLayout: (isSingleCol: boolean) => void;
};
export type PageInfo = State & Action;

export const initialState: State = {
    showPagination: true,
    showPrice: true,
    showFilter: true,
    hideBubble: false,
    is_secondary: false, //二次营销
    is_personalize: false, //千人千面
    layout_type: 0,
    error: "",
    openNewTab: true,
    pageData: {},
    is_sku: true,
    page_id: -1,
    isSingleCol: false,
    isPackage: false,
};
