import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { IAccount, initialState, IWishes } from "./initialState";
import { deleteFav, getAccount, postAccount } from "@/src/models/account";
import { FAVORITE } from "@/config/api";
import acquisition from "@/src/components/LoadAnalysis/analysis/DataAcquisition";
import { getCookieItem } from "@/lib/commonService";
import { CURRENCY_COOKIE_NAME } from "@/config/constConf";
import reddit from "@/src/components/LoadAnalysis/analysis/reddit";
import { addFavouriteClickEvent } from "@/src/models/emailData";

export const useAccountStore = create<IAccount>()(
    devtools(
        persist(
            (set, get) => ({
                ...initialState,
                // 获取列表
                getFavorite: async (page: number = 1, cb?: Function) => {
                    try {
                        set({ loading: true });
                        const res = await getAccount(FAVORITE, { page });
                        const data = res.data.map((products: IWishes) => {
                            const {
                                favorite_id: favoriteId,
                                products_id: id,
                                products_info,
                            } = products;
                            return {
                                id,
                                favoriteId,
                                price: products_info?.discount
                                    ? products_info?.discount_price
                                    : products_info?.origin_price,
                                img:
                                    products_info?.color_image ||
                                    products.products_image,
                                model:
                                    products.products_name_attribute ||
                                    products.products_model,
                                type: products.products_type,
                                width: products.dimensions_total_width,
                                color_name: products_info?.color_name || "",
                                color_id: products_info?.color_id || "",
                            };
                        });
                        const {
                            per_page: perPage,
                            total,
                            current_page: currentPage,
                        } = res.pagination;
                        let prevWishes = get().wishes.slice(0); // before-wishes
                        if (page === 1) {
                            prevWishes = data;
                        } else {
                            prevWishes = prevWishes.concat(data);
                        }
                        const favoriteArr = prevWishes.map(
                            (item) => item.color_id,
                        );
                        set({
                            wishes: prevWishes,
                            pagination: { perPage, total, currentPage },
                            favoriteArr,
                            loading: false,
                        });
                    } catch (e) {
                        console.error(e);
                    } finally {
                        cb?.();
                    }
                },
                changeFavourite: (colorsId: number) => {
                    const favoriteArr = unique2(get().favoriteArr, colorsId);
                    set({
                        favoriteArr,
                    });
                },
                addFavorite: async (
                    { productId, colorsId, activeColor },
                    cb?: Function,
                ) => {
                    if (productId && colorsId) {
                        acquisition.addWishlist(
                            "addWishlist",
                            `${productId}-${colorsId}`,
                        );
                    }
                    // 去添加喜欢的货品
                    addFavouriteClickEvent({
                        colorId: colorsId,
                        price: activeColor.price,
                    });
                    try {
                        await postAccount(FAVORITE, {
                            products_id: productId,
                            colors_id: colorsId,
                        });

                        reddit.AddToWishlist({
                            currency: getCookieItem(CURRENCY_COOKIE_NAME),
                            itemCount: 1,
                            value: activeColor.price,
                        });
                        get().getFavorite();
                    } catch (e) {
                        throw e;
                    } finally {
                        cb?.();
                    }
                },
                deleteFavorite: async (favorite_id: number) => {
                    try {
                        await deleteFav(favorite_id);
                        get().getFavorite();
                    } catch (e: any) {
                        throw e;
                    }
                },
                setPaginationTotal: (total: number) => {
                    set({
                        pagination: {
                            ...get().pagination, // 覆盖原来的pagination相关数据
                            total,
                        },
                    });
                },
            }),

            {
                name: "account-storage",
                storage: createJSONStorage(() => sessionStorage),
            },
        ),
        {
            name: "account-store",
        },
    ),
);

function unique2(favoriteArr: any[], color_id: number): number[] {
    if (favoriteArr.includes(color_id)) {
        return favoriteArr.filter((item) => item !== color_id);
    } else {
        return [...favoriteArr, color_id];
    }
}
