import { FmDrawer } from "@/ui-component";
import React, { useCallback } from "react";
import UserLoginContent from "./UserLoginContent";

interface IProps {
    userInfo: Record<string, any>;
    open: boolean;
    onChangeUserContent(
        e: React.MouseEvent<HTMLSpanElement, MouseEvent> | null,
        show: boolean,
    ): void;
    height: number;
}
const UserLogin: React.FC<IProps> = (props) => {
    const { onChangeUserContent } = props;

    const closeUserContent = useCallback(() => {
        onChangeUserContent(null, false);
    }, [onChangeUserContent]);
    return (
        <FmDrawer
            anchor="top"
            open={props.open}
            onClose={closeUserContent}
            className={"user-login-drawer"}
            style={{
                zIndex: 9,
            }}
        >
            <UserLoginContent
                userInfo={props.userInfo}
                closeUserContent={closeUserContent}
                height={props.height}
            />
        </FmDrawer>
    );
};

export default UserLogin;
export { type IProps as IUserLoginProps };
