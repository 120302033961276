"use client";
import { FmThemeProvider, Theme, createTheme } from "@/ui-component";
import React from "react";
interface IProps {}
const HeaderMenuRightTheme: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    return (
        // Your JSX code goes here
        <FmThemeProvider
            theme={(theme: Theme) =>
                createTheme({
                    ...theme,
                    palette: {
                        ...theme.palette,
                        primary: {
                            main: "#2ea7e0",
                            contrastText: "#fff",
                        },
                    },
                })
            }
        >
            {props.children}
        </FmThemeProvider>
    );
};

export default HeaderMenuRightTheme;
