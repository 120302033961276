import React from "react";
import stl from "./searchOrderListSkeleton.module.scss";
import { FmSkeleton } from "@/ui-component";
function SearchOrderItem() {
    return (
        <div className={stl["search-order-item"]}>
            <div className={stl["order-title"]}>
                <FmSkeleton>
                    <p># 23081001021694</p>
                </FmSkeleton>
                <FmSkeleton>
                    <p>Aug 10 , 2023</p>
                </FmSkeleton>
                <FmSkeleton>
                    <p>New order</p>
                </FmSkeleton>
            </div>
            <div className={stl["order-img"]}>
                <FmSkeleton
                    variant="rectangular"
                    height={"2.8rem"}
                    width={"2.8rem"}
                ></FmSkeleton>
                <FmSkeleton variant="rectangular" className={stl["price"]}>
                    <p>AUD$59.00 </p>
                </FmSkeleton>
                <FmSkeleton variant="text" className={stl["product-title"]}>
                    <p>AUD$59.00 </p>
                </FmSkeleton>
            </div>
            <div className={stl["show-details"]}>
                <FmSkeleton>
                    <p>mx cdcd </p>
                </FmSkeleton>
                <FmSkeleton>
                    <p>501 X St </p>
                </FmSkeleton>
                <FmSkeleton>
                    <p>cheng,Bedford,Alaska,47421-1941</p>
                </FmSkeleton>
                <FmSkeleton>
                    <p>United States </p>
                </FmSkeleton>
                <FmSkeleton>
                    <p>1234567890</p>
                </FmSkeleton>
            </div>
            {/* <FmSkeleton>
                <p>AUD$59.00 </p>
            </FmSkeleton> */}
            <div className={stl["button-container"]}>
                <FmSkeleton height={"0.7rem"}>
                    <div>view</div>
                </FmSkeleton>
                <FmSkeleton height={"0.7rem"}>
                    <div>cancel</div>
                </FmSkeleton>
                <FmSkeleton height={"0.7rem"}>
                    <div>recorder</div>
                </FmSkeleton>
                <FmSkeleton height={"0.7rem"}>
                    <div>Track my order</div>
                </FmSkeleton>
            </div>
        </div>
    );
}
const SearchOrderListSkeleton: React.FC = () => {
    // Your component logic goes here

    return (
        // JSX code goes here
        <div className={stl["search-order-list-container"]}>
            <SearchOrderItem />
        </div>
    );
};

export default SearchOrderListSkeleton;
