// 手风琴
/**
 * 逻辑：每一个item, 都有title, title附加的样式；children, children附加的样式；所以title可以连带一些属性，去得到一个被titleWrapper包裹的reactNode; 也可以直接显示； children也是同理
 * 所以：不管怎么样，title都是要被塞给summary的；那如果要对title进行额外的处理，就是要处理children, 所以要重写children和expandIcon
 */
import React from "react";
import stl from "../../styles/accordion.module.scss";
import AccordionItemClient, { ISingleAccordion } from "./AccordionItemClient";
import {
    AccordionSummary,
    AccordionSummaryClasses,
    AccordionSummaryProps,
} from "@mui/material";
import classnames from "classnames";
import FmIcon from "../FmIcon";

interface IFmAccordionProps extends IClass {
    accordions: (ISingleAccordion &
        Omit<AccordionSummaryProps, "title" | "expandIcon">)[];
    noBottomTitleLine?: boolean; // 是否有title-bottom-line, 默认item为先， 此为后，默认存在line
    summaryClasses?: Partial<AccordionSummaryClasses>;
}

export interface IClass {
    rootExpandedClassName?: string;
    rootClassName?: string; // 未打开前（normal)
    accordionSummaryClassName?: string;
    accordionDetailsClassName?: string;
}

const FmAccordion: React.FC<IFmAccordionProps> = (props) => {
    const { accordions, summaryClasses, ...rest } = props;
    return !accordions?.length
        ? null
        : accordions.map((item) => {
              return item.isAccordion === false ? (
                  <div key={item.id} className={stl["no-accordion-title"]}>
                      {item.title}
                  </div>
              ) : (
                  <AccordionItemClient key={item.id} item={item} {...rest}>
                      <AccordionSummary
                          classes={{
                              root: classnames(
                                  stl.accordion_title,
                                  "accordion_title",
                                  {
                                      [stl["hidden-bottom-line"]]:
                                          item.noBottomTitleLine ??
                                          props.noBottomTitleLine ??
                                          false,
                                  },
                                  item.classes?.root,
                              ),
                              content: classnames(
                                  stl["title-content"],
                                  props.accordionSummaryClassName ??
                                      summaryClasses?.content,
                              ),
                              ...summaryClasses,
                          }}
                          expandIcon={
                              <FmIcon
                                  icon={
                                      item.expandIcon?.icon ??
                                      "icon--arrow-down"
                                  }
                                  className={classnames(
                                      stl["expand-icon"],
                                      "expand-icon",
                                  )}
                              />
                          }
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                          color="inherit"
                      >
                          {item.title}
                      </AccordionSummary>
                  </AccordionItemClient>
              );
          });
};

export default FmAccordion;
export { type ISingleAccordion };
