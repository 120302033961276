import React, { CSSProperties, useMemo } from "react";
import { Badge, BadgeProps } from "@mui/material";
import stl from "../styles/badge.module.scss";
import classnames from "classnames";
import variable from "../_common.module.scss";
import {
    ExcludeT1FrT2,
    WholePropsIncludeAddPreFixAndOtherProps,
} from "../interface";
interface IOtherBadge {
    badgeContent: BadgeProps["badgeContent"] | null; // 为null时不显示badgeContent
    color?: BadgeProps["color"] | string;
}

// 剔除badgeProps中与otherBadge重合的部分
type ExculudeOtherBadge = ExcludeT1FrT2<BadgeProps, IOtherBadge>;

type IBadgeProps = WholePropsIncludeAddPreFixAndOtherProps<
    IOtherBadge,
    ExculudeOtherBadge,
    "badge"
>;
const FmBadge = (props: IBadgeProps) => {
    const { classes, color, ...rest } = useMemo(() => props, [props]);
    const colorCss = useMemo<CSSProperties>(() => {
        return {
            "--color": props.color ?? variable.badgeColor,
        } as CSSProperties;
    }, [props.color]);
    return (
        <Badge
            classes={{
                ...classes,
                root: classnames(
                    stl["badge-container"],
                    props.className,
                    props.classes?.root,
                ),
                badge: classnames(
                    stl["badge-class"],
                    { ["hidden"]: props.badgeContent === null },
                    props.classes?.badge,
                ),
            }}
            color={"primary"}
            {...rest}
            style={colorCss}
        >
            {/* {React.Children.map(props.children, (child, index) => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, {
                        className: stl["icon-font-to-48"],
                    } as any);
                }
                return child;
            })} */}
            {props.children}
        </Badge>
    );
};

export default FmBadge;
