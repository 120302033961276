"use client";
import React, { useCallback } from "react";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import classnames from "classnames";

import { TabsProps } from "@mui/material";
interface TabsPanelClientProps extends TabsProps {
    carousel: boolean;
    swipeableViewsClassName?: string;
}

const TabsPanelClient: React.FC<
    React.PropsWithChildren<TabsPanelClientProps>
> = (props) => {
    // Implement the component logic here
    const { carousel } = props;
    const theme = useTheme();
    // 这个方法太过于绝对，拿不到正确的value值
    const handleChangeIndex = useCallback(
        (index: number) => {
            const e: any = null; // 这个e反正也用不上，用来做替死鬼
            props.onChange?.(e, index);
        },
        [props],
    );
    return carousel ? (
        <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={props.value}
            onChangeIndex={handleChangeIndex}
            className={classnames(
                "swipeableviews",
                props.swipeableViewsClassName,
            )}
        >
            {props.children}
        </SwipeableViews>
    ) : (
        props.children
    );
};

export default TabsPanelClient;
