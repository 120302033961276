"use client";
import React from "react";
import { createTheme, Theme, ThemeProvider } from "@mui/material/styles";
import { ThemeProviderProps } from "@mui/material/styles/ThemeProvider";
import { IStyleProps } from "../interface";
// 把theme搞出来，变成可选项
interface IProps extends Partial<ThemeProviderProps<Theme>>, IStyleProps {}

// 定个主色调
const primaryTheme = createTheme({
    palette: {
        primary: {
            main: "#2ea7e0",
            contrastText: "#121a24",
        },
    },
});
const FmThemeProvider: React.FC<IProps> = (props) => {
    const { theme, children, ...rest } = props;
    return (
        <ThemeProvider theme={theme ?? primaryTheme}>
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, {
                        ...rest,
                    } as any);
                }
                return child;
            })}
        </ThemeProvider>
    );
};

export default FmThemeProvider;

export { createTheme, type Theme };
