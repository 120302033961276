import React, { useCallback, useEffect, useMemo, useState } from "react";
import WishItem from "./WishItem";
import { account, signInModal } from "@/store";
import stl from "./wishShoppingCart.module.scss";
import { FmButton, FmDrawer, FmToast, FmViewList } from "@/ui-component";
import { isLogin } from "@/src/models/loginModel";
import classnames from "classnames";
import { IWishItem } from "@/store/modules/account";
import { useTranslation } from "@/src/i18n/client";

interface IProps {
    open: boolean;
    onClose: () => void;
}
const WishShoppingCart: React.FC<IProps> = (props) => {
    const { t } = useTranslation(["common", "account"]);
    const [wishes, pagination, deleteFavorite, getFavorite] =
        account.useAccountStore((state) => [
            state.wishes,
            state.pagination,
            state.deleteFavorite,
            state.getFavorite,
        ]);

    const [openModal] = signInModal.useSignInModalStore((state) => [
        state.openModal,
    ]);
    const [toastChildren, setToastChildren] = useState("");
    const deleteWish = useCallback(
        async (favoriteId: number) => {
            try {
                await deleteFavorite(favoriteId);
            } catch (e: any) {
                setToastChildren(e.message);
            }
        },
        [deleteFavorite],
    );
    // 初始获取
    useEffect(() => {
        isLogin() && getFavorite();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getNext = useCallback(
        async (page: number) => {
            await getFavorite(page);
        },
        [getFavorite],
    );

    const wishItemList = useMemo(() => {
        return wishes.map((item: IWishItem) => {
            return (
                <WishItem data={item} deleteWish={deleteWish} key={item.id} />
            );
        });
    }, [deleteWish, wishes]);
    return (
        <>
            {/* 登录? (emptyList ? tips : fmviewList) : loginTips */}
            <FmDrawer
                headerChildren={t("account:MyAccount:Favorites")}
                closeable
                open={props.open}
                onClose={props.onClose}
                closeablePosition={"right"}
                boxClassName={stl["box-container"]}
                childrenClassName={classnames(
                    stl["children-container"],
                    "children-container",
                )}
                fullHeight
                anchor={"bottom"}
            >
                {/* wishList */}
                {props.open ? (
                    <div className={stl["wishlist-container"]}>
                        {/* 空的 */}
                        <div
                            className={classnames(
                                stl["wishlist-empty-container"],
                            )}
                        >
                            {/* 没有数据 || 没有登录这句都要显示 */}
                            {!(wishes.length && isLogin()) ? (
                                <p className={stl["heart-tap-tips"]}>
                                    {t(
                                        "common:ClickOrTapOnTheHeartIconToAddItemsToFavorites",
                                    )}
                                </p>
                            ) : (
                                <></>
                            )}
                            {!isLogin() ? (
                                <p className={stl["sigin-in-tips"]}>
                                    <FmButton
                                        variant="text"
                                        onClick={() => openModal()}
                                        className={classnames(
                                            stl["wish-list-login"],
                                            "wish-list-login",
                                        )}
                                    >
                                        {t("common:btn:signIn")}
                                    </FmButton>
                                    {t("common:SignInTips")}
                                </p>
                            ) : (
                                <></>
                            )}
                        </div>
                        {/* 非空 */}
                        {isLogin() ? (
                            <FmViewList
                                currentPage={pagination.currentPage}
                                className={classnames(
                                    "view-list-container",
                                    stl["viewlist-container"],
                                    {
                                        ["hidden"]: !wishes.length,
                                    },
                                )}
                                pageSize={pagination.perPage}
                                total={pagination.total}
                                onEndReached={getNext}
                            >
                                {wishItemList}
                            </FmViewList>
                        ) : (
                            <></>
                        )}
                    </div>
                ) : (
                    <></>
                )}
            </FmDrawer>
            <FmToast visible={!!toastChildren} type={"info"} seconds={3}>
                {toastChildren}
            </FmToast>
        </>
    );
};

export default WishShoppingCart;
