// type和初始值initialState
import {
    filterNavConfig,
    sortByConfig,
    colorConfig,
    genderConfig,
    materialConfig,
    shapeConfig,
    sizeConfig,
    frameConfig,
    featureConfig,
    KidsAgeRangeConfig,
} from "@/config/filterConfig";
import { cloneDeepWith } from "lodash";
import { FilterType } from "./filterConditionSlices";
// 表示筛选项导航的每个选项
type FilterNavItem = {
    id: number;
    title: string;
    hasVal: boolean; // 表示该选项是否有可选的值
    useCount: number; // 表示已选中的个数
};

// 表示颜色的配置项
export type Color = {
    id: number;
    title: string;
    color: string;
    active: boolean; // 表示是否被选中
    val: string; // 表示颜色的值
    img?: string;
    borderColor?: string;
};

type KidsAgeRange = typeof KidsAgeRangeConfig;

// 表示性别的配置项
type Gender = {
    title: string;
    val: number;
    id: number;
    active: boolean; // 表示是否被选中
};

// 表示材质的子项
type MaterialItem = {
    id: number;
    title: string;
    val: string;
    active: boolean; // 表示是否被选中
};

// 表示材质的配置项，包含多个子项
type MaterialConfigItem = {
    title: string;
    data: MaterialItem[];
};

// 表示眼镜形状的配置项
type Shape = {
    id: number;
    title: string;
    img: string;
    imgW: number;
    active: boolean; // 表示是否被选中
    val: number;
};

// 表示尺寸的子项
export type SizeItem = {
    id: number;
    title: string;
    active: boolean; // 表示是否被选中
    val: string; // 表示尺寸的值
};

// 表示自定义尺寸的配置项
export type CustomSizeItem = {
    id: number;
    title: string;
    img: string;
    leftTotal: number;
    rightTotal: number;
    leftVal: number;
    rightVal: number;
    key: string; // 表示与尺寸相关的键
    active?: boolean;
};

// 表示尺寸的配置项，包含多个子项和自定义项
type SizeConfigItem = {
    title: string;
    data: SizeItem[];
    customData: CustomSizeItem[];
};

// 表示排序方式的配置项
type SortByItem = {
    title: string;
    id: number;
    active: boolean; // 表示是否被选中
    val: string | null; // 表示排序方式的值，可以为空
};

// 表示镜框的配置项
type Frame = {
    id: number;
    title: string;
    img: string;
    imgW: number;
    active: boolean; // 表示是否被选中
    val: string; // 表示镜框的值
};

// 表示特性的配置项
type Feature = {
    id: number;
    title: string;
    active: boolean; // 表示是否被选中
    val: string; // 表示特性的值
};

type SortByConfig = SortByItem[];

type ColorConfig = Color[];

export type GenderConfig = Gender[];

type MaterialConfig = MaterialConfigItem[];

type ShapeConfig = Shape[];

type SizeConfig = SizeConfigItem[];

type FrameConfig = Frame[];

type FeatureConfig = Feature[];

export type FilterNavConfig = FilterNavItem[];

export interface FilterConditionProps {
    isFilter: boolean;
    filterArr: string[];
    // 平铺的已筛选对象，暂时不需要初始化
    flatFilterArr: any[];
    color: string[];
    gender: GenderConfig;
    size?: SizeItem;
    ageRange: number[];
    sizeId: number;
    frame: string[];
    material: number[][];
    shape: number[];
    price: string;
    feature: FeatureConfig;
    filterItem: string;
    filterSubItem: string;
    totalWidth: string;
    lensWidth: string;
    lensHeight: string;
    bridgeDistance: string;
    templeLength: string;
    diagonal: string;
    page: number;
    num: number;
    sortBy: SortByItem;
    sortByData: SortByConfig;
    colorData: ColorConfig;
    genderData: GenderConfig;
    materialData: MaterialConfig;
    shapeData: ShapeConfig;
    sizeData: SizeConfig;
    frameData: FrameConfig;
    featureData: FeatureConfig;
    filterNavConfig: FilterNavConfig;
    flowTemplateTag: string;
    kidsAgeRangeData: KidsAgeRange;
    // 在服务端初始化条件
    reqConditon?: Record<string, string | string[]>;
}

export type State = FilterConditionProps;

export type Action = {
    // filterCondition: (action: any) => void;
    activeFilterTitleStr: () => void;
    // 颜色筛选
    colorSelect: (obj: any) => void;
    // 性别选择
    genderSelect: (id: number) => void;
    // kids年龄选择
    ageRangeSelect: (id: number) => void;
    // 材质选择
    materialSelect: (id: number) => void;
    // 风格选择
    shapeSelect: (id: number) => void;
    featureSelect: (id: number) => void;
    // 尺寸选择
    sizeSelect: (id: number) => void;
    // 尺寸自定义选择
    sizeSliderSelect: (obj: CustomSizeItem) => void;
    // 框型选择
    frameSelect: (id: number) => void;
    // 初始化
    initFilterConditions: () => void;
    // 改变筛选条件
    changeFilterConditions: (conditions: any) => void;
    // 从url中取条件
    // getConditionFromQuery: (reqCondition: any) => void;
    // outputCondition输出条件
    outputCondition: () => any;
    // 从已选的筛选选项中删除条件
    deleteSelectedOption: (item: any, type: FilterType) => void;
};
export type FilterConditionState = State & Action;

export const initialState: State = {
    isFilter: false,
    filterArr: [],
    flatFilterArr: [],
    color: [],
    gender: [],
    size: undefined,
    ageRange: [],
    sizeId: -1,
    frame: [],
    material: [],
    shape: [],
    price: "",
    feature: [],
    filterItem: "", //用户行为分析记录筛选项字段
    filterSubItem: "", //用户行为分析记录筛选项字段
    totalWidth: "",
    lensWidth: "",
    lensHeight: "",
    bridgeDistance: "",
    templeLength: "",
    diagonal: "",
    page: 1,
    num: 0,
    sortBy: sortByConfig[0],
    sortByData: cloneDeepWith(sortByConfig),
    colorData: cloneDeepWith(colorConfig),
    genderData: cloneDeepWith(genderConfig),
    materialData: cloneDeepWith(materialConfig),
    shapeData: cloneDeepWith(shapeConfig),
    sizeData: cloneDeepWith(sizeConfig),
    frameData: cloneDeepWith(frameConfig),
    featureData: cloneDeepWith(featureConfig),
    filterNavConfig: cloneDeepWith(filterNavConfig),
    kidsAgeRangeData: cloneDeepWith(KidsAgeRangeConfig),
    flowTemplateTag: "A",
};
export const FilterInitialState = initialState;
