// import { LOCAL_STORAGE_NAMES } from '../config/constConf';

import { LOCAL_STORAGE_NAMES } from "@/config/constConf";
import { getLocalItem, setLocalItem } from "./commonService";
import { cloneDeepWith } from "lodash";

function unique(arr: Record<string, any>[]) {
    for (var i = 0, len = arr.length; i < len; i++) {
        for (var j = i + 1, len = arr.length; j < len; j++) {
            if (arr[i].key === arr[j].key) {
                arr.splice(j, 1);
                j--; // 每删除一个数j的值就减1
                len--; // j值减小时len也要相应减1（减少循环次数，节省性能）
            }
        }
    }
    return arr;
}

//用户未登录情况下存储用户搜索记录
export function setUserRecentSearchesToLocal(key: string) {
    if (!key) {
        return;
    }

    let _recent_local = getLocalItem(LOCAL_STORAGE_NAMES.USER_RESENT_SEARCH);
    if (!_recent_local) {
        _recent_local = [];
    }
    // _recent_local.splice(_recent_local.findIndex(item => item.key === key), 1);

    //向数组头部添加数据
    _recent_local = [{ key: key }].concat(_recent_local);

    _recent_local = _recent_local.splice(0, 10);
    //最多保存10个搜索记录
    setLocalItem(LOCAL_STORAGE_NAMES.USER_RESENT_SEARCH, unique(_recent_local));
}

export function HighlightSearchKey(key: string, str: string) {
    if (!key.trim().length || !str.trim().length) {
        return str;
    }
    const new_str = str.replace(
        new RegExp(`${key}`, "ig"),
        "<span style='background-color:#abdcf3;'>$&</span>",
    );
    return new_str;
}

//对搜索出来的结果进行颜色排序
export function initColorArrInProducts(data: any[], color_str = "") {
    if (!Array.isArray(data)) {
        return [];
    }
    const currentData = cloneDeepWith(data);
    currentData.forEach((ele) => {
        let { products_color_info = [], color_hit_id } = ele;
        //有搜索的值，往前面排
        if (ele.color_hit_id) {
            const index = products_color_info.findIndex(
                (item: any) => item.color_id === color_hit_id,
            );
            if (index > -1) {
                const _item = { ...products_color_info[index] };
                products_color_info.splice(index, 1);
                products_color_info.unshift(_item);
            }
        }
        //有筛选的颜色，往前面排
        if (color_str) {
            const index = products_color_info.findIndex(
                (item: any) => item.color_name.indexOf(color_str) >= 0,
            );

            if (index > -1) {
                const _item = { ...products_color_info[index] };
                products_color_info.splice(index, 1);
                products_color_info.unshift(_item);
            }
        }
    });

    return currentData;
}

//导航展示实时搜索商品数据
//只取前6个
export function initOnTimeProductList(data: any[] = []) {
    if (!Array.isArray(data)) {
        return [];
    }
    const _data = initColorArrInProducts(data).splice(0, 6);

    for (let index = 0; index < _data.length; index++) {
        let ele = _data[index];
        let {
            products_color_info = [],
            products_name_attribute,
            products_model,
            products_id,
            master_categories_id,
        } = ele;

        let active_color = products_color_info[0];
        _data[index] = {
            products_name_attribute,
            products_model,
            products_id,
            master_categories_id,
            color_id: active_color ? active_color.color_id : "",
            products_image: active_color ? active_color.color_image : "",
        };
    }

    return _data;
}
