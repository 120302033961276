import React, { useMemo } from "react";
import { IStyleProps } from "@/ui-component/interface";
import FmButton from "../FmButton";
import stl from "../../styles/carousel.module.scss";
import classnames from "classnames";

interface IDot {
    active: boolean;
    index: number;
    onClick: Function;
}
const styles = {
    root: {
        height: 18,
        width: 18,
        cursor: "pointer",
        border: 0,
        background: "none",
        padding: 0,
    },
};

const Dot: React.FC<IDot> = (props) => {
    const { onClick, active } = props;

    return (
        <FmButton
            style={styles.root}
            className={stl["dot-wrapper"]}
            onClick={(e) => onClick(e, props.index)}
        >
            <div
                className={classnames(stl["dot-content"], {
                    [stl["active"]]: active,
                })}
            />
        </FmButton>
    );
};

interface IPaginationProps extends IStyleProps {
    dots: number;
    index: number;
    onChangeIndex: Function;
}

const Pagination: React.FC<IPaginationProps> = (props) => {
    const { onChangeIndex, index, dots } = props;

    const children = useMemo(() => {
        const resArr = [];
        for (let i = 0; i < dots; i += 1) {
            resArr.push(
                <Dot
                    key={i}
                    index={i}
                    active={i === index}
                    onClick={(e: any) => onChangeIndex(index)}
                />,
            );
        }
        return resArr;
    }, [dots, index, onChangeIndex]);

    return (
        <div
            style={props.style}
            className={classnames(stl["dot-root"], props.className)}
        >
            {children}
        </div>
    );
};

export default Pagination;
