export interface IUserInfoProps {
    isLoading: boolean;
    isUkUser: boolean;
    data: Partial<IData>;
    errors: string;
}

export interface IData {
    baskets_count: number;
    country: Record<string, any>;
    country_id: number;
    customers_dob: any;
    customers_email_address: string;
    customers_fax: any;
    customers_firstname: string;
    customers_gender: string;
    customers_id: number;
    customers_lastname: string;
    customers_nick: string;
    customers_telephone: string;
    favorites_count: number;
    firmoo_uid: number;
    is_old_customer: boolean;
    user_id: number;
}

export type State = IUserInfoProps;

export type IUserInfoStateAndAction = State & Action;
export type Action = {
    getUserInfo: () => Promise<void>;
};

export const initialState: State = {
    isLoading: false,
    isUkUser: false,
    data: {
        baskets_count: 0,
        favorites_count: 0,
    },
    errors: "",
};
