import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { FindMyFitStateAndAction, initialState } from "./initialState";
import { MYFIT } from "@/config/constConf";
import { setLocalItem, removeLocalItem } from "@/lib/commonService";

export const useFindMyFitStore = create<FindMyFitStateAndAction>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        setFrameInfo: (frameInfo) => {
          set({ frameInfo, haveFrameInfo: frameInfo.length > 0 });
          if (frameInfo.length) {
            setLocalItem(MYFIT, frameInfo);
          } else {
            removeLocalItem(MYFIT);
          }
        },
      }),
      {
        name: "find-my-fit-storage",
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
    {
      name: "find-my-fit-store",
    },
  ),
);
