import { FetchProductsType } from "@/src/provider/Products/hooks";

export interface IProductData {
    data: any[];
    data_ad: any[];
    pagination: {
        current_page: number;
        total: number;
        per_page: number;
    };
}
// type和初始值initialState
export interface Product {
    isLoading: boolean;
    productData: IProductData;
    favoriteArr: any[];
    is_sku: boolean;
    error: string;
    // 客户端是否请求过数据
    isClientFetched: boolean;
    // 是否展示骨架屏
    isShowSkeleton: boolean;
}

export type State = Product;

export type Action = {
    setProductsSkuStatus: (is_sku: boolean) => void;
    fetchProducts: (params: any, type: FetchProductsType) => void;
    setInitProductsData: (data: any) => void;
};
export type ProductsProps = State & Action;

export const initialState: State = {
    isLoading: false,
    productData: {
        data: [],
        data_ad: [],
        pagination: {
            current_page: 1,
            total: 0,
            per_page: 60,
        },
    },
    favoriteArr: [],
    is_sku: true,
    error: "",
    isClientFetched: false,
    isShowSkeleton: true,
};
