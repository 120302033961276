import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {
    IPackageInfoAction,
    IPackageInfoState,
    initialState,
} from "./initialState";

export interface IPageDescriptions {
    name: string;
    content: string;
}

export interface IInitProps {
    package_list: any[];
    page_descriptions: IPageDescriptions;
    is_rx: boolean;
}
export type PackageInfoStore = ReturnType<typeof createPackageInfoStore>;
export const createPackageInfoStore = (initProps?: any) => {
    const init = { ...initialState, ...initProps };
    return create<IPackageInfoAction>()(
        devtools(
            (set, get) => ({
                ...init,
                setPackageInfo(data: IPackageInfoState) {
                    set({ page_descriptions: data.page_descriptions });
                },
                selectPackage(active_package) {
                    set({
                        active_package,
                    });
                },
                selectUsage(usage) {
                    set({
                        usage,
                    });
                },
            }),
            {
                name: "packageInfo-store",
            },
        ),
    );
};
