"use client";
import React, { useEffect, useMemo, useState } from "react";
import { BUBBLE_COOKIE_NAME, pageType } from "@/config/constConf";
import { gaCommonEvent } from "@/src/utils/gtagEvents";
import { isLogin } from "@/src/models/loginModel";
import { getCookieItem, setCookieItem } from "@/lib/commonService";
import stl from "./bubble.module.scss";
import { ICircle } from "@/store/modules/pageLayout";
import { ICmsProps } from "../Announcement/interface";
import { IChildren } from "@/src/app/interface";
import { useUserInfoContext } from "@/src/provider";
export interface IProps extends ICmsProps {
    circle: ICircle;
}

const BubbleClient: React.FC<IProps & IChildren> = (props) => {
    const [userInfo] = useUserInfoContext((state) => [state.data]);
    const { circle = {} as Record<string, any>, page_name = "all" } = props;
    const [show, setShow] = useState(true);
    useEffect(() => {
        checkDataShow();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkDataShow = () => {
        const { pages = [], hide_pages = [] } = (circle ?? {}) as Record<
            string,
            any
        >;
        const pathName = location.pathname;
        let _pageType: any = pageType;

        if (getCookieItem(BUBBLE_COOKIE_NAME) === "true") {
            setShow(false);
            return;
        }
        //显示页面
        if (!pages.includes(_pageType[page_name]) && !pages.includes(0)) {
            setShow(false);
            return;
        }

        //隐藏页面
        hide_pages.forEach((item: any) => {
            if (item.value === pathName) {
                setShow(false);
            }
            return;
        });
    };

    const onCloseBubble = () => {
        setCookieItem(BUBBLE_COOKIE_NAME, "true");
        setShow(false);
    };
    //是否老用户
    const isOldUser = useMemo(
        () => (userInfo && userInfo.is_old_customer) || "",
        [userInfo],
    );

    const showNullDom = useMemo(
        () =>
            !circle.img || !circle.href || !show || circle.visible == "hidden",
        [circle.href, circle.img, circle.visible, show],
    );

    return (
        !showNullDom && (
            <>
                <div
                    className={stl["bubble-radius"]}
                    onClick={() =>
                        gaCommonEvent("bubble_ad", {
                            event_category: "bubble_ad",
                            event_label: `bubble_ad_${isOldUser ? "old" : "new"}${
                                isLogin() ? "_login" : ""
                            }`,
                        })
                    }
                >
                    {props.children[0]}
                    <div className={stl["close-btn"]} onClick={onCloseBubble}>
                        &times;
                    </div>
                </div>
            </>
        )
    );
};

export default BubbleClient;
