import {
    StaticRequire,
    StaticImageData,
} from "next/dist/shared/lib/get-img-props";
import { getSrcWhenImgHaveSomeOtherTypes } from "../basic/FmImage";
import { IMG_HOST } from "@/config/api";

function getWebpUrl(
    temp: string | undefined | StaticRequire | StaticImageData,
    useWebp: boolean = true,
): Record<string, any> {
    let src = getSrcWhenImgHaveSomeOtherTypes(temp);
    if (!src || !src.trim().length) return { src };
    const iswebpImage =
        useWebp &&
        /\.(jpg|jpeg|png)$/i.test(src) &&
        src.indexOf(IMG_HOST()) > -1;
    // src.indexOf("df5apg8r0m634.cloudfront.net") > -1;
    return {
        src: iswebpImage ? src.replace(/(\.(\w+))$/, ".webp") : src,
        webp: iswebpImage,
    };
}

export default getWebpUrl;
