"use client";
import React, { forwardRef, useImperativeHandle, useMemo, useRef } from "react";
import classnames from "classnames";
import stl from "../../styles/viewList.module.scss";
import { IViewListProps } from "./ViewListClient";
import { IButtonProps } from "../FmButton";
export interface IFooterProps extends IViewListProps {
    currentPage: number;
    onClick: IButtonProps["onClick"];
    loading: boolean;
}
const FooterClient = forwardRef(function FooterClient(
    props: React.PropsWithChildren<IFooterProps>,
    ref: React.Ref<unknown> | undefined,
) {
    const itemRef = useRef<HTMLLIElement>(null);
    useImperativeHandle(
        ref,
        () => {
            return itemRef.current;
        },
        [],
    );

    const hasManual = useMemo(() => {
        return (
            props.manual?.limitPage &&
            props.currentPage >= props.manual.limitPage
        );
    }, [props.currentPage, props.manual?.limitPage]);

    const footerManual = useMemo(() => {
        return hasManual && props.loading
            ? props.manual?.loadingReachedNode
            : props.manual?.onEndReachedNode?.({
                  ...props.manual?.buttonProps,
                  onClick: props.onClick,
              });
    }, [hasManual, props.loading, props.manual, props.onClick]);
    return (
        <div key={"footer-loading"} className={stl["list-item"]}>
            <div
                className={classnames(stl["render-footer"], {
                    ["hidden"]: !props.canBeScroll,
                })}
            >
                {hasManual ? footerManual : props.children}
            </div>
        </div>
    );
});

export default FooterClient;
