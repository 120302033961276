"use client";
import {
    FormControl,
    InputAdornment,
    InputProps,
    OutlinedInput,
    OutlinedInputProps,
} from "@mui/material";
import React, {
    useMemo,
    forwardRef,
    useImperativeHandle,
    useRef,
    useCallback,
    useState,
    useEffect,
    CSSProperties,
} from "react";
import FmIcon from "./FmIcon";
import stl from "../styles/input.module.scss";
import classnames from "classnames";
import variable from "../styles/variable.module.scss";

export interface IInputProps extends OutlinedInputProps {
    onSearch?: (e?: any) => void;
    onClear?: () => void;
    onFocus?: () => void;
    clearable?: boolean;
    helperText?: string;
    borderRadiusType?: "round" | "square";
    showSearchBtn?: boolean;
    errorColor?: string; // error的颜色 variable.warning
    borderColor?: string; // 设置border的颜色
    helperTextClassName?: string;
    errorPosition?: "top" | "bottom";
    inputClasses?: Partial<{
        outline: string;
        helperText: string;
    }>;
}

const defaultInputHeight = {
    large: 0.92,
    medium: 0.68,
    small: 0.68,
}; // 默认input框的高度
const FmInput = forwardRef(function FmInput(props: IInputProps, ref) {
    const {
        classes,
        onClear,
        onSearch,
        onFocus,
        onClick,
        onBlur,
        clearable,
        helperText,
        className,
        borderRadiusType,
        showSearchBtn,
        helperTextClassName,
        inputClasses,
        borderColor,
        errorPosition = "top", // 错误提示默认在顶部
        ...restProps
    } = props;
    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => {
        return inputRef.current;
    }, []);
    const { root, input, notchedOutline, ...restClasses } = classes ?? {};

    const firstRenderRef = useRef(true);
    const inputFocusRef = useRef(false);

    const error = useMemo(() => {
        return !!(props.error ?? (props.helperText ?? "")?.trim().length > 0);
    }, [props.error, props.helperText]);
    const [borderStatus, setBorderStatus] = useState<
        "normal" | "focus" | "error"
    >(error ? "error" : "normal");

    useEffect(() => {
        // 监听error => border-status
        let status: "normal" | "focus" | "error" = "normal";
        if (inputFocusRef.current) {
            status = "focus";
        }
        setBorderStatus(error ? "error" : status);
    }, [error]);
    const handleFocus = useCallback(() => {
        firstRenderRef.current = false; // not first painting
        inputFocusRef.current = true;
        setBorderStatus("focus");
        onFocus?.(); // clear error-tips
    }, [onFocus]);

    const handleBlur = useCallback(
        (
            event: React.FocusEvent<
                HTMLInputElement | HTMLTextAreaElement,
                Element
            >,
        ) => {
            // 验证接口，错误提示
            inputFocusRef.current = false;
            setBorderStatus(error ? "error" : "normal");
            onBlur?.(event);
        },
        [onBlur, error],
    );

    const errorColor = useMemo<React.CSSProperties>(() => {
        return {
            "--error-color": props.errorColor ?? variable.warning,
            "--border-color": props.disabled
                ? variable.disabled_color
                : (borderColor ?? "#C5C9CD"),
        } as React.CSSProperties;
    }, [borderColor, props.disabled, props.errorColor]);
    //首次渲染 || props.error不存在 || 是正常状态 => hide
    const hiddenErrorTips = useMemo(() => {
        return (
            borderStatus === "focus" ||
            (borderStatus === "normal" && !error) ||
            firstRenderRef.current
        );
    }, [borderStatus, error]);

    const inputWrapperHeight = useMemo<CSSProperties>(() => {
        return {
            "--default-height": `${defaultInputHeight[props.size ?? "large"]}rem`,
        } as CSSProperties;
    }, [props.size]);

    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (props.disabled) {
                e.stopPropagation();
                e.preventDefault();
                return;
            }
            onClick?.(e);
        },
        [onClick, props.disabled],
    );
    const labelMemo = useMemo(() => {
        // 在输入值时展示
        if (
            typeof props.label === "boolean" ||
            props.label === props.placeholder
        ) {
            return props.value ? props.placeholder : "";
        } else {
            return props.label;
        }
    }, [props.label, props.placeholder, props.value]);
    return (
        <FormControl
            variant="outlined"
            className={classnames(stl.form_control, props.className)}
            disabled={props.disabled}
        >
            {/* error有两种错误提示，一种是类似于登录注册页面，错误提示在顶部且定位在右上角；第二种是在底部 */}
            <p
                className={classnames(
                    stl["error"],
                    stl["help-text"],
                    helperTextClassName,
                    inputClasses?.helperText,
                    stl[`error-${errorPosition}-position`],
                    {
                        ["hidden"]: errorPosition === "bottom",
                        [stl["helptext-animation"]]: !hiddenErrorTips,
                    },
                )}
                style={{
                    ...errorColor,
                    // 增加错误提示弹窗动效
                    minHeight: hiddenErrorTips ? 0 : "0.56rem",
                    opacity: hiddenErrorTips ? 0 : 1,
                }}
            >
                {helperText}
            </p>
            <div
                className={classnames(
                    stl["input-content"],
                    inputClasses?.outline,
                )}
                style={inputWrapperHeight}
            >
                <OutlinedInput
                    autoComplete={props.autoComplete ?? "on"}
                    id={props.id}
                    error={error}
                    classes={{
                        root: classnames(
                            stl.root_input,
                            stl[
                                `border-radius-${borderRadiusType ?? "square"}`
                            ],
                            `border-radius-${borderRadiusType || "square"}`,
                            stl[`border-status-${borderStatus}`],
                            `border-status-${borderStatus}`,
                            root,
                            {
                                [stl["border-status-diasbled"]]: props.disabled,
                            },
                        ),
                        notchedOutline: classnames(
                            stl["notched-out-line"],
                            "notched-outline",
                            notchedOutline,
                        ),
                        input: classnames(
                            stl.input_content,
                            "input_content",
                            input,
                        ),
                        ...restClasses,
                    }}
                    style={errorColor}
                    ref={inputRef}
                    value={props.value}
                    onChange={props.onChange}
                    onFocus={handleFocus}
                    onBlur={(e) => handleBlur(e)}
                    onClick={(e) => handleClick(e)}
                    endAdornment={
                        props.endAdornment ?? (
                            <InputAdornment
                                position="end"
                                classes={{
                                    root: classnames(stl.adornment, {
                                        ["hidden"]: !(
                                            String(props.value).trim()
                                                .length !== 0 && clearable
                                        ),
                                    }),
                                }}
                            >
                                <FmIcon
                                    icon={"icon--close"}
                                    className={classnames(stl.close_icon)}
                                    aria-label="toggle password visibility"
                                    color="inherit"
                                    onClick={onClear}
                                />
                            </InputAdornment>
                        )
                    }
                    placeholder={props.placeholder}
                    {...restProps}
                    label={labelMemo}
                />
                <FmIcon
                    className={classnames(stl.search_icon_btn, "search-icon", {
                        ["hidden"]: !(showSearchBtn ?? false),
                    })}
                    icon={"icon--search"}
                    sx={{ fontSize: "20px", color: "#fff" }}
                    onClick={(e) => onSearch?.(e)}
                ></FmIcon>
            </div>
            {errorPosition === "bottom" && (
                <p
                    className={classnames(
                        stl["error-bottom-tips"],
                        helperTextClassName,
                        inputClasses?.helperText,
                    )}
                    style={errorColor}
                >
                    {helperText}
                </p>
            )}
        </FormControl>
    );
});

export default FmInput;

export { type InputProps };
