import {
    FmDrawer,
    FmInput,
    IDrawerProps,
    FmTabs,
    FmToast,
    InputProps,
    FmIcon,
} from "@/ui-component";
import React, {
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import stl from "./search.module.scss";
import classnames from "classnames";
import { WholePropsIncludeAddPreFixAndOtherProps } from "@/ui-component/interface";
import { searchTab } from "@/store";
import dynamic from "next/dynamic";
import {
    SearchProductsSkeleton,
    SearchArticlesSkeleton,
    HelpCenterSkeleton,
    SearchOrderListSkeleton,
} from "@/src/components/Header/headerBarSkeleton/searchIcon";
const SearchProducts = dynamic(() => import("./tabPanel/SearchProducts"), {
    loading: () => <SearchProductsSkeleton />,
});
const HelpCenter = dynamic(() => import("./tabPanel/HelpCenter"), {
    loading: () => <HelpCenterSkeleton />,
});
const SearchArticles = dynamic(() => import("./tabPanel/SearchArticles"), {
    loading: () => <SearchArticlesSkeleton />,
});
const SearchOrder = dynamic(() => import("./tabPanel/SearchOrder"), {
    loading: () => <SearchOrderListSkeleton />,
});
import { ES_SEARCH_TYPE, LOCAL_STORAGE_NAMES } from "@/config/constConf";
import {
    getOrderCode,
    getOrderInfos,
    getTrackInfo,
    isLogin,
} from "@/src/models/loginModel";
import { gaCommonEvent } from "@/src/utils/gtagEvents";
import { setUserRecentSearchesToLocal } from "@/lib/esSearch";
import {
    esSearchData,
    getPopularSearches,
    getProductSuggestions,
    getRecentSearches,
} from "@/src/models/esSearch";
import { IStyleProps } from "@/src/app/interface";
import { useFilterConditionContext } from "@/src/provider";
import AppBar from "@/src/components/AppBar";
import reddit from "@/src/components/LoadAnalysis/analysis/reddit";
import { useTranslation } from "@/src/i18n/client";
import { usePathname } from "next/navigation";
import { getLocalItem } from "@/lib/commonService";

const order_test = /^\d*$/g;
const zg = /[><》《「」『』￠￢￣#%&]/;
type ExtendsProps = WholePropsIncludeAddPreFixAndOtherProps<
    IDrawerProps,
    InputProps,
    "input"
>;
interface IProps extends ExtendsProps {
    onClose: () => any;
}

// 在tabChildren插入的dom节点
export interface ITabChildren extends IStyleProps {
    insertTips: ReactNode;
    isOpen: boolean;
}

interface ISearchProps extends IStyleProps {
    // type: number;
    onClose: Function;
}

let timer: NodeJS.Timeout | undefined = undefined;
const Search: React.FC<ISearchProps> = (props) => {
    // search-btn
    const [toastVisible, setToastVisible] = useState(false);
    const { t } = useTranslation("common");
    const pathname = usePathname() ?? "";
    const [
        error_tip,
        search,
        isShowOrder,
        search_type_list,
        type,
        onSearchError,
        setData,
        onOrderShowChange,
        getSearchData,
    ] = searchTab.useSearchTabStore((state) => [
        state.error_tip,
        state.search,
        state.isShowOrder,
        state.search_type_list,
        state.type,
        state.onSearchError,
        state.setData,
        state.onOrderShowChange,
        state.getSearchData,
    ]);

    // 将placeholder变成一个以searchTabStore.search_type_list的value为key的对象
    const placeholderObj: Record<number, any> = useMemo(() => {
        const res = {};
        for (let item of search_type_list) {
            const { value, ...rest } = item;
            Object.assign(res, {
                [value]: rest,
            });
        }

        return res;
    }, [search_type_list]);

    const [isFilter, initFilterConditions] = useFilterConditionContext(
        (state) => [state.isFilter, state.initFilterConditions],
    );

    const initOrderData = useCallback(
        (orderData: any) => {
            getOrderCode(search, null).then((data: any) => {
                data &&
                    getOrderInfos(data).then((arg: any) => {
                        setToastVisible(false);
                        onOrderShowChange(true);
                        setData({
                            code: 200,
                            error_tip: "",
                            empty_error: false,
                        });
                    });
            });
        },
        [onOrderShowChange, search, setData],
    );

    //订单搜索，回调为正常搜索流程
    const onOrderSearch = useCallback(
        async (callBack?: () => void) => {
            if (type === ES_SEARCH_TYPE.ORDERS) {
                //未登录不能搜索订单
                if (!isLogin()) {
                    onSearchError(
                        `${t("common:PLEASE")} ${t("common:login")} ${t(
                            "common:ORDER_LOGIN_TIPS",
                        )}`,
                    );
                    return;
                }
            }

            if (!new RegExp(order_test).test(search)) {
                if (callBack) {
                    callBack();
                } else {
                    onOrderShowChange(false);
                    onSearchError(`${t("common:search:ErrorTip")} "${search}"`);
                }
                return;
            }

            setToastVisible(true);
            getTrackInfo(search)
                .then((arg: any) => {
                    initOrderData(arg);
                })
                .catch(() => {
                    setToastVisible(false);
                    if (callBack) {
                        callBack();
                    } else {
                        onSearchError(
                            `${t("common:search:ErrorTip")} "${search}"`,
                        );
                        onOrderShowChange(false);
                    }
                });
        },
        [type, search, onSearchError, t, onOrderShowChange, initOrderData],
    );

    const onGoToSearchLocation = useCallback(() => {
        isShowOrder && onOrderShowChange(false);
        setUserRecentSearchesToLocal(search);

        gaCommonEvent("searching_click", {
            event_category: "top navigation bar",
            event_label: search,
        });

        window.location.href = `/search-eyewear-keyword-${search}.html?type=${type}`;
    }, [isShowOrder, onOrderShowChange, search, type]);

    //输入文字，或者切换type，搜索即时更新
    const onSearchRefresh = useCallback(
        async (searchType = 1) => {
            // 在搜索页面时，清除搜索页面商品筛选项
            if (pathname.indexOf("search-eyewear-keyword") > -1) {
                isFilter && initFilterConditions();
            }
            gaCommonEvent("r-searching_click", {
                event_category: "top navigation bar",
                event_label: search,
            });
            await getSearchData({
                type,
                search,
                searchType,
            });
        },
        [pathname, isFilter, initFilterConditions, search, getSearchData, type],
    );

    const handleSearch = useCallback(
        (e: any) => {
            if (!search) {
                onSearchError(t("common:search:InvalidSearch"));
                return;
            }
            e.preventDefault();
            if (error_tip) {
                return;
            }
            if (type === ES_SEARCH_TYPE.ORDERS) {
                onOrderSearch();
                return;
            }
            if (type === ES_SEARCH_TYPE.ALL) {
                onOrderSearch(onGoToSearchLocation);
                return;
            }
            setToastVisible(true);
            esSearchData({
                search,
                type,
            }).then((data: any) => {
                if (data.linkUrl) {
                    window.location.href = data.linkUrl;
                } else {
                    onGoToSearchLocation();
                }
                setToastVisible(false);
            });
        },
        [
            search,
            error_tip,
            type,
            onSearchError,
            t,
            onOrderSearch,
            onGoToSearchLocation,
        ],
    );

    //searchType 1:普通搜索 0:实时搜索
    const onSearchData = useCallback(
        (searchType: number = 1) => {
            reddit.Search();
            if (type === ES_SEARCH_TYPE.ORDERS) {
                onOrderSearch();
                return;
            }
            if (type === ES_SEARCH_TYPE.ALL) {
                onOrderSearch(() => {
                    onSearchRefresh(searchType);
                });
                return;
            }
            onSearchRefresh(searchType);
        },
        [onOrderSearch, onSearchRefresh, type],
    );

    const checkSearch = useCallback(
        (value: string, searchType = 0) => {
            //字符限制32个字符,超过32个字符提示
            //特殊字符软验证，如*%等这类字符,输入异常字符提示

            if (value.length > 32) {
                onSearchError(t("common:search:MaximumCharacter"));
                timer && clearTimeout(timer);
                return false;
            }

            if (!value) {
                setData({
                    error_tip: "",
                    empty_error: false,
                    type_loading: false,
                });
                timer && clearTimeout(timer);
                return false;
            }

            if (zg.test(value)) {
                onSearchError(t("common:search:SpecialCharacters"));
                timer && clearTimeout(timer);
                return false;
            }
            setData({ error_tip: "", searchType });
            timer && clearTimeout(timer);
            timer = setTimeout(() => {
                onSearchData(searchType);
            }, 1000);
        },
        [onSearchData, onSearchError, setData, t],
    );

    // 监听inputValue变化以做校验
    useEffect(() => {
        checkSearch(search);
    }, [checkSearch, search]);

    return (
        <AppBar>
            <div
                className={classnames(
                    stl["search-input-container"],
                    "search-input-container",
                )}
            >
                <FmIcon
                    icon={"icon--arrow-left"}
                    onClick={() => props.onClose()}
                    className={stl["arrow-left"]}
                />
                <div className={stl["search-picker-and-input"]}>
                    {/* 搜索内容输入 */}
                    <FmInput
                        id={"search-input"}
                        borderRadiusType={"round"}
                        showSearchBtn
                        classes={{
                            root: classnames("root-input-here"),
                            input: classnames("input"),
                        }}
                        value={search}
                        placeholder={t(
                            `common:search:${placeholderObj[type].placeholder}`,
                        )}
                        fullWidth
                        onChange={(e) => setData({ search: e.target.value })}
                        onClear={() => setData({ search: "" })}
                        onSearch={(e) => handleSearch(e)}
                        clearable
                    />
                </div>
                <FmToast visible={toastVisible} type={"loading"} />
            </div>
        </AppBar>
    );
};

function getCurrentChildren(
    tabValue: number,
    insertTips: ReactNode,
    isOpen: boolean = false,
) {
    switch (tabValue) {
        case ES_SEARCH_TYPE.ALL: // all
        case ES_SEARCH_TYPE.PRODUCTS: // products
            return (
                <SearchProducts
                    className={`tabpanel-products-${tabValue}`}
                    insertTips={insertTips}
                    isOpen={isOpen}
                />
            );
        case ES_SEARCH_TYPE.ARTICLES: // ARTICLES
            return (
                <SearchArticles
                    className={`tabpanel-article-${tabValue}`}
                    insertTips={insertTips}
                    isOpen={isOpen}
                />
            );
        case ES_SEARCH_TYPE.HELP:
            return (
                <HelpCenter
                    className={`tabpanel-help-${tabValue}`}
                    insertTips={insertTips}
                    isOpen={isOpen}
                />
            );
        case ES_SEARCH_TYPE.ORDERS:
            return (
                <SearchOrder
                    className={`tabpanel-order-${tabValue}`}
                    insertTips={insertTips}
                    isOpen={isOpen}
                    // domain={domain}
                />
            );
        default:
    }
}
const SearchDrawer: React.FC<IProps> = (props) => {
    const { t } = useTranslation("common");
    // redux：用redux的searchTypeList设置tab和placeholder
    const [
        search_type_list,
        empty_error,
        error_tip,
        search,
        type,
        setData,
        resent_keys,
        hot_products,
        hot_keys,
    ] = searchTab.useSearchTabStore((state) => [
        state.search_type_list, // 出此下策的原因是search_type_list的顺序排序见鬼了，而tab又默认以index作为value值返回
        state.empty_error,
        state.error_tip,
        state.search,
        state.type,
        state.setData,
        state.resent_keys, //用户搜索记录
        state.hot_products,
        state.hot_keys, // 关键词热搜记录
    ]);

    // 用户第二次展示这个面板的时候，数据无感更新(页面刷新执行？)
    useEffect(() => {
        props.open &&
            (async () => {
                try {
                    let recent: any[] = [...resent_keys],
                        popular_data: any[] = [...hot_keys],
                        hot_data: any[] = [...hot_products];
                    if (isLogin()) {
                        const recentRes = await getRecentSearches();
                        recent = recentRes.data;
                    } else {
                        recent =
                            getLocalItem(
                                LOCAL_STORAGE_NAMES.USER_RESENT_SEARCH,
                            ) ?? [];
                    }

                    if (hot_keys.length <= 0) {
                        const popular = await getPopularSearches();
                        popular_data = popular.data;
                    }

                    if (hot_products.length <= 0) {
                        const hotDataRes = await getProductSuggestions();
                        hot_data = hotDataRes.data;
                    }

                    setData({
                        resent_keys: recent,
                        hot_keys: popular_data,
                        hot_products: hot_data,
                    }); // 近期搜索
                } catch (e) {
                    console.error(e);
                } finally {
                }
            })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open]);

    // 搜索错误提示
    const errorTipsDom = useMemo(() => {
        return (
            <p className={stl["search-error"]}>
                {error_tip || (
                    <span>
                        {empty_error &&
                            `${t("common:search:ErrorTip")} "${search}"`}
                    </span>
                )}
            </p>
        );
    }, [empty_error, error_tip, search, t]);

    const searchInput = useMemo(() => {
        return <Search onClose={props.onClose} />;
    }, [props.onClose]);

    const tabs = useMemo(() => {
        search_type_list.sort((a, b) => a.value - b.value);
        return search_type_list.map((item, index) => {
            return {
                label: t(`common:search:${item.label}`),
                children: getCurrentChildren(
                    item.value,
                    errorTipsDom,
                    props.open,
                ),
                value: item.value,
                classes: {
                    root: stl["tab-root"],
                },
            };
        });
    }, [errorTipsDom, props.open, search_type_list, t]);

    const handleChangeTabs = useCallback(
        (value: number) => {
            setData({ type: value });
            // 切换input的placeholder
        },
        [setData],
    );
    return (
        <FmDrawer
            anchor={props.anchor}
            open={props.open}
            headerChildren={searchInput} // search-input-component
            headerClassName={stl["header-modals"]}
            fullHeight
            boxClassName={stl["search-drawer-box"]}
            className={stl["search-drawer-container"]}
            childrenClassName={stl["search-drawer-children-content"]}
        >
            {props.open && (
                <FmTabs
                    tabsClassName={stl["tabs-wrapper-container"]}
                    tabs={tabs}
                    value={type}
                    onChange={(e, value) => handleChangeTabs(value)}
                    tabPanelClassName={classnames(stl["tab-panel-container"], {
                        [stl["helpcenter"]]: type === ES_SEARCH_TYPE.HELP,
                    })}
                    className={stl["tabs-container"]}
                />
            )}
        </FmDrawer>
    );
};

export default SearchDrawer;
