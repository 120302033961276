import { esSearchData, getArticlesSuggestions } from "@/src/models/esSearch";
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { ISearchInfoStateAndAction, initialState } from "./initialState";

export const useSearchTabStore = create<ISearchInfoStateAndAction>()(
    devtools(
        persist(
            (set, get) => ({
                ...initialState,
                setData: async (data) => {
                    set(data);
                },
                getArticlesSuggestions: async () => {
                    const hotArticlesData = await getArticlesSuggestions();
                    set({
                        hot_articles: hotArticlesData.data,
                    });
                },
                onSearchError(error_tip: string) {
                    set({
                        error_tip,
                        code: 204,
                        type_loading: false,
                        isShowOrder: false,
                        help: {
                            pagination: {},
                            data: [],
                        },
                        articles: {
                            pagination: {},
                            data: [],
                        },
                        products: {
                            pagination: {},
                            data: [],
                        },
                    });
                },
                onOrderShowChange(isShow: boolean) {
                    set({ type_loading: false, isShowOrder: isShow });
                },
                getSearchData: async (data) => {
                    const searchRes: any = await esSearchData(data);
                    set({
                        ...data,
                        linkUrl: searchRes.linkUrl,
                        empty_error: searchRes.code === 204,
                        products: {
                            data: [...searchRes.data.products.data],
                            pagination: searchRes.data.products.pagination,
                        },
                        articles: searchRes.data.articles,
                        help: searchRes.data.help,
                    });
                },
            }),
            {
                name: "searchbar-storage",
                storage: createJSONStorage(() => sessionStorage),
                skipHydration: true,
            },
        ),
        {
            name: "searchbar-store",
        },
    ),
);
