"use client";
import React, {
    forwardRef,
    useCallback,
    useImperativeHandle,
    useMemo,
    useRef,
} from "react";
import { Button, ButtonProps, ButtonClassKey, styled } from "@mui/material";
import classnames from "classnames";
import stl from "../styles/button.module.scss";
import FmIcon, { IIconProps } from "./FmIcon";
import {
    AddPreFixProps,
    ExcludeT1KeyInT2Props,
    ExtractT1KeyInT2Props,
    IStyleProps,
    RecordProps,
} from "../interface";
type IWidth = "auto" | "full" | "half";

// 为button某些属性增添可选项

// 继承了外部样式，所以需要rename外部样式中和本props样式重复的样式，为其添加对应组件前缀

// 1.颜色定义额外的接口
interface IColor {
    // 主色
    buttonColor?: string; //! 请自动转换颜色，目前没有实现比较好的方式
}

interface IColorButton {
    buttoncolor?: string;
}

// 把buttonProps的color干掉
type ButtonP = ExcludeT1KeyInT2Props<ButtonProps, IColor>;

type RecordP<T1 extends keyof T2, T2> = {
    [K in T1]?: T2[K];
};

type ButtonPPP = RecordP<ButtonP, ButtonProps>;

interface IProps extends ButtonPPP, IColor, IStyleProps {
    width?: IWidth | string;
    iconLeft?: boolean;
    iconRight?: boolean;
    iconClassName?: string;
    loading?: boolean;
    contentClassName?: string;
}

// 1.取iconProps中和IProps共同的属性，添加前缀
type PreIconProps = AddPreFixProps<
    ExtractT1KeyInT2Props<IProps, IIconProps>,
    "icon"
>;
// 2. 取icon中其他的属性
type OtherIconProps = RecordProps<
    ExcludeT1KeyInT2Props<IIconProps, IProps>,
    IIconProps
>;
// 3.改造后的iconprops
type IconProps = PreIconProps & OtherIconProps;

export type IButtonProps = IProps & IconProps;
const defaultProps: IButtonProps = {
    variant: "contained",
    size: "medium",
    iconLeft: false,
    loading: false,
};

const widthConfig: Record<IWidth, string> = {
    full: "100%",
    half: "50%",
    auto: "auto",
};

// const colorListByType
interface IButtonSet {
    buttoncolor: string;
    theme: any;
}

const buttonColorSet = ({ buttoncolor, theme }: IButtonSet) => {
    return {
        outlined: {
            color: `${buttoncolor} !important`,
            backgroundColor: "transparent !important",
            border: `1px solid ${buttoncolor} !important`,
        },
        text: {
            color: `${buttoncolor} !important`,
            backgroundColor: `transparent !important`,
            border: "none",
        },
        contained: {
            color: `${theme.palette.getContrastText(buttoncolor)} !important`,
            backgroundColor: `${buttoncolor} !important`,
        },
    };
};
const ColorButton = styled(Button)<IButtonProps & IColorButton>((props) => {
    const { buttoncolor, theme, variant } = props;
    return buttoncolor
        ? buttonColorSet({ buttoncolor, theme })[variant ?? "contained"]
        : null;
});

const FmButton = forwardRef(function FmButton(prop: IButtonProps, ref) {
    const {
        loading,
        width,
        contentClassName,
        iconLeft,
        iconRight,
        iconClassName,
        classes,
        children,
        buttonColor,
        style,
        ...propRes
    } = useMemo(() => {
        return { ...defaultProps, ...prop };
    }, [prop]);

    const buttonRef = useRef<HTMLButtonElement>(null);

    useImperativeHandle(ref, () => {
        return buttonRef.current;
    }, []);

    const upperFirstWords = useCallback(
        (str: string | undefined, upperType: string = "") => {
            if (!str) {
                // undefined
                return "";
            }
            // string
            let firstWord = str.split("")[0];
            let reg = `${firstWord}.*?`; // 避免出现多次匹配，所以需要做惰性匹配模式
            let lastWords = str.replace(
                new RegExp(reg),
                firstWord.toUpperCase(),
            );
            return `${upperType}${lastWords}`;
        },
        [],
    );
    const classesName = useMemo(() => {
        return {
            root: classnames(stl.root, stl.button_container, propRes.className), // 总元素样式（common）
            // variant-color
            [`${propRes.variant}${upperFirstWords(propRes.color)}`]: classnames(
                propRes.color ? stl[propRes.color] : "",
            ),
            // variant?-Size-size
            [`${propRes.variant}${upperFirstWords(
                propRes.size,
                "Size",
            )}` as ButtonClassKey]: classnames(
                stl[propRes.size!],
                propRes.size,
                stl[propRes.variant!],
            ),
            // disabled
            disabled: classnames(stl.disabled, stl.disabled_color),
            // disableElevation
            // focusVisible
            // colorInherit
            //size-size
            [upperFirstWords(propRes.size, "size")]: classnames(),
            // fullWidth
            fullWidth: classnames(stl["full-width"]),
            // startIcon
            // endIcon
            // icon-size-size
            [`icon${upperFirstWords(propRes.size, "Size")}`]: classnames(),
        };
    }, [
        propRes.className,
        propRes.color,
        propRes.size,
        propRes.variant,
        upperFirstWords,
    ]);

    const iconBefore = useMemo(() => {
        return propRes.icon && iconLeft ? (
            <FmIcon
                className={classnames(
                    stl["icon"],
                    stl["icon-left"],
                    iconClassName,
                )}
                icon={propRes.icon}
            />
        ) : null;
    }, [propRes.icon, iconClassName, iconLeft]);
    const iconAfter = useMemo(() => {
        return propRes.icon && iconRight ? (
            <FmIcon
                className={classnames(
                    stl["icon"],
                    stl["icon-right"],
                    iconClassName,
                )}
                icon={propRes.icon}
            />
        ) : null;
    }, [propRes.icon, iconClassName, iconRight]);

    const buttonContent = useMemo(() => {
        return (
            <div
                className={classnames(
                    stl["button-container"],
                    {
                        [stl["visibility-hidden"]]: loading,
                    },
                    contentClassName,
                )}
            >
                {iconBefore}
                {children}
                {iconAfter}
            </div>
        );
    }, [children, iconAfter, iconBefore, contentClassName, loading]);

    const widthStyle = useMemo(() => {
        return {
            width:
                typeof width !== "undefined" && widthConfig[width as IWidth]
                    ? widthConfig[width as IWidth]
                    : width,
        };
    }, [width]);
    return (
        <ColorButton
            buttoncolor={buttonColor}
            style={{ ...widthStyle, ...style }}
            classes={{ ...classesName, ...classes }}
            {...propRes}
            ref={buttonRef}
        >
            {loading ? (
                <FmIcon
                    icon={"icon-loading"}
                    className={stl["loading-animation"]}
                />
            ) : null}
            {buttonContent}
        </ColorButton>
    );
});

export default FmButton;
