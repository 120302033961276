"use client";
import React, { useMemo } from "react";
import { FmAccordion, FmImage, FmLink, ISingleAccordion } from "@/ui-component";
import classnames from "classnames";
import stl from "./navlist.module.scss";
import { usePageLayoutContext } from "@/src/provider";

interface INavListProps {
    closeModal: () => void;
}

const NavList: React.FC<INavListProps> = ({ closeModal }) => {
    const nav = usePageLayoutContext((state) => state.nav);

    const accordionData = useMemo<ISingleAccordion[]>(() => {
        if (nav.length === 0) {
            return [];
        }
        return nav.map((item) => {
            let linkArr: React.ReactElement[] = []; // 链接
            let imageArr: React.ReactElement[] = []; // 图片链接
            if (item.isChild) {
                linkArr = item.textList.map((text) => {
                    return (
                        <FmLink
                            // className={classnames(stl.fm_link)}
                            color="inherit"
                            key={text.name}
                            href={text.href}
                            title={text.name}
                            onClickCapture={() => closeModal()}
                        >
                            {text.name}
                        </FmLink>
                    );
                });
                imageArr = item.imgList.map((img) => {
                    return (
                        <div key={img.name} className={stl.image_content}>
                            <FmLink href={img.href} className={stl.img_link}>
                                <FmImage
                                    width={103}
                                    height={103}
                                    alt={""}
                                    image={img.img}
                                    placeholder="blur"
                                    // onClick={() => closeModal()}
                                />
                            </FmLink>
                            <p className={stl.img_title}>{img.name}</p>
                        </div>
                    );
                });
                return {
                    id: item.title,
                    title: item.title,
                    key: item.title,
                    details: {
                        classes: { root: stl["nav-wrapper"] },
                        children: (
                            <>
                                <>{linkArr}</>
                                <div
                                    className={classnames(
                                        stl.img_container,
                                        stl["image-wrapper"],
                                    )}
                                >
                                    {imageArr}
                                </div>
                            </>
                        ),
                    },
                };
            } else {
                // link标记
                return {
                    id: item.title,
                    isAccordion: false,
                    title: (
                        <FmLink
                            key={item.href as string}
                            href={item.href!}
                            title={item.title}
                            className={classnames(
                                "add-me",
                                stl.fm_link,
                                stl.normal_link,
                            )}
                            color={"inherit"}
                            onClickCapture={() => closeModal()}
                        >
                            {item.title}
                        </FmLink>
                    ),
                };
            }
        });
    }, [closeModal, nav]);
    return accordionData.length ? (
        <FmAccordion accordions={accordionData} />
    ) : null;
};

export default NavList;
