import { HandlePhpFetch } from "@/src/utils/fetch";
import { RX } from "@/config/api";
import { isLogin } from "../loginModel";
import { getTouristId } from "@/lib/commonService";

export const getRxN = (id: number | string = "") => {
    //获取处方，
    return HandlePhpFetch.get(`${RX}${id}`);
};

export const rxFormEdit = async (
    params: Record<string, any>,
    headers?: Record<string, any>,
) => {
    //新增 | 修改处方
    let touristId = undefined;
    if (!isLogin()) {
        await getTouristId().then((id) => {
            touristId = id;
        });
    }
    params.tourist_id = touristId ?? "";
    return HandlePhpFetch.post(RX, params, { headers });
};
