import { REGISTER_API, FAVORITE } from "@/config/api";
import HandlePhpFetch from "@/src/utils/fetch/PhpFetch";
import { handleGid } from "./CPUChannelData";

export const postAccount = (id: any, data: any) => {
    return HandlePhpFetch.post(REGISTER_API + id, data);
};

export const getAccount = async (id?: string, data?: any) => {
    const url =
        typeof id === "undefined"
            ? REGISTER_API.split("/")[0]
            : REGISTER_API + id;

    return HandlePhpFetch.get(url, data).then((res) => {
        handleGid({
            userId: res.user_id,
            customerId: res.customers_id,
            email: res.customers_email_address,
        });
        return res;
    });
};

export const deleteFav = (favorite_id: any) => {
    return HandlePhpFetch.delete(REGISTER_API + FAVORITE, { favorite_id });
};
