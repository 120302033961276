"use client";

import {
    Checkbox,
    CheckboxProps,
    FormControl,
    FormControlLabel,
    FormControlLabelProps,
    FormControlProps,
    FormGroup,
    FormHelperText,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import stl from "../styles/checkbox.module.scss";
import {
    IStyleProps,
    WholePropsIncludeAddPreFixAndOtherProps,
} from "../interface";
import classnames from "classnames";
import FmIcon from "./FmIcon";

export interface IFormControlLabel<T> {
    formControlContainerClassName?: string;
    formControlLabelClassName?: string;
    labelClassName?: string;
    value: T;
}
export type IFormLabel<T> = WholePropsIncludeAddPreFixAndOtherProps<
    FormControlLabelProps,
    CheckboxProps,
    "checkbox"
> &
    IFormControlLabel<T>;

export interface ICheckboxProps<T> extends IStyleProps {
    dataSource: Array<IFormLabel<T>>;
    error?: FormControlProps["error"];
    note?: string;
    values: T[] | T;
    onChange?: (values: T[] | T) => void;
    noteClassName?: string;
    checkboxClassName?: string;
    formControlClassName?: string;
    noDefaultInteraction?: boolean;
}
const FmCheckbox = <T,>(props: ICheckboxProps<T>) => {
    const { onChange } = useMemo(() => props, [props]);
    // 兼容values可能是单独string | number，不处理成数组的情况
    const values = useMemo<T[]>(() => {
        // 1.数组；2.存在；3.string | number
        if (Array.isArray(props.values)) {
            return props.values.length > 0
                ? props.values.map((item) => item?.toString())
                : [];
        } else if (
            typeof props.values === "string" ||
            typeof props.values === "number"
        ) {
            return [props.values.toString()];
        }
        return [] as any[];
    }, [props.values]);

    // 转成数组
    const [valuesTemp, setValuesTemp] = useState(values);

    useEffect(() => {
        setValuesTemp(values);
    }, [values]);

    const handleItemChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const currentValue = e.target.value.toString();
            // values里是否存在该value， 存在？删除，且not checked : 存入且checked
            let vals = [...valuesTemp]; // 复制一份
            if (vals.includes(currentValue as T)) {
                vals = vals.filter((item) => item !== currentValue); // 挪出来
            } else {
                vals.push(currentValue as T);
            }

            vals = vals.filter((item) => item !== "");

            onChange?.(props.dataSource.length > 1 ? vals : vals[0]); // 可选项列表只有一个的时候返回单独string | number；否则返回数组
        },
        [onChange, props.dataSource.length, valuesTemp],
    );
    const checkboxList = useMemo(() => {
        return props.dataSource.map((item, index) => {
            const {
                formControlContainerClassName, // wrapper
                formControlLabelClassName, // 给整个label
                labelClassName, // 给label的文案
                checkboxName, // 给label的选择框
                checkboxClasses,
                checkboxDisabled,
                ...restItem
            } = item;
            return (
                <div
                    className={classnames(
                        stl["form-control-label-container"],
                        {
                            [stl["custom-interation"]]:
                                props.noDefaultInteraction,
                        },
                        formControlContainerClassName,
                    )}
                    key={`${item.value}`}
                >
                    <FormControlLabel
                        className={classnames(
                            stl["form-control-label"],
                            formControlLabelClassName,
                        )}
                        control={
                            <Checkbox
                                // size="small"
                                {...restItem}
                                name={checkboxName}
                                onChange={handleItemChange}
                                checked={valuesTemp?.includes(
                                    item.value?.toString() as T,
                                )}
                                classes={{
                                    root: classnames(
                                        stl["checkbox-label-item"],
                                        props.checkboxClassName,
                                    ),
                                    checked: classnames(
                                        stl["checked-item"],
                                        "checked-one",
                                    ),
                                    disabled: stl["checked-disabled-item"],
                                }}
                                sx={{
                                    "& .MuiSvgIcon-root": {
                                        fontSize: "0.48rem",
                                    },
                                }}
                                icon={<></>}
                                checkedIcon={
                                    <FmIcon
                                        icon={"icon--checked"}
                                        className={stl["checked-icon-font"]}
                                    />
                                }
                                disabled={checkboxDisabled}
                            />
                        }
                        label={props.noDefaultInteraction ? "" : item.label}
                        classes={{
                            label: classnames(
                                stl["form-label"],
                                labelClassName,
                            ),
                            ...checkboxClasses,
                        }}
                    />
                    <span
                        className={classnames(
                            stl["config-label"],
                            stl["form-label"],
                            labelClassName,
                            {
                                ["hidden"]: !props.noDefaultInteraction,
                                [stl["checked"]]: valuesTemp?.includes(
                                    item.value?.toString() as T,
                                ),
                            },
                        )}
                    >
                        {item.label}
                    </span>
                </div>
            );
        });
    }, [
        handleItemChange,
        props.checkboxClassName,
        props.dataSource,
        props.noDefaultInteraction,
        valuesTemp,
    ]);
    return (
        <FormControl
            error={props.error}
            variant="standard"
            className={classnames(
                stl["form-control"],
                props.formControlClassName,
            )}
        >
            <FormGroup
                className={classnames(props.className, stl["form-group"])}
                style={props.style}
                name="checkbox-buttons-group"
            >
                {checkboxList}
            </FormGroup>
            <FormHelperText
                className={classnames(stl["note"], props.noteClassName, {
                    ["hidden"]: !props.note,
                })}
            >
                {props.note}
            </FormHelperText>
        </FormControl>
    );
};

export default FmCheckbox;
