import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { StateAction, initialState } from "./initialState";
import { ISizeConversionCanvasData } from "@/src/productDetails/SizeChart/SizeConversionCanvas";

export const useProdcutDetailsStore = create<StateAction>()(
    devtools(
        (set, get) => ({
            ...initialState,
            onChangeSwiperIndex(swiperIndex: number) {
                set({
                    swiperIndex,
                });
            },
            changeVideoIndex(videoIndex: number) {
                set({ videoIndex });
            },
            // 产品图
            changeImgSrc(imgSrc: ISizeConversionCanvasData[]) {
                set({
                    imgSrc,
                });
            },
            setImgIndex(imgNum) {
                set({
                    imgNum,
                });
            },
            setVideoAndSizeImgIndex(videoAndSizeImgNum) {
                set({
                    videoAndSizeImgNum,
                });
            },
        }),
        {
            name: "product-page-details-storage",
        },
    ),
);
