"use client";
import { ProductPackageAction } from "@/store/modules/productPackage";
import { useContext } from "react";
import { useStore } from "zustand";
import { ProductPackageContext } from "./Provider";

export function useProductPackageContext<T>(
    selector: (state: ProductPackageAction) => T,
): T {
    const store = useContext(ProductPackageContext);
    if (!store)
        throw new Error("Missing ProductPackageContext.Provider in the tree");
    return useStore(store, selector);
}

export { ProductPackageProvider } from "./Provider";
