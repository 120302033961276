"use client";
import {
    PrescriptionStore,
    createPrescriptionStore,
} from "@/store/modules/prescription";
import React, { createContext, useRef } from "react";
export const PrescriptionContext = createContext<PrescriptionStore | null>(
    null,
);

interface PrescriptionProviderProps
    extends React.PropsWithChildren<PrescriptionStore> {}

export function PrescriptionProvider({ children, ...props }: any) {
    const storeRef = useRef<PrescriptionStore>();
    if (!storeRef.current) {
        storeRef.current = createPrescriptionStore(props);
    }
    return (
        <PrescriptionContext.Provider value={storeRef.current}>
            {children}
        </PrescriptionContext.Provider>
    );
}
