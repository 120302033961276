import { ISizeConversionCanvasData } from "@/src/productDetails/SizeChart/SizeConversionCanvas";

export interface IProductDetails {
    swiperIndex: number;
    videoIndex: number;
    imgSrc: ISizeConversionCanvasData[]; // 产品图
    isImgLoading: boolean;
    imgNum: number; // 产品图num
    videoAndSizeImgNum: number; // (尺寸图 + 视频图)num
}
export type State = IProductDetails;

export type Action = {
    onChangeSwiperIndex: (swiperIndex: number) => void;
    changeVideoIndex: (videoIndex: number) => void;
    changeImgSrc: (imgSrc: ISizeConversionCanvasData[]) => void;
    setImgIndex: (imgNum: number) => void;
    setVideoAndSizeImgIndex: (videoAndSizeImgNum: number) => void;
};

export type StateAction = State & Action;

export const initialState: State = {
    swiperIndex: 0,
    videoIndex: -1,
    imgSrc: [],
    imgNum: 0,
    videoAndSizeImgNum: 0,
    isImgLoading: false,
};
