// 解析url, 拿到’？‘后的参数组成对象，如果没有参数则返回空对象
export default function parse_url(url: string): Record<string, any> | null {
  //解析url
  let pattern = /(\w+)=(\w+)/gi; //定义正则表达式
  let parames: Record<string, any> = {};
  let paramsArr = url.match(pattern); // 数组
  if (!paramsArr?.length) {
    return null;
  }
  paramsArr.map((item) => (parames[item.split("=")[0]] = item.split("=")[1]));
  return parames; //返回这个数组.
}
