/**
 * 分享组件，应用于非阿波罗可配置的模块，现有：首页侧边栏，帮助中心，商品详情页分享，下单成功页面(非footer部分)
 */
"use client";
import React, { useMemo } from "react";
import { FmIcon, FmImage, FmLink } from "@/ui-component";
import { useCommonContext } from "@/src/provider";
import stl from "./share.module.scss";
import classnames from "classnames";
import { getSiteConfiguration } from "@/src/utils/configUtils";
import { useTranslation } from "@/src/i18n/client";
interface IProps {
    openShareWindow: boolean; // 是否在当前页打开窗口
    isProductDetail?: boolean; // 是否是产品详情页
}

const ShareComponent: React.FC<IProps> = (props) => {
    const { openShareWindow, isProductDetail } = props;
    const { host, site } = useCommonContext();
    const { t } = useTranslation("common");
    const handleGetSiteConfiguration = (key: string) =>
        getSiteConfiguration(key, host);

    const handleOpenShareWindow = (url: string) => {
        if (!url) return;
        if (openShareWindow) {
            const [w, h] = [500, 600];
            const left = window.document.body.offsetWidth / 2 - w / 2;
            const top = window.document.body.offsetHeight / 2 - h / 2;
            url &&
                window.open(
                    url,
                    "_blank",
                    `width=${w},height=${h},left=${left},top=${top}`,
                );
        }
    };

    // 根据站点展示相应的社交媒体组
    const shareListWithSite = useMemo(() => {
        switch (site) {
            case "us":
                // 北美的产品详情页特殊处理
                if (props.isProductDetail) {
                    return ["facebook", "instagram", "tiktok"];
                }
                return [
                    "facebook",
                    "instagram",
                    "tiktok",
                    "whatsapp",
                    "twitter",
                    "pinterest",
                    "youtube",
                ];

            case "pt":
                return ["facebook"];
            case "it":
            case "br":
            case "cl":
                return ["facebook", "instagram"];
            case "au":
                return [
                    "facebook",
                    "instagram",
                    "youtube",
                    "twitter",
                    "pinterest",
                ];
            case "de":
            case "fr":
            case "es":
            case "mx":
                return ["facebook", "instagram", "tiktok"];
            case "uk":
                return ["facebook", "instagram", "tiktok", "youtube"];
            case "jp":
            default:
                return [];
        }
    }, [props.isProductDetail, site]);

    const handleClickFaceBook = () => {
        if (openShareWindow && t) {
            handleOpenShareWindow(
                `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                    window.location.href,
                )}&quote=${t("common:ShareTitle")}`,
            );
        } else {
            window.open(handleGetSiteConfiguration("facebookUrlInSubscribe"));
        }
    };

    const handleClickTwitter = () => {
        if (openShareWindow && t) {
            handleOpenShareWindow(
                `https://twitter.com/intent/tweet?url=${encodeURIComponent(
                    window.location.href,
                )}&text=${t("common:ShareTitle")}`,
            );
        } else {
            window.open(handleGetSiteConfiguration("twitterUrlInSubscribe"));
        }
    };

    return (
        <div
            className={stl["share-list"]}
            style={{
                justifyContent: `${isProductDetail ? "center" : "flex-start"}`,
            }}
        >
            {shareListWithSite.map((item: string, index: number) => {
                switch (item) {
                    case "facebook":
                        return (
                            <FmLink
                                key={`${item}-${index}`}
                                onClickCapture={() => {
                                    handleClickFaceBook();
                                }}
                                href={"#"}
                                target="_blank"
                                className={stl["share-btn-container"]}
                            >
                                <FmIcon
                                    className={classnames(
                                        stl["h5-share-btn"],
                                        stl["fb-btn"],
                                    )}
                                    icon={"icon--facebook"}
                                    style={{
                                        color: "#fff",
                                        fontSize: "0.4rem",
                                    }}
                                />
                            </FmLink>
                        );
                    case "instagram":
                        return (
                            <FmLink
                                href={handleGetSiteConfiguration(
                                    "instagramUrlInSubscribe",
                                )}
                                target="_blank"
                                className={stl["share-btn-container"]}
                                key={`${item}-${index}`}
                            >
                                <FmImage
                                    className={classnames(
                                        stl["h5-share-btn"],
                                        stl["ins-2x-image"],
                                    )}
                                    image="/__firmoo/static/images/pics/Ins@2x.png"
                                    alt=""
                                />
                            </FmLink>
                        );
                    case "tiktok":
                        return (
                            <FmLink
                                key={`${item}-${index}`}
                                href={handleGetSiteConfiguration(
                                    "tiktokUrlInSubscribe",
                                )}
                                target="_blank"
                                className={stl["share-btn-container"]}
                            >
                                <FmImage
                                    className={classnames(stl["h5-share-btn"])}
                                    image="/__firmoo/static/images/pics/tiktok.png"
                                    alt=""
                                />
                            </FmLink>
                        );
                    case "twitter":
                        return (
                            <FmLink
                                key={`${item}-${index}`}
                                onClickCapture={() => {
                                    handleClickTwitter();
                                }}
                                href={'#"'}
                                target="_blank"
                                className={stl["share-btn-container"]}
                            >
                                <FmIcon
                                    className={classnames(
                                        stl["h5-share-btn"],
                                        stl["twitter-btn"],
                                    )}
                                    icon={"icon--twitter"}
                                    style={{
                                        color: "#fff",
                                        fontSize: "0.4rem",
                                    }}
                                />
                            </FmLink>
                        );
                    case "whatsapp":
                        return (
                            <FmLink
                                key={`${item}-${index}`}
                                href={handleGetSiteConfiguration(
                                    "whatsappUrlInSubscribe",
                                )}
                                target="_blank"
                                className={stl["share-btn-container"]}
                            >
                                <FmImage
                                    className={stl["h5-share-btn"]}
                                    image="/__firmoo/static/images/pics/whatsapp.svg"
                                    alt=""
                                />
                            </FmLink>
                        );
                    case "youtube":
                        return (
                            <FmLink
                                key={`${item}-${index}`}
                                href={handleGetSiteConfiguration(
                                    "youtubeUrlInSubscribe",
                                )}
                                target="_blank"
                                className={stl["share-btn-container"]}
                            >
                                <FmIcon
                                    icon={"icon-youtube"}
                                    className={classnames(
                                        stl["h5-share-btn"],
                                        stl["youtube-btn"],
                                    )}
                                    style={{
                                        color: "#fff",
                                        fontSize: "0.4rem",
                                    }}
                                />
                            </FmLink>
                        );
                    case "pinterest":
                        return (
                            <FmLink
                                key={`${item}-${index}`}
                                href={handleGetSiteConfiguration(
                                    "pinterestUrlInSubscribe",
                                )}
                                target="_blank"
                                className={stl["share-btn-container"]}
                            >
                                <FmIcon
                                    icon={"icon--pinterest"}
                                    className={classnames(
                                        stl["h5-share-btn"],
                                        stl["pinterest-btn"],
                                    )}
                                    style={{
                                        color: "#fff",
                                        fontSize: "0.4rem",
                                    }}
                                />
                            </FmLink>
                        );
                    default:
                        return <></>;
                }
            })}
        </div>
    );
};

export default ShareComponent;
