import { getProductPackage } from "@/src/models/products";
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import {
    initialState,
    ProductPackageAction,
    IProductsPackage,
} from "./initialState";

export type IinitProps = any;
export type ProductPackageStore = ReturnType<typeof createProductPackageStore>;
export const createProductPackageStore = (initProps?: IinitProps) => {
    if (!initProps) {
        return;
    }
    const { data: package_data, total, ...restInit } = initProps;
    const initialProps = {
        ...initialState,
        ...{
            package_data,
            isShowPackBtn: total > 0,
            ...restInit,
        },
    };
    return create<ProductPackageAction>()(
        devtools(
            persist(
                (set, get) => ({
                    ...initialProps,
                    getProductsPackage: async ({
                        productsId,
                        pageId,
                    }: IProductsPackage) => {
                        const res = await getProductPackage({
                            productsId,
                            pageId,
                        });

                        set({
                            loading: false,
                            isShowPackBtn: res.total > 0,
                            package_data: res.data,
                            pageNum: res.pageNum,
                            pageSize: res.pageSize,
                            total: res.total,
                            errors: "",
                        });
                    },
                    selectPackDataIndex: (packDataIndex: number) => {
                        set({
                            packData: get().package_data[packDataIndex] || {},
                            packDataIndex,
                            isShowPackBtn: !!get().package_data[packDataIndex],
                        });
                    },
                }),
                {
                    name: "product-package-storage",
                    storage: createJSONStorage(() => sessionStorage),
                },
            ),
            {
                name: "product-package-store",
            },
        ),
    );
};
