"use client";
import {
    PageInfo,
    PageInfoStore,
    createPageInfoStore,
} from "@/store/modules/pageInfo";
import { createContext, useRef } from "react";
export const PageInfoContext = createContext<PageInfoStore | null>(null);

interface PageInfoProviderProps extends React.PropsWithChildren<PageInfo> {}

export function PageInfoProvider({
    children,
    ...props
}: PageInfoProviderProps) {
    const storeRef = useRef<PageInfoStore>();
    if (!storeRef.current) {
        storeRef.current = createPageInfoStore(props);
    }
    return (
        <PageInfoContext.Provider value={storeRef.current}>
            {children}
        </PageInfoContext.Provider>
    );
}
