// type和初始值initialState
export interface IFindMyFitProps {
  haveFrameInfo: boolean;
  frameInfo: Array<string | number>;
}

export type State = IFindMyFitProps;

export type FindMyFitStateAndAction = State & Action;
export type Action = {
  setFrameInfo: (frameInfo: Array<string | number>) => void;
};

export const initialState: State = {
  haveFrameInfo: false,
  frameInfo: [],
};
