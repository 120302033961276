import React, {
    useReducer,
    useEffect,
    useCallback,
    useState,
    useMemo,
} from "react";
import { findMyFit } from "@/store";
import Cookies from "js-cookie";
import {
    clickMyfitEntry,
    clickMyfitCanotFindSize,
    clickMyfitSubmitSuccess,
    clickMyfitSubmitError,
    clickMyfitEdit,
    clickMyfitDel,
} from "@/src/utils/gtagEvents";
import useUpdateEffect from "@/lib/useUpdateEffect";
import { FmButton, FmIcon, FmImage, FmDrawer } from "@/ui-component";
import stl from "./findMyFit.module.scss";
import classnames from "classnames";
import { State, Action, Props } from "./interface";
import { useTranslation } from "@/src/i18n/client";

const ACTIONS = {
    SET_VAL: "SET_VAL",
    SET_STEP: "SET_STEP",
    INIT: "INIT",
    SET_MYFIT_MODAL_VISIBLE: "SET_MYFIT_MODAL_VISIBLE",
    SET_CONFIRM_MODAL_VISIBLE: "SET_CONFIRM_MODAL_VISIBLE",
    SET_ERROR_NOTICE: "SET_ERROR_NOTICE",
    SET_VISIBLE: "SET_VISIBLE",
};

const initialState: State = {
    lensVal: "",
    bridgeVal: "",
    templeVal: "",
    lensInputRed: false,
    bridgeInputRed: false,
    templeInputRed: false,
    errorNoticeVisible: false,
    isOutOfRange: false,
    step: 1,
    myfitNoticeVisible: false,
    confirmModalVisible: false,
    visible: false,
};

function reducer(state: State = initialState, action: Action) {
    let result = { ...state };
    switch (action.type) {
        // 从redux中读取之前提交的值显示再页面上
        case ACTIONS.INIT:
            result.lensVal = action.frameInfo[0];
            result.bridgeVal = action.frameInfo[1];
            result.templeVal = action.frameInfo[2];
            break;
        // 提交镜架信息
        case ACTIONS.SET_VAL:
            const key: keyof State = action.payload.key;
            const val: string = action.payload.val;
            (result[key] as string) = val;
            break;
        // 通过step控制提交frame信息的dialog显示的内容
        case ACTIONS.SET_STEP:
            result.step = action.step;
            break;
        // 控制提交frame信息的dialog的显隐
        case ACTIONS.SET_MYFIT_MODAL_VISIBLE:
            result.myfitNoticeVisible = action.visible;
            break;
        // 控制删除frame信息的dialog的显隐
        case ACTIONS.SET_CONFIRM_MODAL_VISIBLE:
            result.confirmModalVisible = action.visible;
            break;
        case ACTIONS.SET_ERROR_NOTICE:
            result = { ...result, ...action.payload };
            break;
        case ACTIONS.SET_VISIBLE:
            result.visible = action.visible;
            break;
        default:
            console.warn("no action.type", action);
            break;
    }
    return result;
}

const FindMyFit: React.FC<Props> = (props) => {
    const { t } = useTranslation("common");
    // 常规使用store里的函数
    const [setFrameInfo, frameInfo, haveFrameInfo] =
        findMyFit.useFindMyFitStore((state) => [
            state.setFrameInfo,
            state.frameInfo,
            state.haveFrameInfo,
        ]);
    // frame-info-dialog-to-show-or-hide
    const [frameInfoDialogVisible, setFrameInfoDialogVisible] = useState(false);

    const [questionIconDialogVisible, setQuestionIconDialogVisible] =
        useState(false);
    const [
        {
            lensVal,
            bridgeVal,
            templeVal,
            lensInputRed,
            bridgeInputRed,
            templeInputRed,
            errorNoticeVisible,
            isOutOfRange,
            step,
            confirmModalVisible,
            visible,
        },
        dispatch,
    ] = useReducer(reducer, initialState);

    function nextStep() {
        dispatch({ type: ACTIONS.SET_STEP, step: 2 });
        clickMyfitCanotFindSize();
    }

    function prePage() {
        dispatch({ type: ACTIONS.SET_STEP, step: 1 });
    }

    function onValChange(val: string, key: string) {
        dispatch({ type: ACTIONS.SET_VAL, payload: { key, val } });
    }

    // 处理弹窗
    const handleModal = useCallback((isOpen: boolean) => {
        setFrameInfoDialogVisible(isOpen);
    }, []);

    const onSubmit = useCallback(() => {
        const isNotInt = (arg: string) => !/^\d+$/.test(arg);
        // 检验参数是否正确 并且给出提示 前两项必须是整数，并且再范围内， 第三项在么不输入，在么和前两项规则一致
        const obj: any = {
            lensInputRed: false,
            bridgeInputRed: false,
            templeInputRed: false,
            errorNoticeVisible: false,
            isOutOfRange: false,
        };
        // 如果参数没有输入显示红框。如果输入的不是数字显示错误文字提示。如果超出范围显示红框。输入小数也红框
        if (!lensVal) {
            obj.lensInputRed = true;
        } else if (isNotInt(lensVal)) {
            obj.lensInputRed = true;
            obj.errorNoticeVisible = true;
        } else if (Number(lensVal) < 40 || Number(lensVal) > 80) {
            obj.lensInputRed = true;
            obj.isOutOfRange = true;
        }
        if (!bridgeVal) {
            obj.bridgeInputRed = true;
        } else if (isNotInt(bridgeVal)) {
            obj.bridgeInputRed = true;
            obj.errorNoticeVisible = true;
        } else if (Number(bridgeVal) < 9 || Number(bridgeVal) > 30) {
            obj.bridgeInputRed = true;
            obj.isOutOfRange = true;
        }
        if (templeVal) {
            if (isNotInt(templeVal)) {
                obj.templeInputRed = true;
                obj.errorNoticeVisible = true;
            } else if (Number(templeVal) < 115 || Number(templeVal) > 155) {
                obj.templeInputRed = true;
                obj.isOutOfRange = true;
            }
        }

        if (Object.keys(obj).reduce((pre, curr) => pre || obj[curr], false)) {
            // 错误
            dispatch({ type: ACTIONS.SET_ERROR_NOTICE, payload: obj });
            clickMyfitSubmitError();
        } else {
            // 没有错误
            dispatch({ type: ACTIONS.SET_ERROR_NOTICE, payload: obj });
            setFrameInfo([
                Number(lensVal),
                Number(bridgeVal),
                templeVal ? Number(templeVal) : "",
            ]);
            handleModal(false);
            clickMyfitSubmitSuccess([lensVal, bridgeVal, templeVal]);
        }
    }, [bridgeVal, handleModal, lensVal, setFrameInfo, templeVal]);

    // 点击？打开modal
    const handleNoticeDialog = useCallback((noticeDialogOpen: boolean) => {
        setQuestionIconDialogVisible(noticeDialogOpen);
    }, []);

    function cancelDel() {
        dispatch({ type: ACTIONS.SET_CONFIRM_MODAL_VISIBLE, visible: false });
    }

    function confirmDel() {
        dispatch({ type: ACTIONS.INIT, frameInfo: ["", "", ""] });
        dispatch({ type: ACTIONS.SET_CONFIRM_MODAL_VISIBLE, visible: false });
        setFrameInfo([]);
    }

    const onOpenModal = useCallback(() => {
        handleModal(true);
        clickMyfitEntry(props.position);
    }, [handleModal, props.position]);

    function handleClickEdit() {
        handleModal(true);
        clickMyfitEdit(props.position);
    }

    function handleClickDel() {
        dispatch({ type: ACTIONS.SET_CONFIRM_MODAL_VISIBLE, visible: true });
        clickMyfitDel(props.position);
    }

    useEffect(() => {
        if (frameInfo.length) {
            dispatch({ type: ACTIONS.INIT, frameInfo });
        }
    }, [frameInfo]); // 填写信息的modal

    // modal打开的时候阻止父亲的MouseLeave事件，modal关闭的时候恢复
    function doSomethingToAncestors(visible: boolean, callback: any) {
        if (visible) {
            props.helpIconHoverShowComponent?.setBlockMouseLeaveEvent(true);
            props.sliderDownSelect?.setBlockMouseLeaveEvent(true);
        } else {
            props.helpIconHoverShowComponent?.setBlockMouseLeaveEvent(
                false,
                callback,
            );
            props.sliderDownSelect?.setBlockMouseLeaveEvent(false, callback);
        }
    }

    // 当这个组件中的modal关闭后，关闭help-content和size-filter
    // TODO: 待斟酌，有必要用这种force-update吗？
    useUpdateEffect(() => {
        doSomethingToAncestors(
            questionIconDialogVisible || visible || confirmModalVisible,
            () => {
                setTimeout(() => {
                    props.helpIconHoverShowComponent?.onMouseLeave();
                    props.sliderDownSelect?.onMouseLeave();
                }, 1000);
            },
        );
    }, [questionIconDialogVisible, visible, confirmModalVisible]);

    const pageNext = useMemo(() => step === 2, [step]); // show next-page
    // dialog-title-reactNode
    // const DialogTitle = useMemo(() => {
    //     return (
    //         <>
    //             {/* step === 1 */}
    //             <p
    //                 className={classnames(stl["find-my-fit-title"], {
    //                     [stl["visible_hide"]]: pageNext,
    //                 })}
    //             >
    //                 {t("common:FindMyFit:FindMyFit")}
    //             </p>
    //             {/* pageNext */}
    //             <span
    //                 className={classnames(stl["left-arrow"], {
    //                     [stl.hide]: !pageNext,
    //                 })}
    //                 onClick={prePage}
    //             >
    //                 <FmIcon
    //                     icon="icon--arrow-left"
    //                     className={stl["find-my-fit-arrow-left"]}
    //                 />
    //             </span>
    //         </>
    //     );
    // }, [pageNext, t]);

    // 获取当前语种
    const currentLang = useMemo(() => {
        return Cookies.get("firmoo_lan");
    }, []);

    return (
        <>
            <style jsx>{`
                .fitbtn:active {
                    opacity: 0.5;
                }
                .notice2 .img-box .desc1 {
                    ${(() => {
                        switch (currentLang) {
                            case "es":
                                return "top: 89%; left: 8%;";
                            case "it":
                                return "top: 90%; left: 6%;";
                            case "de":
                                return "top: 89%; left: 8%;";
                            case "fr":
                                return "top: 89%; left: 9%;";
                            default:
                                return "top: 88%; left: 9%;";
                        }
                    })()}
                }

                .notice2 .img-box .desc3 {
                    ${(() => {
                        switch (currentLang) {
                            case "de":
                                return "top: -1%; left: 14%;";
                            default:
                                return "top: 1%; left: 15%;";
                        }
                    })()}
                }
            `}</style>

            {/* haveframeinfo */}
            <div
                className={classnames(
                    stl["myfit-info"],
                    "myfit-info",
                    props.className,
                    {
                        [stl.hide]: !haveFrameInfo,
                    },
                )}
            >
                <div className={stl.title}>
                    {t("common:FindMyFit:MyMeasurements")}
                    <FmIcon
                        onClick={() => handleNoticeDialog(true)}
                        icon="icon--FAQ"
                        className={stl.question}
                    />
                </div>
                <div className={stl["size-box"]}>
                    <span>{frameInfo.join("-")}</span>
                    <div className={stl["button-container"]}>
                        <FmButton
                            variant="text"
                            onClick={handleClickEdit}
                            className={stl["size-edit-btn"]}
                        >
                            {t("common:FindMyFit:EDIT")}
                        </FmButton>
                        <FmButton
                            variant="text"
                            onClick={handleClickDel}
                            className={stl["size-del-btn"]}
                        >
                            {t("common:FindMyFit:DELETE")}
                        </FmButton>
                    </div>
                </div>
            </div>
            {/* have-no-frameinfo */}
            <FmButton
                variant="outlined"
                width={"95%"}
                className={classnames(stl["find-myfit"], props.className, {
                    [stl.hide]: haveFrameInfo,
                })}
                onClick={onOpenModal}
                style={{
                    marginBottom: "0.24rem",
                }}
            >
                {t("common:FindMyFit:FindMyFit")}
            </FmButton>
            {/* 填写frame info的modal */}
            <FmDrawer
                onClose={() => handleModal(false)}
                open={frameInfoDialogVisible}
                className={classnames(
                    stl["find-my-fit-dialog"],
                    "find-my-fit-dialog",
                )}
                fullHeight
                closeable
                closeablePosition="right"
                headerChildren={
                    !pageNext ? (
                        t("common:FindMyFit:FindMyFit")
                    ) : (
                        <FmIcon
                            className={classnames(
                                stl["left-arrow"],
                                stl["find-my-fit-arrow-left"],
                                {
                                    [stl.hide]: !pageNext,
                                },
                            )}
                            onClick={prePage}
                            icon="icon--arrow-left"
                        />
                    )
                }
                anchor={"bottom"}
                headerClassName={stl["header"]}
                iconClassName={stl["close-icon"]}
                childrenClassName={classnames(stl.fm_dialog, "fm_dialog")}
            >
                <>
                    <div
                        className={classnames(
                            stl.notice_container,
                            "notice_container",
                            {
                                [classnames(stl.notice1, "notice1")]: !pageNext,
                                [classnames(stl.notice2, "notice2")]: pageNext,
                            },
                            `notice_${step}`,
                        )}
                    >
                        <div className={classnames(stl.notice, "notice")}>
                            <p
                                className={classnames(
                                    stl.notice_p,
                                    "notice_p",
                                    {
                                        [stl.hide]: pageNext,
                                    },
                                )}
                            >
                                {t("common:FindMyFit:FillFrameMeasurements")}
                            </p>
                            <p
                                className={classnames(
                                    stl.notice_p,
                                    "notice_p",
                                    {
                                        [stl.hide]: !pageNext,
                                    },
                                )}
                            >
                                {t("common:FindMyFit:MeasureManually")}
                            </p>
                        </div>
                        <p
                            className={classnames(stl["sub-notice"], {
                                [stl.hide]: pageNext,
                            })}
                        >
                            {t("common:FindMyFit:LocateNumbers")}
                        </p>
                        <div className={classnames(stl["img-box"], "img-box")}>
                            <FmImage
                                className={stl.img}
                                image={
                                    !pageNext
                                        ? "https://df5apg8r0m634.cloudfront.net/images/2022/0111/8b0b66f4e55c31c90f9c84015b9dd30a.png"
                                        : "https://df5apg8r0m634.cloudfront.net/images/2022/0209/e54bed61608c2fb83217d21723745d68.png"
                                }
                            />
                            <p
                                className={classnames(stl.desc, stl.desc1, {
                                    [stl.hide]: pageNext,
                                })}
                            >
                                {t("common:FindMyFit:LensWidth")}
                            </p>
                            <p
                                className={classnames(stl.desc, stl.desc1, {
                                    [stl.hide]: !pageNext,
                                })}
                            >
                                {t("common:FindMyFit:Lens")}
                            </p>
                            <p
                                className={classnames(stl.desc, stl.desc2, {
                                    [stl.hide]: pageNext,
                                })}
                            >
                                {t("common:FindMyFit:BridgeWidth")}
                            </p>
                            <p
                                className={classnames(stl.desc, stl.desc2, {
                                    [stl.hide]: !pageNext,
                                })}
                            >
                                {t("common:FindMyFit:Bridge")}
                            </p>
                            <p
                                className={classnames(stl.desc, stl.desc3, {
                                    [stl.hide]: pageNext,
                                })}
                            >
                                {t("common:FindMyFit:TempleLength")}
                            </p>

                            <p
                                className={classnames(stl.desc, stl.desc3, {
                                    [stl.hide]: !pageNext,
                                })}
                            >
                                {t("common:FindMyFit:Temple")}
                            </p>
                        </div>
                        <p
                            onClick={nextStep}
                            className={classnames(stl.guide, {
                                [stl.hide]: pageNext,
                            })}
                        >
                            {t("common:FindMyFit:CanNotFindMeasurements")}
                        </p>
                    </div>
                    <div className={stl["input-line"]}>
                        <div
                            className={classnames(stl["input-item"], {
                                [stl.red]: lensInputRed,
                            })}
                        >
                            <input
                                value={lensVal}
                                onChange={(e) =>
                                    onValChange(e.target.value, "lensVal")
                                }
                            />
                            <div className={stl.name}>
                                *{t("common:FindMyFit:LensWidth")}
                            </div>
                            <div className={stl["length-range"]}>
                                (40-80 mm)
                            </div>
                        </div>
                        <div
                            className={classnames(stl["input-item"], {
                                [stl.red]: bridgeInputRed,
                            })}
                        >
                            <input
                                value={bridgeVal}
                                onChange={(e) =>
                                    onValChange(e.target.value, "bridgeVal")
                                }
                            />
                            <div className={stl.name}>
                                *{t("common:FindMyFit:BridgeWidth")}
                            </div>
                            <div className={stl["length-range"]}>(9-30 mm)</div>
                        </div>
                        <div
                            className={classnames(stl["input-item"], {
                                [stl.red]: templeInputRed,
                            })}
                        >
                            <input
                                value={templeVal}
                                onChange={(e) =>
                                    onValChange(e.target.value, "templeVal")
                                }
                            />
                            <div className={stl.name}>
                                {t("common:FindMyFit:TempleLength")}
                            </div>
                            <div className={stl["length-range"]}>
                                (115-155 mm)
                            </div>
                        </div>
                    </div>
                    <div className={stl["submit-box"]}>
                        <div
                            className={classnames(stl["error-notice"], {
                                [stl.visible_hide]: !errorNoticeVisible,
                            })}
                        >
                            {t("common:FindMyFit:MustBeInt")}
                        </div>
                        <div
                            className={classnames(stl["out-of-range"], {
                                [stl.visible_hide]: !isOutOfRange,
                            })}
                        >
                            {t("common:FindMyFit:OutOfRange")}
                        </div>
                        <FmButton
                            width={"full"}
                            className={classnames(
                                stl["submit-btn"],
                                stl["fitbtn"],
                            )}
                            onClick={onSubmit}
                        >
                            {t("common:FindMyFit:Submit")}
                        </FmButton>
                    </div>
                </>
            </FmDrawer>
            {/* 点击？出现的modal */}
            <FmDrawer
                onClose={() => handleNoticeDialog(false)}
                open={questionIconDialogVisible}
                anchor="bottom"
                className={classnames("fm-dialog-container")}
                closeable
                childrenClassName={classnames(
                    stl["myfit-notice-modal-of-drawer"],
                    stl["myfit-notice-modal"],
                )}
                headerChildren
                closeablePosition="right"
            >
                <div className={stl["myfit-notice-box"]}>
                    <p className={stl.desc}>
                        {t("common:FindMyFit:FillMeasurements")}
                    </p>
                    <FmButton
                        onClick={() => handleNoticeDialog(false)}
                        className={classnames(stl.fitbtn, stl.ok, "ok")}
                        variant={"outlined"}
                        width={"3.8rem"}
                    >
                        {t("common:FindMyFit:OK")}
                    </FmButton>
                </div>
            </FmDrawer>
            {/* 点击删除出现的modal */}
            <FmDrawer
                anchor="bottom"
                onClose={cancelDel}
                open={confirmModalVisible}
                className={stl["myfit-notice-modal"]}
                closeable
                iconClassName={classnames(
                    stl.cross,
                    stl["icon-cross-class"],
                    "icon-cross-class",
                )}
                // paperClassName={classnames(stl.paper_container)}
                // titleClassName={classnames(
                //     stl["title-scross-no-trans"],
                //     "title-scross-no-trans",
                // )}
                headerChildren
                childrenClassName={classnames(
                    stl["myfit-notice-modal-of-drawer"],
                    stl["myfit-notice-modal"],
                )}
                closeablePosition="right"
            >
                <div
                    className={classnames(
                        stl["myfit-notice-box"],
                        stl.delete_content,
                    )}
                >
                    <p className={stl.desc}>
                        {t("common:FindMyFit:WillClose")}
                    </p>
                    <div className={stl.btns}>
                        <FmButton
                            onClick={confirmDel}
                            className={classnames(stl.fitbtn, stl.del)}
                            variant={"outlined"}
                            width={"2.6rem"}
                        >
                            {t("common:FindMyFit:DELETE")}
                        </FmButton>
                        <FmButton
                            onClick={cancelDel}
                            className={classnames(stl.fitbtn, stl.cancel)}
                            width={"2.6rem"}
                        >
                            {t("common:FindMyFit:CANCEL")}
                        </FmButton>
                    </div>
                </div>
            </FmDrawer>
        </>
    );
};

export default FindMyFit;
