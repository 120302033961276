import React, {
    forwardRef,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import stl from "../styles/extendsButton.module.scss";
import classnames from "classnames";
interface IProps
    extends React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
    > {}
const ExtendButton = forwardRef(function ExtendButton(
    props: React.PropsWithChildren<IProps>,
    ref,
) {
    const { style, className, ...rest } = props;
    const [touch, setTouch] = useState(false);

    const buttonContainerRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(
        ref,
        () => {
            return buttonContainerRef.current;
        },
        [],
    );
    return (
        <div
            className={classnames(stl["extend-button"], className)}
            onTouchStart={() => setTouch(true)}
            onTouchEnd={() => setTouch(false)}
            style={{
                opacity: touch ? 0.5 : 1,
                ...style,
            }}
            {...rest}
            ref={buttonContainerRef}
        >
            {props.children}
        </div>
    );
});

export default ExtendButton;
