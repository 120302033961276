import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { FilterConditionState, initialState } from "./initialState";
import {
    fCChange,
    filterActive,
    filterColor,
    filterFeature,
    filterGender,
    filterMaterial,
    filterRim,
    filterShape,
    filterSize,
    filterSizeSlider,
    filterAgeRange,
    handleReqCondition,
} from "@/store/modules/filterConditions/filterConditionSlices";
import { outputCondition } from "./selector";
import { cloneDeepWith } from "lodash";

export type FilterConditionStore = ReturnType<
    typeof createFilterConditionStore
>;

export const createFilterConditionStore = (
    initProps?: Partial<FilterConditionState>,
) => {
    // 将条件初始化
    let condition = {};
    if (initProps?.reqConditon) {
        condition = handleReqCondition(initProps.reqConditon);
    }
    // 注意Object.assign接收的第一个参数不为{}时，返回的不是一个新对象，需要深克隆
    const init = Object.assign(cloneDeepWith(initialState), {
        ...initProps,
        ...condition,
    });
    return create<FilterConditionState>()(
        devtools(
            (set, get) => ({
                ...init,
                activeFilterTitleStr: () => {
                    set(filterActive(get()));
                },
                colorSelect: (color: any) => {
                    set(filterColor(get(), color));
                    get().activeFilterTitleStr();
                },
                genderSelect: (id) => {
                    set(filterGender(get(), id));
                    get().activeFilterTitleStr();
                },
                ageRangeSelect: (id) => {
                    set(filterAgeRange(get(), id));
                    get().activeFilterTitleStr();
                },
                materialSelect: (id) => {
                    set(filterMaterial(get(), id));
                    get().activeFilterTitleStr();
                },
                shapeSelect: (id) => {
                    set(filterShape(get(), id));
                    get().activeFilterTitleStr();
                },
                featureSelect: (id) => {
                    set(filterFeature(get(), id));
                    get().activeFilterTitleStr();
                },
                sizeSelect: (id) => {
                    set(filterSize(get(), id));
                    get().activeFilterTitleStr();
                },
                sizeSliderSelect: (obj) => {
                    // @ts-ignore
                    set(filterSizeSlider(get(), obj));
                    get().activeFilterTitleStr();
                },
                frameSelect: (id) => {
                    set(filterRim(get(), id));
                    get().activeFilterTitleStr();
                },
                initFilterConditions: () => {
                    set(initialState);
                    get().activeFilterTitleStr();
                },
                changeFilterConditions: (conditions) => {
                    set(fCChange(get(), conditions));
                    get().activeFilterTitleStr();
                },
                // getConditionFromQuery: (reqCondition) => {
                //     set(fromQuery(get(), reqCondition));
                // },
                outputCondition: () => {
                    return outputCondition(get());
                },
                deleteSelectedOption: (item, type) => {
                    const {
                        colorSelect,
                        genderSelect,
                        ageRangeSelect,
                        materialSelect,
                        shapeSelect,
                        featureSelect,
                        sizeSelect,
                        frameSelect
                    } = get();
                    switch (type) {
                        case "color":
                            colorSelect(item);
                            break;
                        case "gender":
                            genderSelect(item.id);
                            break;
                        // case "ageRange":
                        //     ageRangeSelect(item.id);
                        //     break;
                        case "material":
                            materialSelect(item.id);
                            break;
                        case "shape":
                            shapeSelect(item.id);
                            break;
                        case "feature":
                            featureSelect(item.id);
                            break;
                        case "size":
                            sizeSelect(item.id);
                            break;
                        case "frame":
                            frameSelect(item.id);
                            break;
                        default:
                            break;
                    }
                },
            }),
            {
                name: "filter-condition-store",
            },
        ),
    );
};
