"use client";
import { useContext } from "react";
import { useStore } from "zustand";
import { FilterConditionContext } from "./Provider";
import { FilterConditionState } from "@/store/modules/filterConditions";

export function useFilterConditionContext<T>(
    selector: (state: FilterConditionState) => T,
): T {
    const store = useContext(FilterConditionContext);
    if (!store)
        throw new Error(
            "Missing PageFilterConditionContext.Provider in the tree",
        );
    return useStore(store, selector);
}

export { FilterConditionProvider } from "./Provider";
