// 作为favorite模块的store, 则在此处有一个模块该有的形式，如调用方法，和服务器有交互，不单独走model, 都走这里，形成一个交互市场
// !不要在除这里以外的地方调用和favorite相关的account, 被我发现我要买凶杀人！

import { ColorData } from "@/src/components/ColorSelect/GlassColor";
import { ViewItemProps } from "@/ui-component";

/**
 * 1. 调用处：
 * i) header: getTotal;
 * ii) favoriteList: getFavorite;
 * iii) add(收藏) / delete(取消收藏、删除收藏列表的产品) => 产品模块 + favoriteList的删除功能
 */
interface IPagination {
    total: number;
    currentPage: number;
    perPage: number;
}

interface IProductsInfo {
    color_id: number;
    color_image: string;
    color_name: string;
    color_number: string;
    color_qty: number;
    discount: boolean;
    discount_price: number | null;
    frame_image: string;
    frame_image_total: number;
    origin_price: string;
    tryon_image: string;
}
export interface IWishItem {
    // 这个才是改格式后，正式在前端使用的接口！！！不要问我为什么要改，我也不知道啊喂喂喂，应该是为了不要到处写属性没有就选谁这种操作吧。。。
    id: number;
    favoriteId: number;
    price: number;
    img: string;
    model: string | null;
    type: number;
    width: number;
    color_name: string;
    color_id: number;
}
export interface IWishes {
    // 接口请求过来的格式
    colors_id: number;
    dimensions_total_width: number;
    favorite_id: number;
    products_id: number;
    products_image: string;
    products_info: IProductsInfo;
    products_model: string;
    products_name_attribute: string | null;
    products_type: number;
}
export interface IAccountProps {
    wishes: ViewItemProps<IWishItem>[]; // favoritelist
    pagination: IPagination;
    errors: string;
    favoriteArr: number[]; // 存[colorsId]
    loading: boolean; // 在getFavorite时设置laoding状态
}

export type State = IAccountProps;

interface IAddFavorite {
    productId: number;
    colorsId: number;
    activeColor: Partial<Omit<ColorData, "price">> & { price: number };
    // host: string;
}
export type Action = {
    getFavorite: (page?: number, cb?: Function) => Promise<void>;
    addFavorite: (
        { productId, colorsId, activeColor }: IAddFavorite,
        cb?: Function,
    ) => Promise<void>; // 收藏产品
    deleteFavorite: (favorite_id: number) => Promise<void>;
    changeFavourite: (colorsId: number) => void;
    setPaginationTotal: (total: number) => void;
};
export type IAccount = State & Action;

export const initialState: State = {
    wishes: [],
    pagination: {
        total: 0,
        currentPage: 1,
        perPage: 10,
    },
    favoriteArr: [],
    errors: "",
    loading: false,
};
