import { useCallback, useEffect, useState } from "react";
import { getEnv } from "../utils/configUtils";

export const useFBLoading = () => {
    const [showFBloading, setShowFBloading] = useState(false);
    const [showReTry, setShowReTry] = useState(false);

    const onFBCheckLoad = useCallback(() => {
        if (window && window.FB) {
            return;
        }
        let timeNum = 20;
        setShowFBloading(true);
        setShowReTry(false);
        const timer = setInterval(() => {
            if (timeNum <= 0) {
                clearInterval(timer);
                setShowFBloading(false);
                setShowReTry(true);
                return;
            }
            if (window && window.FB) {
                setTimeout(() => {
                    setShowFBloading(false);
                    setShowReTry(false);
                }, 1000);
                clearInterval(timer);
            }
            timeNum--;
        }, 500);
    }, []);

    const onRetry = useCallback(() => {
        ((d, s, id) => {
            let js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s) as HTMLScriptElement;
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode?.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk2");

        // 初始化Facebook SDK
        window.fbAsyncInit = () => {
            window.FB.init({
                appId: getEnv("FB_LOGIN"),
                autoLogAppEvents: true,
                xfbml: true,
                version: "v10.0",
            });
        };
        onFBCheckLoad();
    }, [onFBCheckLoad]);

    useEffect(() => {
        onFBCheckLoad();
    }, [onFBCheckLoad]);

    return { showReTry, showFBloading, onRetry };
};
