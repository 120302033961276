import React, { useCallback, useEffect, useState } from "react";
import { FacebookProvider, LoginButton } from "react-facebook";
import { isIE } from "@/lib/commonService";
import classnames from "classnames";
import { getEnv } from "@/src/utils/configUtils";
import { useCommonContext } from "@/src/provider";
import { useTranslation } from "@/src/i18n/client";
interface IProps {
    onSuccess: (data: any) => void;
    onError: (error: Error) => void;
    scope: string;
}
const FacebookLoginBt: React.FC<IProps> = (props) => {
    const [currentIsIE, setIE] = useState(false);
    const { host } = useCommonContext();
    const { t } = useTranslation("common");

    const appId = getEnv("FB_LOGIN", host);
    const onSuccess = useCallback(
        (data: any) => {
            props.onSuccess(data);
        },
        [props],
    );
    const onError = useCallback(
        (data: any) => {
            props.onError(data);
        },
        [props],
    );

    useEffect(() => {
        setIE(isIE());
    }, [currentIsIE]);

    return (
        <div>
            <p
                className={classnames("default-font-color", {
                    ["hidden"]: !currentIsIE,
                })}
            >
                {t("common:IE_CAN_NOT_LOGIN_WITH_FACEBOOK")}
            </p>
            <FacebookProvider appId={appId}>
                <LoginButton
                    scope={props.scope}
                    //   class={classnames("bt", "b4A67A2")}
                    onSuccess={onSuccess}
                    onError={onError}
                >
                    <span>{t("common:share:facebook")}</span>
                </LoginButton>
            </FacebookProvider>
        </div>
    );
};

export default FacebookLoginBt;
