import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { ILoginStateAndAction, initialState } from "./initialState";
export const useLoginStore = create<ILoginStateAndAction>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        setLoginState() {
          set({ isLogin: true });
        },
        setLogoutState() {
          set({ isLogin: false });
        },
      }),
      {
        name: "login-storage",
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
    {
      name: "login-store",
    },
  ),
);
