"use client";
import React, { CSSProperties, useCallback, useMemo } from "react";

import Link from "next/link";
import { parse_url, conversion } from "../../utils";
import isObject from "@/src/utils/isObject";
import stl from "../../styles/link.module.scss";

import classnames from "classnames";
import { UrlObject } from "url";
import { useTheme } from "@mui/material/styles";
import { ILinkProps, INewLinkProps, RenamedIconProps } from ".";
interface ClientProps {
    // Define your props here
}

interface ICover {
    href: string;
    query: Record<string, any> | null;
}

const Client: React.FC<React.PropsWithChildren<ClientProps & INewLinkProps>> = (
    props,
) => {
    const theme = useTheme();
    const rest: Partial<ILinkProps> = {};
    // 至少隔离出带icon-前缀的属性
    const iconPre: Partial<RenamedIconProps> = {};

    for (let key in props) {
        if (/^icon/.test(key.toString())) {
            iconPre[key as keyof RenamedIconProps] =
                props[key as keyof INewLinkProps];
        } else {
            rest[key as keyof ILinkProps] = props[key as keyof INewLinkProps];
        }
    }
    const { href, as, children, onClick, color, onClickCapture, ...restProps } =
        rest; // 列举出icon的属性，然后隔离出来
    const cover = useMemo(() => {
        const coverObj: ICover = {
            href: "",
            query: null,
        };
        if (typeof href === "string") {
            coverObj.href = href.split("?")[0];
            coverObj.query = parse_url(href);
        } else if (isObject(href)) {
            const { pathname, query } = href as UrlObject; // 如果是对象pathname必然会存在吧
            coverObj.href = pathname!.split("?")[0];
            coverObj.query = { ...parse_url(pathname!), query }; // 不确定，为什么query不是对象？？？可能有问题这里
        }
        return coverObj;
    }, [href]);

    const conversionObj = useMemo(
        () =>
            conversion({
                href: cover.href,
                query: cover.query,
                as: as ?? href,
            }),
        [as, cover.href, cover.query, href],
    );

    const hrefObj = useMemo(() => {
        return {
            pathname: conversionObj.pathname,
            asPath: conversionObj.asPath,
            query: conversionObj.query,
        };
    }, [conversionObj.asPath, conversionObj.pathname, conversionObj.query]);
    const asPath = useMemo(() => {
        const temp = conversionObj.asPath;
        if (!temp) {
            return "#";
        }
        if (
            typeof temp === "string" &&
            !(temp.startsWith("/") || /https?:\/\//.test(temp)) &&
            temp.indexOf("#") === -1
        ) {
            return `/${temp}`;
        }
        return temp;
    }, [conversionObj.asPath]);

    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            if (onClickCapture) {
                onClickCapture?.(e);
                e.stopPropagation();
            }
        },
        [onClickCapture],
    );

    // Component logic goes here
    const styles = useMemo<CSSProperties>(() => {
        return {
            "--color": color ?? theme.palette.primary.main,
        } as CSSProperties;
    }, [color, theme.palette.primary]);
    const { className, ...linkRest } = restProps;

    return (
        <Link
            as={asPath}
            href={typeof props.href === "undefined" ? "#" : props.href}
            //nextjs 9 会自动 <Link>会自动在后台预取页面（显示在视图中）。
            //先取消后期可以修改路由，开启
            prefetch={false}
            dangerouslySetInnerHTML={props.dangerouslySetInnerHTML}
            onClick={onClick}
            onClickCapture={handleClick}
            className={classnames(
                stl["link-container"],
                "link-container",
                className,
            )}
            style={{ ...styles, ...props.style }}
            {...linkRest}
        >
            {props.children}
        </Link>
    );
};

export default Client;
