import phpFetch from "@/src/utils/fetch/PhpFetch";

import {
    SCHEME,
    SCHEME_LOGS,
    VISITOR,
    RECORD_VISITOR,
    AD_TOP,
} from "@/config/api";
import { isServer } from "@/lib/commonService";

export function postScheme(data: any) {
    return phpFetch.get(`${SCHEME}?${new URLSearchParams(data)}`);
}

// 获取visitorId
export const getVisitorId = (
    data: Record<string, any>,
    cookie?: Record<string, string>,
    hLang?: string,
    hSiteId?: number,
) => {
    const jwt = cookie ? cookie.jwt : "";
    const headers: any = {
        hLang,
        hSiteId,
    };
    if (isServer && jwt) headers["jwt-auth"] = jwt;
    const obj = isServer ? { cookie, ...data } : data.params;
    return phpFetch.get(VISITOR, obj, { headers });
};

export function getAdTop(data: any) {
    return phpFetch.get(AD_TOP, data);
}

export function postSchemeLog(data: any) {
    return phpFetch.post(SCHEME_LOGS, data);
}

////记录浏览者访问详情
export function recordVisitorDetail(data: any) {
    return phpFetch.post(RECORD_VISITOR, data);
}
