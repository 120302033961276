"use client";
import { useContext } from "react";
import { useStore } from "zustand";
import { PrescriptionContext } from "./Provider";
import { PrescriptionAction } from "@/store/modules/prescription";

export function usePrescriptionContext<T>(
    selector: (state: PrescriptionAction) => T,
): T {
    const store = useContext(PrescriptionContext);
    if (!store)
        throw new Error("Missing PrescriptionContext.Provider in the tree");
    return useStore(store, selector);
}

export { PrescriptionProvider } from "./Provider";
