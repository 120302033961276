import React, { useCallback, useMemo } from "react";
import LoginComponent from "./LoginComponent";
import { getSiteConfiguration } from "@/src/utils/configUtils";
import NoLogin from "./NoLogin";
import classnames from "classnames";
import { signInModal } from "@/store";
import { isObject } from "@/src/utils";
import { useCommonContext } from "@/src/provider";
import { useLoginStore } from "@/store/modules/loginState";

interface IUserNavProps {
    onClose: () => void;
    userInfo: Record<string, any>;
}

const UserNav: React.FC<IUserNavProps> = (props) => {
    const [isLogin] = useLoginStore((state) => [state.isLogin]);

    const [closeModal, openModal] = signInModal.useSignInModalStore((state) => [
        state.closeModal,
        state.openModal,
    ]);

    const { host } = useCommonContext();
    const { onClose } = useMemo(() => props, [props]);

    const loginCallback = useCallback(() => {
        closeModal();
        location.search ? (location.href = location.href) : location.reload();
    }, [closeModal]);

    const pageChange = useCallback(
        (url: string, _url?: Record<string, any>) => {
            if (!_url) {
                location.href = url;
            } else if (isObject(_url)) {
                let query = "";
                for (let item of Object.entries(_url)) {
                    query += query.length && "&"; // query有参数，则在其后面添加&
                    query += `${item[0]}=${item[1]}`;
                }
                location.href = query.length ? `${url}?${query}` : url;
            } else {
                location.href = `${url}?url=${_url}`;
            }

            onClose();
            closeModal();
        },
        [closeModal, onClose],
    );

    //新老永用户跳转不同链接
    const moneyHref = useCallback(() => {
        return "/account/referralProgram";
    }, []);

    const makeMoneyHref = useMemo(() => moneyHref(), [moneyHref]);

    const isShowFirmooAssets = useMemo(
        () => getSiteConfiguration("isShowFirmooAssets", host),
        [host],
    );

    return (
        <>
            <LoginComponent
                userInfo={props.userInfo}
                isShowFirmooAssets={isShowFirmooAssets}
                makeMoneyHref={makeMoneyHref}
                pageChange={pageChange}
                className={classnames({ ["hidden"]: !isLogin })}
            />
            <NoLogin
                userInfo={props.userInfo}
                openModal={openModal}
                loginCallback={loginCallback}
                handleClose={onClose}
                isShowFirmooAssets={isShowFirmooAssets}
                makeMoneyHref={makeMoneyHref}
                pageChange={pageChange}
                className={classnames({ ["hidden"]: isLogin })}
            />
        </>
    );
};

export default UserNav;
