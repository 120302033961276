export interface ILoginProps {
  isLogin: boolean;
}

export type State = ILoginProps;

export type ILoginStateAndAction = State & Action;

export type Action = {
  setLoginState: () => void;
  setLogoutState: () => void;
};

export const initialState: State = {
  isLogin: false,
};
