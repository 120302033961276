"use client";
import React, {
    CSSProperties,
    memo,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import FmIcon, { IIconProps } from "./FmIcon";
import { ExtractT2FrT1, ExcludeT1FrT2 } from "../interface";
import stl from "../styles/drawer.module.scss";
import classnames from "classnames";
import { Drawer, Box, DrawerProps } from "@mui/material";
import FmButton, { IButtonProps } from "./FmButton";
import $ from "jquery";
import { debounce } from "lodash";

export type Anchor = "top" | "left" | "bottom" | "right";
interface IFmDrawerProps {
    headerChildren?: ReactNode;
    closeable?: boolean;
    headerClassName?: string;
    fullHeight?: boolean;
    closeablePosition?: "left" | "right"; // 关闭按钮的位置
    boxClassName?: string;
    boxStyle?: CSSProperties;
    childrenClassName?: string;
    footer?: ReactNode;
    horizontal?: boolean;
    footerClassName?: string;
}
// swipable + fmDrawer得到swipable_drawerProps
// 1. swipeable添加前缀
type AddSwipeableProps = ExtractT2FrT1<
    DrawerProps,
    IFmDrawerProps,
    "swipeable"
>;
// 3. 拿到其他name不重名的属性
type ExtractSwipeableProps = ExcludeT1FrT2<DrawerProps, IFmDrawerProps>;
type ExtractSwipeableDrawerProps = ExcludeT1FrT2<IFmDrawerProps, DrawerProps>;

// 得到一份暂时性结果
type SwipeableAndDrawerProps = AddSwipeableProps &
    ExtractSwipeableProps &
    ExtractSwipeableDrawerProps;

// 以谁为基准，提出共用的，加上前缀，再合并

// 2. icon添加前缀
type AddIconProps = ExtractT2FrT1<IIconProps, SwipeableAndDrawerProps, "icon">;

// 3. 拿到其他name不重名的属性
type AnotherProps = ExcludeT1FrT2<IIconProps, SwipeableAndDrawerProps>;

// 得到结果
export type IDrawerProps = AddIconProps &
    AnotherProps &
    SwipeableAndDrawerProps;

const defaultProps = {
    anchor: "left",
    closeablePosition: "left",
    horizontal: false,
};

// 弹窗overflow控制滚动
export const drawerClassName = "drawer-is-opening";
export const dialogClassName = "dialog-is-opening";
const FmDrawer: React.FC<IDrawerProps> = memo(function FmDrawer(prop) {
    const { onClose, horizontal, footerClassName, ...props } = useMemo(() => {
        return { ...defaultProps, ...prop };
    }, [prop]);

    // 设置closeable按钮位置为right
    const closeableIconPosition = useMemo(() => {
        return props.closeablePosition === "right";
    }, [props.closeablePosition]);

    const headerRef = useRef<HTMLDivElement>(null);
    const footerHeightRef = useRef<HTMLDivElement>(null);
    const boxHeightRef = useRef<HTMLDivElement>(null);
    const [childrenHeight, setChildrenHeight] = useState(0);
    const [boxHeight, setBoxHeight] = useState(0);
    const [headerHeightState, setHeaderHeightState] = useState(false);
    useEffect(() => {
        props.open ||
        document.getElementsByClassName(drawerClassName).length > 0 ||
        document.getElementsByClassName(dialogClassName).length > 0
            ? $("body").addClass("setOverflowHiddenImportantly") &&
              $("body").removeClass("setOverflowAutoImportantly")
            : $("body").addClass("setOverflowAutoImportantly") &&
              $("body").removeClass("setOverflowHiddenImportantly");
    }, [props.open]);

    const handleResize = useCallback(() => {
        setChildrenHeight(
            (headerRef?.current?.clientHeight ?? 0) +
                (footerHeightRef.current?.clientHeight ?? 0),
        );
        setBoxHeight(boxHeightRef.current?.getBoundingClientRect().height ?? 0);
    }, []);
    useEffect(() => {
        window.addEventListener("resize", debounce(handleResize));
        return () => {
            window.removeEventListener("resize", debounce(handleResize));
        };
    }, [handleResize]);

    useEffect(() => {
        setChildrenHeight(
            (headerRef?.current?.clientHeight ?? 0) +
                (footerHeightRef.current?.clientHeight ?? 0),
        );
        setBoxHeight(boxHeightRef.current?.getBoundingClientRect().height ?? 0);
    }, [headerHeightState, props.headerChildren, props.footer]);
    useEffect(() => {
        // 原本props.open的更新导致了内容是完全被清除和被设置，ref虽然更新却无法导致副作用改动，增加状态机配置重新获取ref值
        setHeaderHeightState(!!props.open);
    }, [props.open]);
    const boxSX = useMemo(() => {
        return {
            width:
                props.anchor === "top" || props.anchor === "bottom"
                    ? "auto"
                    : "100vw",
            height: props.fullHeight ? "100vh" : "auto",
        };
    }, [props.anchor, props.fullHeight]);

    return (
        <Drawer
            anchor={props.anchor as Anchor}
            open={props.open}
            onClose={onClose}
            style={props.style}
            classes={{
                root: classnames(
                    props.className,
                    props.classes?.root,
                    stl["drawer-root"],
                    {
                        [drawerClassName]: props.open,
                    },
                ),
                paper: classnames(
                    { [stl["paper-when-no-fullheight"]]: !props.fullHeight },
                    props.classes?.paper,
                ),
                ...props.classes,
            }}
            // disableEnforceFocus
        >
            <Box
                sx={boxSX}
                className={classnames(
                    stl["drawer-box-container"],
                    {
                        [stl["drawer-box-with-header"]]: props.headerChildren,
                        [stl["drawer-box-fullheight"]]:
                            props.fullHeight && props.headerChildren,
                        [stl["padding-drawer"]]:
                            !props.fullHeight && props.anchor === "bottom",
                    },
                    props.boxClassName,
                )}
                ref={boxHeightRef}
                style={{
                    ...({
                        "--box-container-height": `${boxHeight}px`,
                    } as CSSProperties),
                    ...props.boxStyle,
                }}
            >
                <div
                    className={classnames(
                        stl["drawer-icon"],
                        {
                            ["hidden"]: !(
                                props.closeable ??
                                props.headerChildren ??
                                false
                            ),
                            [stl["closeable_no-header-children"]]:
                                props.closeable && !props.headerChildren,
                            // closeableIconPosition, // 可关闭但没有headerchildren占据header部分 && 关闭按钮位置在右边
                            [classnames(
                                stl["closeable-position-right"],
                                "closeable-position-right",
                            )]: closeableIconPosition,
                        },
                        props.headerClassName,
                    )}
                    ref={headerRef}
                >
                    <FmIcon
                        icon={props.icon ?? "icon--close"}
                        onClick={(e) => onClose?.(e, "backdropClick")}
                        color="inherit"
                        className={classnames(
                            stl.icon_class,
                            {
                                [stl[
                                    "closeable-position-right-then-icon-order"
                                ]]: closeableIconPosition,
                                [classnames(
                                    stl["no-header-and-closeable-right"],
                                    "no-header-and-closeable-right",
                                )]:
                                    closeableIconPosition &&
                                    typeof props.headerChildren === "boolean",
                                ["hidden"]: !props.closeable,
                            },
                            props.iconClassName,
                        )}
                    />
                    {/* header以children的形式塞入 */}
                    {props.headerChildren}
                </div>
                <div
                    style={
                        {
                            "--header-footer-height": `${Math.ceil(childrenHeight) + 1}px`,
                        } as CSSProperties
                    }
                    className={classnames(
                        {
                            [stl["children-container"]]: props.headerChildren,
                            [stl["has-footer-children-container"]]:
                                props.footer,
                            [stl["full-height"]]: props.fullHeight,
                        },
                        props.childrenClassName,
                    )}
                >
                    {props.children}
                </div>
                {props.footer && (
                    <div
                        className={classnames(
                            stl["footer-container"],
                            {
                                [stl["vertical"]]: !horizontal,
                            },
                            footerClassName,
                        )}
                        ref={footerHeightRef}
                    >
                        {props.footer}
                    </div>
                )}
            </Box>
        </Drawer>
    );
});

export default FmDrawer;

interface IDrawerButtonProps {
    buttonProps: IButtonProps[];
}
const DrawerButton: React.FC<IDrawerButtonProps> = (props) => {
    return (
        <>
            <FmButton
                {...props.buttonProps[0]}
                variant="outlined"
                size="large"
                width="half"
                classes={{
                    ...props.buttonProps[0].classes,
                    outlined: classnames(
                        stl["footer-button"],
                        props.buttonProps[0].classes?.outlined,
                    ),
                }}
            />
            <FmButton
                {...props.buttonProps[1]}
                width="half"
                variant="contained"
                color="primary"
                size="large"
                classes={{
                    ...props.buttonProps[1].classes,
                    contained: classnames(
                        stl["footer-button"],
                        props.buttonProps[0].classes?.contained,
                    ),
                }}
            />
        </>
    );
};

export { DrawerButton };
