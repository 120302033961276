import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { ProductsProps, initialState } from "./initialState";
import { getSpecialSubProducts } from "@/src/models/specialSubject";
import { cloneDeepWith } from "lodash";
// 产品的数据store

export type ProductsStore = ReturnType<typeof createProductsStore>;

export const createProductsStore = (initProps?: Partial<ProductsProps>) => {
    const init = Object.assign(cloneDeepWith(initialState), initProps);
    return create<ProductsProps>()(
        devtools(
            (set, get) => ({
                ...init,
                setProductsSkuStatus: (is_sku: boolean) => {
                    set({ is_sku });
                },
                fetchProducts: (params, type) => {
                    if (type === "filter") {
                        set({
                            isShowSkeleton: true,
                            productData: initialState.productData,
                        });
                    } else {
                        set({ isShowSkeleton: false });
                    }
                    set({ isLoading: true, isClientFetched: true });

                    getSpecialSubProducts(params).then((res) => {
                        console.log("fetchProducts", type, res);
                        if (type === "filter") {
                            set({
                                productData: {
                                    data: res.data,
                                    data_ad: res.data_ad,
                                    pagination: res.pagination,
                                },
                                isLoading: false,
                                isShowSkeleton: false,
                            });
                        } else {
                            set({
                                productData: {
                                    pagination: res.pagination,
                                    data_ad: res.data_ad,
                                    data: get().productData.data.concat(
                                        res.data,
                                    ),
                                },
                                isLoading: false,
                            });
                        }
                    });
                },
                setInitProductsData: (data) => {
                    set({
                        productData: data,
                    });
                },
            }),
            {
                name: "products-store",
            },
        ),
    );
};
