"use client";
import React, { CSSProperties, useCallback, useMemo } from "react";
import stl from "./connectToHelp.module.scss";
import variable from "@/src/styles/variables.module.scss";
import { FmButton, FmIcon } from "@/ui-component";
import { useRouter } from "next/navigation";
//懒加载
import LazyLoad from "react-lazyload";
import { getSiteConfiguration } from "@/src/utils/configUtils";
import { gaLogisticsHelpClick } from "@/src/utils/gtagEvents";
import { openChat } from "@/lib/chatOperation";
import FindMyFit from "./findMyFit";
import { EIconType } from "@/public/static/interface";
import classnames from "classnames";
import { useCommonContext } from "@/src/provider/Common/context";
import { useTranslation } from "@/src/i18n/client";
import { isLogin } from "@/src/models/loginModel";
import { useSignInModalStore } from "@/store/modules/signInModals/createStore";

interface IConnectToHelpProps {
    style?: CSSProperties;
    titleDisabled?: boolean;
    helpCenterHide?: boolean;
    closeAll?: () => void;
    searchVal?: string;
}

const ConnectToHelp = (props: IConnectToHelpProps) => {
    const router = useRouter();
    const { host, site } = useCommonContext();
    const { t } = useTranslation("common");
    const openModal = useSignInModalStore((state) => state.openModal);
    const { closeAll } = useMemo(() => props, [props]);
    const handleChat = useCallback(() => {
        openChat(host);
    }, [host]);

    const call = useCallback(() => {
        window.location.href = `tel://${getSiteConfiguration("telCall", host)}`;
    }, [host]);

    const isShowHelpCenter = useMemo(() => {
        return (
            getSiteConfiguration("isShowHelpCenter", host) &&
            !props.helpCenterHide
        );
    }, [host, props.helpCenterHide]);
    const liListArr = useMemo(
        () => [
            {
                icon: "icon--helpcenter",
                description: t("common:footer:HelpCenter"),
                style: stl["list-icon-font"],
                handleClick: (): void => {
                    closeAll?.();
                    router.push("/helpCenter"); // TODO: 对应模块的链接原来是有ashref功能的，现在没有，确认链接走向
                },
                show: isShowHelpCenter, // default: true
            },
            {
                icon: "icon--FAQ",
                description: t("common:nav:FAQ"),
                style: stl["list-icon-font"], //{ fontSize: "0.44rem", color: variable.text_2 },
                handleClick: (): void => {
                    closeAll?.();
                    router.push("help");
                },
            },
            {
                icon: "icon--review",
                description: t("common:nav:LiveTime"),
                style: stl["list-icon-font"],
                handleClick: (): void => {
                    closeAll?.();
                    handleChat();
                },
                show: getSiteConfiguration("isShowChat", host),
            },
            {
                icon: "icon--email",
                description: getSiteConfiguration("email", host),
                style: stl["list-icon-font"],
            },
            {
                handleClick: call,
                icon: "icon--nav-tell",
                description: getSiteConfiguration("tel", host),
                style: stl["list-icon-font"],
                show:
                    getSiteConfiguration("isShowChat", host) &&
                    getSiteConfiguration("tel", host),
            },
            {
                show: site === "de",
                showAnotherChildren: true,
                key: "show-de",
                children: (
                    <>
                        <li key="show-children-1">
                            <p>
                                {site}
                                {getSiteConfiguration("tollFreeTime", host)}
                            </p>
                        </li>
                        <li key="show-children-2">
                            {/* TODO: 这句话应该从外面进来的 */}
                            <p>
                                *Im Moment haben wir nur englischer Kundendienst
                            </p>
                        </li>
                    </>
                ),
            },
        ],
        [t, isShowHelpCenter, host, call, site, closeAll, router, handleChat],
    );

    return (
        <LazyLoad
            height={200}
            once={true}
            offset={100}
            className={classnames(stl["lazy-load-container"])}
        >
            <div className={stl["home-need-help"]} style={props.style}>
                {!props.titleDisabled && <h1>{t("common:nav:NeedHelp")}</h1>}
                <FindMyFit position="topnav" className={stl["h5-classify"]} />
                <ul className={stl.connect_to_help_ul}>
                    {liListArr.map((list) => {
                        return list.showAnotherChildren ? (
                            <div
                                key={list.key}
                                className={classnames({
                                    ["hidden"]: !(list.show ?? true),
                                })}
                            >
                                {list.children}
                            </div>
                        ) : (
                            <li
                                id={list.icon}
                                key={list.icon}
                                className={classnames({
                                    ["hidden"]: !(list.show ?? true),
                                })}
                            >
                                {list.handleClick ? (
                                    <FmButton
                                        variant="text"
                                        className={classnames(
                                            stl.fm_button,
                                            "fm-button-item",
                                        )}
                                        width={"full"}
                                        onClick={list.handleClick}
                                    >
                                        <FmIcon
                                            className={classnames(
                                                stl["icon--left"],
                                                list.style,
                                            )}
                                            icon={list.icon as EIconType}
                                            // style={list.style}
                                        />
                                        <p>{list.description}</p>
                                    </FmButton>
                                ) : (
                                    <div className={stl.fm_button}>
                                        <FmIcon
                                            className={list.style}
                                            icon={list.icon as EIconType}
                                            // style={list.style}
                                        />
                                        <p>{list.description}</p>
                                    </div>
                                )}
                            </li>
                        );
                    })}

                    <li
                        style={{
                            marginBottom: "0.2rem",
                            justifyContent: "space-between",
                        }}
                    >
                        <FmButton
                            width="full"
                            variant="text"
                            className={classnames(
                                stl.fm_button,
                                stl.flex_container,
                            )}
                            onClick={() => {
                                props.closeAll?.();
                                //增加ga 统计
                                gaLogisticsHelpClick();
                                isLogin()
                                    ? router.push("/account/trackSearch")
                                    : openModal(() => {
                                          router.push("/account/trackSearch");
                                      });
                            }}
                            contentClassName={stl["track_search"]}
                        >
                            <div className={stl.flex_content}>
                                <FmIcon
                                    icon={"icon--track"}
                                    className={stl["list-icon-font"]}
                                />
                                <p>{t("common:nav:Trackorder")}</p>
                            </div>

                            <FmIcon
                                icon={"icon--search"}
                                className={stl["list-icon-font"]}
                                style={{
                                    color: variable.main,
                                }}
                            ></FmIcon>
                        </FmButton>
                    </li>
                </ul>
            </div>
        </LazyLoad>
    );
};

export default ConnectToHelp;
