import React, { useCallback, useMemo, useState } from "react";
import { sendPasswordForgotten } from "../../models/loginModel";
import { FmButton, FmInput, FmToast } from "@/ui-component";
import classnames from "classnames";
import stl from "./forgotPassword.module.scss";
import { IStyleProps } from "@/src/app/interface";
import { emailValidate } from "@/src/utils";
import { useTranslation } from "@/src/i18n/client";
interface IProps extends IStyleProps {
    returnLogin: () => void;
    changeState: (val: number) => void;
}
const ForgotPassword: React.FC<IProps> = (props) => {
    const { t } = useTranslation(["login"]);
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");

    const [error, setError] = useState("");
    const [toastVisible, setToastVisible] = useState(false);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setEmail(e.target.value);
    };

    const emailBlur = () => {
        const res = emailValidate(email);

        setEmailError(t(res.message));
    };

    const cleanError = useCallback(() => {
        setError("");
        setEmailError("");
    }, []);

    const send = useCallback(async () => {
        const res = emailValidate(email);

        if (res.error) {
            setError(res.error);
            setEmailError(t(res.message));
            return;
        }
        setToastVisible(true);
        try {
            const data = await sendPasswordForgotten({
                customers_email_address: email,
            });
            if (data.res.error) {
                setError(data.res.message);
                return;
            }
            props.changeState(2);
        } catch (e: any) {
            let str = e.toString().split(":");
            setError(str);
        } finally {
            setToastVisible(false);
        }
    }, [email, props, t]);

    const btnDisable = useMemo(
        () => email && !emailError && !error,
        [email, emailError, error],
    );
    return (
        <div
            className={classnames(
                stl["firmoo-forget-password"],
                props.className,
            )}
        >
            <div
                className={classnames(stl["firmoo-register-error"], {
                    ["hidden"]: !error,
                })}
            >
                {error}
            </div>
            <p className={stl["text-forgot"]}>
                {t("login:ForgotPasswordText")}
            </p>
            <FmInput
                className={stl["forgot-input"]}
                label={t("login:Email")}
                value={email}
                name={"Email"}
                type="email"
                onChange={handleChange}
                error={!!emailError}
                helperText={emailError}
                onBlur={emailBlur}
                onFocus={cleanError}
                placeholder={t("login:EmailText")}
            />
            <FmButton
                disabled={!btnDisable}
                className={stl["send-email-to-me"]}
                onClick={send}
                fullWidth
                size="large"
            >
                {t("login:SendBtn")}
            </FmButton>
            <FmButton
                onClick={props.returnLogin}
                variant={"text"}
                fullWidth
                className={stl["return-to-login"]}
                size="large"
            >
                {t("login:ReturnLogin")}
            </FmButton>
            <FmToast visible={toastVisible} type={"loading"} />
        </div>
    );
};

export default ForgotPassword;
