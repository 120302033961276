// 菜单栏抽屉-left
import React from "react";
import { FmDrawer, IDrawerProps } from "@/ui-component";
import stl from "../styles/appBar.module.scss";
import classnames from "classnames";
// import dynamic from "next/dynamic";
// import BarLeftMenuSkeleton from "@/src/components/Header/headerBarSkeleton/BarLeftMenu";
import BarMenuContent from "./BarMenuContent";
// const BarMenuContent = dynamic(() => import("./BarMenuContent"), {
//     loading: () => <BarLeftMenuSkeleton />,
// });
interface IBarMenuProps extends IDrawerProps {
    onClose: () => void;
}
const BarMenu: React.FC<IBarMenuProps> = (props) => {
    return (
        <FmDrawer
            anchor={props.anchor}
            open={props.open}
            onClose={props.onClose}
            closeable
            childrenClassName={classnames(
                stl["bar-menu-container"],
                "bar-menu-container",
            )}
            headerChildren
            fullHeight
        >
            <BarMenuContent onClose={props.onClose} />
        </FmDrawer>
    );
};

export default BarMenu;
