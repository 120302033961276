"use client";
import React, { useCallback, useState } from "react";
import SearchDrawer from "../../SearchDrawer";
import { useHeaderStore } from "@/store/pageModules/header";
import { ExtendButton } from "@/ui-component";

interface IProps {
    // Define your props here
}

const SearchIconClient: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    // search-drawer
    const [searchOpen, setSearchOpen] = useState(false);
    const closeOtherPanels = useHeaderStore((state) => state.closeOtherPanels);
    const handleSearch = useCallback(() => {
        closeOtherPanels(); // 关闭其他会影响的弹窗
        setSearchOpen(true);
    }, [closeOtherPanels]);
    // Component logic goes here

    return (
        <>
            <ExtendButton onClick={handleSearch}>{props.children}</ExtendButton>
            <SearchDrawer
                anchor={"left"}
                open={searchOpen}
                onClose={() => setSearchOpen(false)}
            />
        </>
    );
};

export default SearchIconClient;
