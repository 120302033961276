"use client";

import {
    Product,
    ProductsStore,
    createProductsStore,
} from "@/store/modules/products";
import { createContext, useRef } from "react";

export const ProductsContext = createContext<ProductsStore | null>(null);

interface ProductsProviderProps
    extends React.PropsWithChildren<Partial<Product>> {}

export function ProductsProvider({
    children,
    ...props
}: ProductsProviderProps) {
    const storeRef = useRef<ProductsStore>();
    if (!storeRef.current) {
        storeRef.current = createProductsStore(props);
    }
    return (
        <ProductsContext.Provider value={storeRef.current}>
            {children}
        </ProductsContext.Provider>
    );
}
