import React, { forwardRef, useMemo } from "react";
import ViewListClient, { ICommonProps, IProps } from "./ViewListClient";
import { ListItemProps } from "@mui/material";
import { IStyleProps } from "../../interface";
import { IChildren } from "@/ui-component/interface";

interface IIndexProps {
    total?: number; // 用于停止触发onEndReached方法，向下取整
}
type IViewListProps<T> = IProps<T> &
    Partial<ICommonProps> &
    IIndexProps &
    IChildren;
const FmViewList = forwardRef(function FmViewList<T>(
    prop: IViewListProps<T>,
    ref: any,
) {
    const {
        currentPage,
        listenFirstItem = false,
        pageSize = 10,
        ...props
    } = prop;

    const totalPage: number | null = useMemo(() => {
        // 向下取整: 获取page总页数
        return props.total ? Math.ceil(props.total / pageSize) : null;
    }, [pageSize, props.total]);

    // 是否可以滚动：页面尚有余量 => 是否还有余量用于触发onEndReached
    const canBeScroll = useMemo(() => {
        if (!totalPage) {
            // 不做判断
            return true;
        }
        return totalPage > currentPage;
    }, [totalPage, currentPage]);
    return (
        <ViewListClient
            {...props}
            ref={ref}
            canBeScroll={canBeScroll}
            currentPage={currentPage}
        >
            {props.children}
        </ViewListClient>
    );
});
export default FmViewList;
export type ViewItemProps<T> = ListItemProps & IStyleProps & T;
export { type IViewListProps };
