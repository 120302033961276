const distanceHelpImg =
    "https://df5apg8r0m634.cloudfront.net/images/68b99684af70edee2e7ceec1d80f592a.jpg"; //require('../static/images/lens/t_usefor_distance.jpg');
const nearHelpImg =
    "https://df5apg8r0m634.cloudfront.net/images/44483f418fc8b495b682128b11a4a3ca.jpg"; //require('../static/images/lens/t_usefor_reader.jpg');
const roundTop =
    "https://df5apg8r0m634.cloudfront.net/images/b14583432f694d863030ca7a35ed578a.png"; //require('../static/images/lens/t_usefor_bifocal_02.png');
const flatTop = "common:IMAGE.FlatTop";

const stantardImg = "common:IMAGE.StantardImg"; //require('../static/images/lens/t_usefor_progresive.jpg');
const advancedImg = "common:IMAGE.AdvancedImg"; //require('../static/images/lens/t_usefor_freeForm.jpg');

const range = [[700, 750], [10 - 15]];
import { choosedInt } from "./lensThickness";
const arr = choosedInt(range, 1);

export const usage_valus_arr = [-1, 0, 1, 2]; //-1表示，有处方但是没有选择用途

interface IChildren {
    name: string;
    title: string;
    tips?: string;
    keys: string;
    icon: string;
    optionId: number;
    value: number;
    subHead?: string;
    helps: {
        title: string;
        tips: string;
        img?: string;
        isTranImg?: boolean;
    };
}
export interface IUsages {
    name: string;
    title: string;
    key?: string;
    value?: number;
    topTip: string;
    tips: string;
    choosed?: number | string;
    personNum: string;
    children: IChildren[];
    recommend?: string;
    subHead?: string;
}
export const usages: IUsages[] = [
    {
        name: "singleVision",
        title: "SINGLE_VISION",
        topTip: "choose",
        tips: "SINGLE_VISION_TIPS",
        choosed: arr[0],
        personNum: "250k+",
        children: [
            {
                name: "distance",
                title: "DISTANCE",
                tips: "SINGLE_VISION_DISTANCE",
                keys: "id[2]",
                icon: "https://df5apg8r0m634.cloudfront.net/images/2021/0630/96f1887e3d9d0c8ca2661d21c5c3119c.png",
                optionId: 2,
                value: 1,
                helps: {
                    title: "DISTANCE",
                    tips: "HELP.USAGE.DISTANCE_TIPS",
                    img: distanceHelpImg,
                },
            },
            {
                name: "intermediate",
                title: "INTERMEDIATE",
                keys: "id[2]",
                icon: "https://df5apg8r0m634.cloudfront.net/images/2021/0730/f49856b6e8f5343d04e85398eb52d10a.png",
                optionId: 2,
                value: 3,
                tips: "SINGLE_VISION_INTERMEDIATE",
                helps: {
                    title: "INTERMEDIATE",
                    tips: "HELP.USAGE.INTERMEDIATE_TIPS",
                },
            },
            {
                name: "near",
                title: "NEAR",
                tips: "SINGLE_VISION_NEAR",
                keys: "id[2]",
                optionId: 2,
                value: 2,
                icon: "https://df5apg8r0m634.cloudfront.net/images/2021/0630/26d5f2b54966d7e36d39a010130caa74.png",
                helps: {
                    title: "NEAR",
                    tips: "HELP.USAGE.NEAR_TIPS",
                    img: nearHelpImg,
                },
            },
        ],
    },
    {
        name: "multifocal",
        key: "id[27]",
        value: 278,
        title: "Multifocal",
        topTip: "choose",
        recommend: "ADD_RECOMMEND_TIPS",
        personNum: "520k+",
        // subHead: "WITH_LINE",
        tips: "BIFOCAL_TIPS",
        choosed: arr[1],
        children: [
            {
                name: "standardPL",
                title: "StandardPL",
                subHead: "NO_LINE",
                keys: "id[28]",
                icon: "https://df5apg8r0m634.cloudfront.net/images/2021/0630/5cc6a9d6ee96b96262be82e6851c28d8.png",
                optionId: 28,
                value: 279,
                helps: {
                    title: "HELP.USAGE.STANTARD_PROGRESSIVE_TITLE",
                    tips: "HELP.USAGE.STANTARD_PROGRESSIVE_TIPS",
                    img: stantardImg,
                    isTranImg: true,
                },
            },
            {
                name: "advancedPL",
                title: "AdvancedPL",
                subHead: "NO_LINE",
                keys: "id[12]",
                icon: "https://df5apg8r0m634.cloudfront.net/images/2021/0630/722c98d67a150bb8f1ec6bf9a8188deb.png",
                optionId: 12,
                value: 703,
                helps: {
                    title: "HELP.USAGE.ADVANCED_PROGRESSIVE_TITLE",
                    tips: "HELP.USAGE.ADVANCED_PROGRESSIVE_TIPS",
                    img: advancedImg,
                    isTranImg: true,
                },
            },
            {
                name: "bifocal",
                title: "BIFOCAL",
                keys: "id[27]",
                optionId: 27,
                value: 278,
                icon: "https://df5apg8r0m634.cloudfront.net/images/2021/0630/9858e08479547bf8813bdf07f6f4cc8e.png",
                subHead: "WITH_LINE",
                helps: {
                    title: "HELP.USAGE.BIFOCAL_GLASSES",
                    tips: "HELP.USAGE.BIFOCAL_GLASSES_TIPS",
                    img: flatTop,
                    isTranImg: true,
                },
            },
        ],
    },
];
