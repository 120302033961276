"use client";
/**
 * 确认是否要触发onEndReached函数（一般是用于请求新dataSource) => 有数据 || 到滚动极限
 * 触发方式：
 *  滑动触底 => 最后一个id的标记出现
 *  设置onEndReachedThreshold，表示底部藏onEndReachedThreshold个item时触发
 */
// TODO: 列表到最后，如果是有数据，会一直更新，这很不好, 说到底都是列表一滑动到最后更新，就一直在最后，而不是在其后面追加
// TODO: 虚拟滚动实现
import { ListProps } from "@mui/material";
import React, {
    forwardRef,
    useImperativeHandle,
    useMemo,
    useRef,
    ReactNode,
    useCallback,
    useEffect,
    useState,
    useLayoutEffect,
} from "react";
import { IChildren, IStyleProps } from "../../interface";
import classnames from "classnames";
import stl from "../../styles/viewList.module.scss";
import ShowContent, { IShowContentProps } from "./ShowContent";
import FmButton, { IButtonProps } from "../FmButton";
import Footer from "./Footer";
import FmListItem from "../FmListItem";
import FmLoading from "../FmLoading";

export interface ILimitPage {
    limitPage?: number; // 自动获取到第几页
    onEndReachedNode: (props: IButtonProps) => ReactNode; // 触发下一页按钮
    loadingReachedNode: ReactNode;
    buttonProps?: IButtonProps;
}
export const defaultProps = {
    onEndReachedThreshold: 1,
    pageSize: 10,
    total: null,
    listenFirstItem: false,
    manual: {
        // limitPage: 2,
        onEndReachedNode: (props: IButtonProps) => (
            <FmButton variant="outlined" size={"large"} fullWidth {...props}>
                <div style={{ fontSize: "0.32rem" }}>Load More</div>
            </FmButton>
        ),
        loadingReachedNode: <FmLoading visible />,
    },
};
export interface IProps<T> extends IStyleProps, ListProps {
    pageSize?: number; // 每页显示条数
    onEndReached?: (page: number) => any; // 当所有的数据都已经渲染过，并且列表被滚动到距离最底部不足onEndReachedThreshold个item的距离时调用
    renderFooter?: ReactNode;
    onEndReachedThreshold?: number; // 调用请求数据之前的临界值，默认是1，即当单个item的高度 * 1不到被隐藏在下面时会触发onEndReached去请求数据或做其他处理
    noDataTips?: IShowContentProps<T>["noDataTips"];
    currentPage: number;
    listenFirstItem?: boolean; // 是否要监听第一个列表项: 使用场景，当第一个列表项消失了多少百分比以后，处理列表项的header操作等
    intersectionObserverAPI?: {
        callback: IntersectionObserverCallback;
        options?: IntersectionObserverInit;
        reObserver?: boolean;
        observerChange?: (observered: boolean) => void;
    }; // 当监听第一个列表项做一系列处理时，需要配置的监听函数对象
}

// 给内部继承，一定要有，除了limitPage
export interface IViewListProps extends ICommonProps {
    canBeScroll: boolean;
}
export interface ICommonProps {
    manual?: Partial<ILimitPage>; //手动触发获取下一页
    hasListItem?: boolean; // 是否有listItem
}
const options = {
    root: null, // 视窗
    threshold: 0.5,
};
// 传入一个组件模板，然后再传入对应数据，让viewList渲染对应数据模板？
const FmViewList = forwardRef(function FmViewList<T>(
    prop: IProps<T> & IViewListProps & IChildren,
    ref: any,
) {
    const {
        currentPage,
        onEndReached,
        intersectionObserverAPI,
        listenFirstItem,
        canBeScroll,
        ...props
    } = { ...defaultProps, ...prop };

    const [loading, setLoading] = useState(false);
    const viewRef = useRef<HTMLDivElement>(null);
    const listContentRef = useRef<HTMLUListElement>(null); // item-container-ref
    const currentPageRef = useRef(currentPage);
    const manual = useMemo(() => {
        return { ...defaultProps.manual, ...prop.manual };
    }, [prop.manual]);

    const handleReached = useCallback(() => {
        if (currentPageRef.current !== currentPage) {
            // 非正常递增，比如有filter,导致currentPage更新不及时
            onEndReached?.(currentPageRef.current + 1);
            currentPageRef.current = currentPageRef.current + 1;
        } else {
            // 正常递增
            onEndReached?.(currentPage + 1);
            currentPageRef.current = currentPage + 1;
        }
    }, [onEndReached, currentPage]);

    // 绑定列表项的滚动监听
    const scrollObserver = useCallback(
        (node: HTMLElement, currentPage: number) => {
            return new Promise((resolve) => {
                observerRef.current = new IntersectionObserver(
                    // 滚动判断是否可以触发函数：有余量
                    (
                        entries: IntersectionObserverEntry[],
                        observer: IntersectionObserver,
                    ): void => {
                        // 没有余量 || ("没有更多的数据" || "正在请求中")
                        if (
                            (manual.limitPage &&
                                currentPage >= manual.limitPage) ||
                            !canBeScroll ||
                            currentPageRef.current > currentPage
                        ) {
                            observer.unobserve(entries[0].target);
                            return resolve("");
                        }
                        if (entries[0].isIntersecting) {
                            setLoading(true);
                            handleReached();
                            observer.unobserve(entries[0].target);
                            resolve("");
                        }
                    },
                    options,
                );
                observerRef.current?.observe(node);
            });
        },
        [canBeScroll, handleReached, manual.limitPage],
    );

    useEffect(() => {
        if (currentPage === 1) {
            currentPageRef.current = 1;
        }
        if (currentPageRef.current === currentPage) {
            setLoading(false);
            // 当请求page更新以后，重新对lastChild进行监听
            if (listContentRef.current) {
                scrollObserver(
                    listContentRef.current.lastChild as HTMLElement,
                    currentPage,
                );
            }
        }
    }, [currentPage, scrollObserver, props.total]);

    useImperativeHandle(
        ref,
        () => {
            return viewRef.current;
        },
        [],
    );

    const handleClick = useCallback(() => {
        setLoading(true);
        handleReached();
    }, [handleReached]);

    const observerRef = useRef<IntersectionObserver>();

    // 列表项的第一项监听observer
    const firstItemObserver = useCallback(
        (node: HTMLElement) => {
            if (!(intersectionObserverAPI && listenFirstItem)) {
                return null;
            }
            new IntersectionObserver(
                intersectionObserverAPI.callback,
                intersectionObserverAPI.options,
            ).observe(node);
        },
        [intersectionObserverAPI, listenFirstItem],
    );

    // 暂存reObserver状态判断是否要重新绑定firstItem
    const [currentFirstObserver, setCurrentFirstObserver] = useState(
        !!intersectionObserverAPI?.reObserver,
    );

    // 监听reObserver状态，如果更新，则更新
    useEffect(() => {
        if (intersectionObserverAPI?.reObserver) {
            setCurrentFirstObserver(!!intersectionObserverAPI?.reObserver);
        }
    }, [intersectionObserverAPI?.reObserver]);
    // 当需要重新绑定时，就执行该函数
    useEffect(() => {
        if (currentFirstObserver && listContentRef.current) {
            firstItemObserver(listContentRef.current.firstChild as HTMLElement);
            setCurrentFirstObserver(false);
            intersectionObserverAPI?.observerChange?.(false);
        }
    }, [
        currentFirstObserver,
        firstItemObserver,
        intersectionObserverAPI,
        intersectionObserverAPI?.reObserver,
    ]);

    useLayoutEffect(() => {
        // 在初始时注册监听
        if (listContentRef.current) {
            scrollObserver(
                listContentRef.current.lastChild as HTMLElement,
                currentPage,
            );
            firstItemObserver(listContentRef.current.firstChild as HTMLElement);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            className={classnames(
                stl["view-list-wrapper"],
                "view-list-wrapper",
                props.className,
            )}
            ref={viewRef}
        >
            <ShowContent
                ref={listContentRef}
                noDataTips={props.noDataTips}
                hasListItem={props.hasListItem}
                total={props.total ?? 0}
            >
                {props.children}

                <FmListItem
                    style={{
                        width: "100% !important",
                        display: "block !important",
                    }}
                >
                    <Footer
                        manual={manual}
                        currentPage={currentPage}
                        renderFooter={props.renderFooter}
                        canBeScroll={canBeScroll}
                        onClick={handleClick}
                        loading={loading}
                    />
                </FmListItem>
            </ShowContent>
        </div>
    );
});

export default FmViewList;
