"use client";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { List } from "@mui/material";
import classnames from "classnames";
import stl from "../../styles/viewList.module.scss";

interface IProps {}
const ShowContentClient = forwardRef(function ShowContentClient(
    props: React.PropsWithChildren<IProps>,
    ref: React.Ref<unknown> | undefined,
) {
    const listContentRef = useRef<HTMLUListElement>(null);
    useImperativeHandle(
        ref,
        () => {
            return listContentRef.current;
        },
        [],
    );
    return (
        <List
            classes={{ root: classnames(stl["list-container"]) }}
            ref={listContentRef}
        >
            {props.children}
        </List>
    );
});

export default ShowContentClient;
