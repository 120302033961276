import HeightBar from "@/src/components/HeightBar";
import ShareComponent from "@/src/components/ShareComponent";
import ConnectToHelp from "@/src/components/menuComponent/ConnectToHelp";
import RateChangeAndLanguageChange from "@/src/components/menuComponent/RateChangeAndLanguageChange";
import NavList from "@/src/components/menuDrawer/NavList";
import React from "react";

export interface IBarMenuContentProps {
    onClose: () => void;
}
const BarMenuContent: React.FC<IBarMenuContentProps> = (props) => {
    // Your component logic goes here

    return (
        <>
            {/* 菜单栏选择产品以及对应专题页 */}
            <NavList closeModal={props.onClose} />
            {/* 信息展示啥的 */}
            <HeightBar />
            <ConnectToHelp
                titleDisabled
                closeAll={props.onClose} // 暂定为关闭当前弹窗
            />
            <HeightBar />
            {/* 货币切换 && 语言切换 */}
            <RateChangeAndLanguageChange />
            {/* 分享 */}
            <ShareComponent openShareWindow={false} />
        </>
    );
};

export default BarMenuContent;
