import { Theme } from "@/ui-component/basic/FmThemeProvider";

export const defaultHeaderContentHeight = 49; // 其实是50,1px容差
export interface IHeader {
    isShowUserContent: boolean;
    fixedHeader: boolean; // 是否header被固定住
    headerContentHeight: number | null; // header的高度
    starkSx: any;
    headerContainerHeight: number; // header + tips的高度
    anouncementHeaderHeight: number; // tips高度
}

// 设置z-index让drawer弹窗永远从toolbar下面出来和进去
export const isShowUserContentClass = {
    zIndex: (theme: Theme) => theme.zIndex.drawer + 1, // 只有userLogin才需要它(出来的弹窗在header下面)
};

export const zIndexAppBarInit = {
    zIndex: (theme: Theme) => theme.zIndex.drawer,
};
export type State = IHeader;

export type Action = {
    // setIsShowUserContent: (isShowUserContent: boolean) => void; // 设置isShowUserContent
    closeOtherPanels: () => void;
    setHeaderOptions: (
        options: Array<Partial<IHeader>> | Partial<IHeader>,
    ) => void; // 设置header相关的属性
};

export type StateAction = State & Action;

export const initialState: State = {
    isShowUserContent: false,
    fixedHeader: false,
    headerContentHeight: null,
    starkSx: zIndexAppBarInit,
    headerContainerHeight: 0,
    anouncementHeaderHeight: 0,
};
