import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { ListItem, ListItemProps } from "@mui/material";
import stl from "../styles/listItem.module.scss";
import classnames from "classnames";
export interface IProps extends ListItemProps {}
const FmListItem = forwardRef(function FmListItem(props: IProps, ref) {
    const listRef = useRef<HTMLLIElement>(null);
    useImperativeHandle(
        ref,
        () => {
            return listRef.current;
        },
        [],
    );
    return (
        <ListItem
            ref={listRef}
            {...props}
            className={classnames(stl["list-item"], props.className)}
        >
            {props.children}
        </ListItem>
    );
});

export default FmListItem;
