"use client";
// TODO: 这种一个列表多个弹窗的操作，应该控制成，不管这个组件多少次被调用，但是弹窗应该永远只有一个，是根据值和id进行绑定，而不是无限生成
import React, {
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { IStyleProps } from "../interface";
import FmButton from "./FmButton";
import FmDrawer, { IDrawerProps } from "./FmDrawer";
import classnames from "classnames";
import stl from "../styles/picker.module.scss";
import FmInput, { IInputProps } from "./FmInput";
import FmIcon from "./FmIcon";
import variable from "../styles/variable.module.scss";
import { WholePropsIncludeAddPreFixAndOtherProps } from "../interface";
import FmMenuItem from "./FmMenuItem";
import { MenuList, OutlinedInputClasses } from "@mui/material";
import $ from "jquery";

interface IInputDataSource {
    label: string | number;
    value: string | number;
    startAdornment?: ReactNode;
}

interface IPickerProps extends IStyleProps {
    pickerWrapper?: (props: IPickerProps) => ReactNode;
    onChange(open: boolean, value?: string | number): void;
    value: string | number;
    dataSource?: IInputDataSource[];
    renderData?(props: any): ReactNode;
    classes?: Partial<
        {
            input: string; // input
            icon: string;
            root: string; // 外层outline-input
        } & Omit<OutlinedInputClasses, "input" | "icon" | "root">
    >;
    inputClassName?: string;
    // children?: ReactNode; // 设置点击input的时候弹出的内容
    // activeClassName?: string;
}
type ExtendedProps = IDrawerProps & IHeaderProps & IInputProps;
// 给drawerProps的属性重复的部分添加前缀
type IProps = WholePropsIncludeAddPreFixAndOtherProps<
    ExtendedProps,
    IPickerProps,
    "drawer"
> &
    IPickerProps;

interface IHeaderProps {
    confirm: string;
    cancel: string;
    onCancel?: (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        isOpen: boolean,
    ) => any;
    onConfirm?: (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        isOpen: boolean,
    ) => any;
}
const HeaderChildren: React.FC<IHeaderProps> = (props) => {
    return (
        <div className={classnames(stl["header-children-for-picker"])}>
            <FmButton
                variant="text"
                onClick={(e) => props.onCancel?.(e, false)}
                className={classnames(
                    stl["header-button"],
                    stl["cancel-button"],
                )}
                buttonColor={variable.main}
            >
                {props.cancel}
            </FmButton>
            <FmButton
                variant="text"
                onClick={(e) => props.onConfirm?.(e, false)}
                className={classnames(
                    stl["header-button"],
                    stl["confirm-button"],
                )}
                buttonColor={variable.main}
            >
                {props.confirm}
            </FmButton>
        </div>
    );
};

const PickerWrapper = (
    props: Omit<IInputProps, "onChange"> & IPickerProps & IDrawerProps,
) => {
    const {
        onChange,
        open,
        value,
        classes,
        dataSource,
        inputClassName,
        ...rest
    } = props;
    const handleInput = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (props.disabled) {
                return;
            }
            e.stopPropagation();
            onChange?.(!open, value);
        },
        [onChange, open, props.disabled, value],
    );
    const showValue = useMemo(() => {
        return dataSource?.find(
            (item: any) => item.value.toString() === value.toString(),
        );
    }, [dataSource, value]);
    return (
        <FmInput
            disabled={props.disabled}
            onClick={handleInput}
            {...rest}
            value={showValue?.label || ""}
            // inputClasses={}
            classes={{
                root: classes?.root,
                input: classes?.input,
                adornedEnd: classes?.adornedEnd,
                // notchedOutline: classes?.notchedOutline,
            }}
            className={inputClassName}
            startAdornment={showValue?.startAdornment}
            endAdornment={
                <FmIcon
                    style={{
                        color: props.disabled
                            ? variable.disabled_color
                            : (props.borderColor ?? "#C5C9CD"),
                    }}
                    icon={"icon--arrow-down"}
                    className={classnames(
                        stl["add-right"], // 增加权重
                        stl["end-adrnment"],
                        {
                            [stl["opened-icon"]]: open,
                        },
                        classes?.icon,
                    )}
                />
            }
        />
    );
};

const defaultProps = {
    pickerWrapper: (props: any) => <PickerWrapper {...props} />,
};
const FmPicker: React.FC<IProps> = (prop) => {
    const { onChange, pickerWrapper, childrenClassName, ...props } = {
        ...defaultProps,
        ...prop,
    };
    const tempValueRef = useRef(props.value);
    const [value, setValue] = useState(tempValueRef.current);

    const [open, setOpen] = useState(props.open);
    useEffect(() => {
        setOpen(props.open);
        setValue(props.value);
        tempValueRef.current = props.value;
    }, [props.open, props.value]);

    const handleChange = useCallback(
        (open: boolean, value: string | number) => {
            onChange?.(open, value);
        },
        [onChange],
    );

    const handleConfirm = useCallback(
        (
            e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
            isOpen: boolean,
        ) => {
            setOpen(isOpen);
            e.stopPropagation();
            handleChange?.(isOpen, value);
            tempValueRef.current = value;
        },
        [handleChange, value],
    );
    const handleCancel = useCallback(
        (
            e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined,
            isOpen: boolean,
        ) => {
            setOpen(isOpen);
            e?.stopPropagation();
            handleChange?.(isOpen, tempValueRef.current);
        },
        [handleChange],
    );

    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            const tempValue = $(e.target).attr("value")!;
            setValue(tempValue);
            e.stopPropagation();
            e.preventDefault();
        },
        [],
    );
    return (
        <>
            {pickerWrapper({
                ...props,
                onChange: handleChange,
            })}
            {/* 判断传入children是一个规范children, 此则传入对应展示数据符合picker原定行为 */}
            {
                // (React.isValidElement(props.children) &&
                //     React.cloneElement(props.children, props)) ??
                <FmDrawer
                    open={open}
                    anchor={"bottom"}
                    className={classnames(
                        stl["drawer-container"],
                        "picker-drawer-container",
                        props.className,
                    )}
                    style={props.style}
                    childrenClassName={classnames(
                        stl["picker-children-container"],
                        childrenClassName,
                    )}
                    headerChildren={
                        <HeaderChildren
                            confirm={props.confirm!}
                            cancel={props.cancel!}
                            onConfirm={handleConfirm}
                            onCancel={handleCancel}
                        />
                    }
                    onClose={() => handleCancel(undefined, false)}
                    boxClassName={classnames(stl["picker-box-container"])}
                    headerClassName={stl["draw-header-container"]}
                >
                    <MenuList
                        className={stl["menu-list-container"]}
                        onClick={(e) => handleClick(e)}
                    >
                        {props.dataSource?.map((item) => {
                            const { startAdornment, ...restItem } = item;
                            return (
                                props.renderData?.({
                                    ...item,
                                    onClick: handleClick,
                                }) ?? (
                                    <FmMenuItem
                                        key={item.value}
                                        // value={item.value}
                                        className={classnames(
                                            stl["menu-item"],
                                            "menu-item",
                                            `value-${item.value}-${value}`,
                                            {
                                                [classnames(
                                                    stl["active-item"],
                                                    "active-item",
                                                )]:
                                                    value.toString() ===
                                                    item.value.toString(),
                                            },
                                            props.className,
                                        )}
                                        {...restItem}
                                    >
                                        {item.label}
                                    </FmMenuItem>
                                )
                            );
                        })}
                    </MenuList>
                </FmDrawer>
            }
        </>
    );
};

export default FmPicker;
