"use client";
import {
    PackageInfoStore,
    createPackageInfoStore,
    IInitProps,
} from "@/store/modules/packageInfo";
import { createContext, useRef } from "react";
export const PackageInfoContext = createContext<PackageInfoStore | null>(null);

interface PackageInfoProviderProps
    extends React.PropsWithChildren<IInitProps> {}

export function PackageInfoProvider({
    children,
    ...props
}: PackageInfoProviderProps) {
    const storeRef = useRef<PackageInfoStore>();
    if (!storeRef.current) {
        storeRef.current = createPackageInfoStore(props);
    }
    return (
        <PackageInfoContext.Provider value={storeRef.current}>
            {children}
        </PackageInfoContext.Provider>
    );
}
