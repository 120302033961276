"use client";
import { useContext } from "react";
import { useStore } from "zustand";
import { PageLayoutContext } from "./Provider";
import { PageLayoutAction } from "@/store/modules/pageLayout";

export function usePageLayoutContext<T>(
    selector: (state: PageLayoutAction) => T,
): T {
    const store = useContext(PageLayoutContext);
    if (!store)
        throw new Error("Missing PageLayoutContext.Provider in the tree");
    return useStore(store, selector);
}

export { PageLayoutProvider } from "./Provider";
