import React from "react";
import { getSiteConfiguration } from "@/src/utils/configUtils";
import { Text } from "../common";
import stl from "./sendSuccess.module.scss";
import { FmButton, FmIcon } from "@/ui-component";
import { IStyleProps } from "@/src/app/interface";
import classnames from "classnames";
import { useCommonContext } from "@/src/provider";
import { useTranslation } from "@/src/i18n/client";
interface IProps extends IStyleProps {
    onOK: () => void;
}
const SendSuccess: React.FC<IProps> = (props) => {
    const { host } = useCommonContext();
    const { t } = useTranslation("login");

    return (
        <div
            className={classnames(
                stl["firmoo-reset-password"],
                stl["i-have-any-means-but-let-btn-css-win"],
                props.className,
            )}
            style={props.style}
        >
            <div className={stl["firmoo-reset-password-title"]}>
                <div className={stl["firmoo-reset-password-suc"]}>
                    <FmIcon
                        icon={"icon--checked"}
                        className={stl["icon-checked-style"]}
                    />
                </div>
                <Text
                    text={t("login:SendSuc")}
                    type="main"
                    color="title"
                ></Text>
            </div>
            <p>
                <Text
                    text={t("login:SendSucText1")}
                    type="discrible"
                    color="title"
                ></Text>
            </p>
            <p>
                <Text
                    text={t("login:SendSucText2")}
                    type="discrible"
                    color="title"
                ></Text>{" "}
                <Text
                    text={getSiteConfiguration("email", host)}
                    type="discrible"
                    color="main"
                ></Text>
            </p>
            <FmButton
                className={classnames(
                    stl["ok-btn"],
                    stl["success-send-email-ensure"],
                )}
                size="medium"
                onClick={props.onOK}
            >
                {t("login:OKBtn")}
            </FmButton>
        </div>
    );
};

export default SendSuccess;
