// 移动端header-bar + 对应选项功能
// 菜单栏 + 菜单栏引用模块
"use client";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import stl from "./styles/appBar.module.scss";
import classnames from "classnames";
import {
    getLocalItem,
    includesOneOfArr,
    setLocalItem,
} from "@/lib/commonService";
import { MYFIT } from "@/config/constConf";
import { findMyFit, loginState, signInModal, account, order } from "@/store";
import { useUserInfoContext } from "@/src/provider/UserInfo/context";
import { headerStore } from "@/store/pageModules";
import {
    defaultHeaderContentHeight,
    zIndexAppBarInit,
} from "@/store/pageModules/header";
import { useCommonContext } from "@/src/provider";
import reddit from "../../LoadAnalysis/analysis/reddit";
import FmStack from "@/ui-component/basic/FmStack";
import { enforceZIndexUnderCurrentId } from "../../FilterNavBar/const";
import { getCountryInfo } from "@/src/models/common";
import { isLogin as checkLogin } from "@/src/models/loginModel";
import useOptimizedScroll from "@/src/hooks/useOptimizedScroll";
interface IHeaderContainerProps {
    ctlBubbleMark?: "Y" | "N";
    hiddenWhenNoFixed?: boolean;
}
const defaultProps = {
    ctlBubbleMark: "N",
};

const options = {
    root: null,
    threshold: 0,
};
const HeaderContainerClient: React.FC<
    React.PropsWithChildren<IHeaderContainerProps>
> = (prop) => {
    const props = { ...defaultProps, ...prop };
    const [
        isShowUserContent,
        starkSx,
        headerContentHeight,
        anouncementHeaderHeight,
        setHeaderOptions,
    ] = headerStore.useHeaderStore((state) => [
        state.isShowUserContent,
        state.starkSx,
        state.headerContentHeight,
        state.anouncementHeaderHeight,
        state.setHeaderOptions,
    ]); // user-login显示or隐藏
    const { host } = useCommonContext();
    const headerRef = useRef<HTMLDivElement>(null);
    const [getUserInfo, data] = useUserInfoContext((state) => [
        state.getUserInfo,
        state.data,
    ]);

    const [totalNum] = order.useOrderStore((state) => [state.totalNum]);

    const [setLoginState, setLogoutState] = loginState.useLoginStore(
        (state) => [state.setLoginState, state.setLogoutState],
    );

    const [visible] = signInModal.useSignInModalStore((state) => [
        state.visible,
    ]);

    const [setFrameInfo] = findMyFit.useFindMyFitStore((state) => [
        state.setFrameInfo,
    ]);
    // 滚动方向: wheel > 0 ？ 向上 ： 向下
    const lastScrollTop = useRef(0);
    const ctrBubbleByBackend = useCallback(() => {
        if (props.ctlBubbleMark === "Y") {
            // setBubbleIcon(true);
        } else if (props.ctlBubbleMark === "N") {
            // setBubbleIcon(false);
        }
    }, [props.ctlBubbleMark]);

    //判断是否加气泡和livechat气泡
    const showBubbleAndChat = useCallback(
        (url: string) => {
            const asPath = url;
            let notShowChat = includesOneOfArr(asPath, [
                "main_page=login",
                "/login",
            ]);
            const liveChatDom: HTMLDivElement | null = document.querySelector(
                "#chat-widget-container",
            );
            const reveChatDom: HTMLDivElement | null = document.querySelector(
                "#reve-chat-container-div",
            );
            if (location.href.includes("checkout")) {
                notShowChat = true;
            }
            if (liveChatDom) {
                if (notShowChat) {
                    liveChatDom.style.display = "none";
                } else {
                    liveChatDom.style.display = "block";
                }
            }
            if (reveChatDom) {
                if (notShowChat) {
                    reveChatDom.style.display = "none";
                } else {
                    reveChatDom.style.display = "block";
                }
            }

            // 是否由后台控制
            props.ctlBubbleMark && ctrBubbleByBackend();
        },
        [ctrBubbleByBackend, props.ctlBubbleMark],
    );

    const getCountryInformation = useCallback(async () => {
        try {
            const res = await getCountryInfo();
            let country = "";
            let region = "";
            if (res?.geo?.country) {
                country = res.geo.country;
                region = res.geo.region;
                // setUserCountry(res.geo.country);
            }
            //Reddit 初始化需要判断加州，应对CCPA法案，详情见https://reddit.my.site.com/helpcenter/s/article/Limited-Data-Use
            reddit.init({ country, region, domain: host });
        } catch (error) {
            reddit.init({ domain: host });
        }
    }, [host]);

    const readMyFitInfoFromStorage = useCallback(() => {
        const myfit = getLocalItem(MYFIT);
        if (myfit) {
            setFrameInfo(myfit);
        }
    }, [setFrameInfo]);
    const initHeaderInDomRef = useRef(false);
    // 当滚动高度超过顶部导航栏距离顶部高度时=>fixed
    const handleScroll = useCallback(() => {
        if (headerRef.current) {
            // 1.判断headerContent是否存在，以确保bottom是否要减去headerContent的高度； 2.bottom处理掉header高度以后 > 0 ? noFixed : fixed
            const scrollTop = document.documentElement.scrollTop;

            const isDown = scrollTop > lastScrollTop.current;
            // 有隐藏header: 正向和反向时，header分别为0和1，需要区分处理；无隐藏header: 整体高度 < header高度（要实时获取boundingRect, 否则拿到的数据不是正确数据）
            const setTrue = !!(props.hiddenWhenNoFixed
                ? (isDown &&
                      headerRef.current.getBoundingClientRect().bottom < 0) ||
                  (!isDown &&
                      headerRef.current.getBoundingClientRect().bottom <
                          (headerContentHeight ?? defaultHeaderContentHeight))
                : headerRef.current.getBoundingClientRect().bottom <
                  (headerContentHeight ?? defaultHeaderContentHeight));
            setHeaderOptions([{ fixedHeader: setTrue }]);
            lastScrollTop.current = scrollTop <= 0 ? 0 : scrollTop;
        }
    }, [headerContentHeight, props.hiddenWhenNoFixed, setHeaderOptions]);

    useEffect(() => {
        // 首次渲染，scrollY的位置决定header是否存在
        if (props.hiddenWhenNoFixed) {
            setHeaderOptions([
                {
                    fixedHeader: window.scrollY > anouncementHeaderHeight,
                },
            ]);
            initHeaderInDomRef.current = true;
        } else {
            setHeaderOptions([
                {
                    fixedHeader: window.scrollY > anouncementHeaderHeight,
                },
            ]);
        }
    }, [anouncementHeaderHeight, props.hiddenWhenNoFixed, setHeaderOptions]);

    useOptimizedScroll({ onScroll: handleScroll });
    useEffect(() => {
        (async () => {
            await getUserInfo();
        })();
    }, [getUserInfo, totalNum]);
    // 首次渲染不需要依赖
    useEffect(() => {
        (async () => {
            const isLogin = checkLogin(); // 登录态

            if (!isLogin) {
                setLogoutState();
            } else {
                setLoginState();
            }
            if (isLogin) {
                //已登录
                if (data.is_old_customer) {
                    //根据 customers_id 获取schema接口
                    const is_old_customer = !!data.is_old_customer;
                    //记录老用户
                    const local_older = getLocalItem("is_old_customer");
                    if (
                        (!local_older ||
                            local_older == "undefined" ||
                            local_older == "false") &&
                        is_old_customer
                    ) {
                        setLocalItem(
                            "is_old_customer",
                            (!!data.is_old_customer).toString(),
                        );
                    }
                } else {
                    getUserInfo();
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    useEffect(() => {
        showBubbleAndChat(location.href);
        readMyFitInfoFromStorage();
        getCountryInformation();
    }, []);
    useEffect(() => {
        // 初始化设置headerContainerHeight
        const headerRefHeight = headerRef.current?.clientHeight;

        setHeaderOptions([{ headerContainerHeight: headerRefHeight }]);
        // 收起弹窗的动效是在225毫秒的范围内结束的，所以这里的时间处理为225
        if (!isShowUserContent) {
            setTimeout(
                () => setHeaderOptions({ starkSx: zIndexAppBarInit }),
                225,
            );
        }
    }, [isShowUserContent, setHeaderOptions]);

    const stackAppendingClass = useMemo(() => {
        return {
            flexGrow: 1,
            ...starkSx,
        };
    }, [starkSx]);

    return (
        <FmStack
            spacing={2}
            className={classnames(stl["app-bar-stack-wrapper"], {
                ["z-index-under-drawer"]: !(isShowUserContent || visible),
            })}
            id={enforceZIndexUnderCurrentId}
            ref={headerRef}
            sx={stackAppendingClass}
        >
            {prop.children}
        </FmStack>
    );
};

export default HeaderContainerClient;
