import React, { useCallback, useEffect, useMemo, useState } from "react";
import Login from "./LoginContent";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import {
    ISignInModalStateAndAction,
    MODALPAGE,
} from "@/store/modules/signInModals";
import { loginState, signInModal } from "@/store";
import { FmDrawer, FmTabs } from "@/ui-component";
import stl from "./loginModal.module.scss";
import classnames from "classnames";
import SendSuccess from "./SendSuccess";
import { Text } from "@/src/components/common";
import FBLogin from "./FBLogin";
import { useTranslation } from "@/src/i18n/client";
import { useUserInfoContext } from "@/src/provider/UserInfo/context";

interface IProps {
    open: boolean;
    onClose: ISignInModalStateAndAction["closeModal"];
}

const DynamicLoginModal: React.FC<IProps> = (props) => {
    const { t } = useTranslation(["common", "login"]);
    const [changePage, loginCallback, modalPage] =
        signInModal.useSignInModalStore((state) => [
            state.changePage,
            state.loginCallback,
            state.modalPage,
        ]);

    const setLoginState = loginState.useLoginStore(
        (state) => state.setLoginState,
    );

    const getUserInfo = useUserInfoContext((state) => state.getUserInfo);
    const [activeTab, setActiveTab] = useState(modalPage);

    useEffect(() => {
        setActiveTab(modalPage); // modalPage更新而导致显示tab更新
    }, [modalPage]);
    const [currentState, setCurrentState] = useState(0); //0:sign;1:forget;2:sendSuc
    const forgetPass = useCallback(() => {
        setCurrentState(1);
    }, []);
    const hideAndSetLogin = useCallback(async () => {
        try {
            props.onClose();
            setLoginState();
            await getUserInfo();
        } catch (e) {
            throw e;
        }
    }, [getUserInfo, props, setLoginState]);

    const loginFBDom = useMemo(() => {
        return (
            <div className={stl["login-or-facebook"]}>
                <div className={stl["firmoo-login-or"]}>
                    <div className={stl["firmoo-login-or-line"]}></div>
                    <Text
                        text={t("common:OR")}
                        type="discrible"
                        color="sub"
                    ></Text>
                    <div className={stl["firmoo-login-or-line"]}></div>
                </div>
                <FBLogin
                    loginCallback={loginCallback}
                    hideAndSetLogin={hideAndSetLogin}
                    className={stl["facebook-login"]}
                />
            </div>
        );
    }, [hideAndSetLogin, loginCallback, t]);
    const tabs = useMemo(
        () => [
            {
                label: t("common:LOGIN"),
                value: MODALPAGE.LOGINPANEL,
                className: classnames(stl["tab-item"], "tab-item"),
                classes: {
                    selected: stl["active-tab-item"],
                },
                children: (
                    <Login
                        forgetPass={forgetPass}
                        loginCallback={loginCallback}
                        hideAndSetLogin={hideAndSetLogin}
                    >
                        {loginFBDom}
                    </Login>
                ),
            },
            {
                label: t("login:Create"),
                value: MODALPAGE.REGISTERPANAL,
                className: classnames(stl["tab-item"], "tab-item"),
                classes: {
                    selected: stl["active-tab-item"],
                },
                children: (
                    <Register
                        loginCallback={loginCallback}
                        hideAndSetLogin={hideAndSetLogin}
                        forgetPass={forgetPass}
                    >
                        {loginFBDom}
                    </Register>
                ),
            },
        ],
        [forgetPass, hideAndSetLogin, loginCallback, loginFBDom, t],
    );

    const handleChange = useCallback((val: number) => {
        setActiveTab(val);
    }, []);

    const returnLogin = useCallback(() => {
        changePage(0);
        setCurrentState(0);
    }, [changePage]);

    const changeState = useCallback((val: number) => {
        setCurrentState(val);
    }, []);

    const forgetPasswordStep = useMemo(() => {
        return currentState === 1;
    }, [currentState]);

    const handleClose = useCallback(() => {
        props.onClose();
        changePage(0);
        setCurrentState(0);
    }, [changePage, props]);
    return (
        <FmDrawer
            open={props.open}
            onClose={handleClose}
            closeable={true}
            anchor={"bottom"}
            fullHeight
            className={stl["login-modal-drawer-wrapper"]}
            headerClassName={classnames({
                [stl["closeable-class"]]: !forgetPasswordStep,
            })}
            childrenClassName={classnames({
                [stl["login-modal-children"]]: !forgetPasswordStep,
            })}
            headerChildren={
                forgetPasswordStep ? t("login:ForgetPassword") : false
            }
            closeablePosition={"right"}
        >
            <div
                className={classnames(
                    stl["login-modal-container"],
                    "login-modal-container",
                    { [stl["forget-password-container"]]: forgetPasswordStep },
                )}
            >
                <div className={classnames({ ["hidden"]: currentState !== 0 })}>
                    <FmTabs
                        className={classnames(
                            stl["root-tabs-container"],
                            "root-tabs-container",
                        )}
                        tabsClassName={stl["root-tabs-content"]}
                        tabs={tabs}
                        value={activeTab}
                        onChange={(e, v) => handleChange(v)}
                        centered
                        tabPanelClassName={classnames(
                            "swipeableViewsClassName",
                            stl["tab-children-container"],
                        )}
                    />
                </div>
                <ForgotPassword
                    className={classnames({ ["hidden"]: !forgetPasswordStep })}
                    returnLogin={returnLogin}
                    changeState={changeState}
                />
                <SendSuccess
                    className={classnames({ ["hidden"]: currentState !== 2 })}
                    onOK={handleClose}
                ></SendSuccess>
            </div>
        </FmDrawer>
    );
};

export default DynamicLoginModal;
