import { getRandom } from "@/lib/commonService";

export const uk_hide_lens = [275, 1083, 607, 606];

export function choosedInt(range: number[][], lastIndex: number) {
    let arr = range.map((item) => getRandom(item[0], item[1], true));
    let last = 10000 - arr.reduce((value, item) => value + item);
    arr.splice(lastIndex, 0, last);
    return arr;
}
