/**
 *
 * ES 评论接口改造
 *
 *
 */

import { getCookieItem } from "@/lib/commonService";

import { VISITOR_COOKIE_NAME } from "@/config/constConf";

import { PRODUCT_COMMENT_API, COMMET_PREVIEW_API } from "@/config/api";
import {
    HandleJavaOpencvFetch,
    HandleJavaReviewFetch,
    HandlePhpFetch,
} from "@/src/utils/fetch";
import { getEnv } from "@/src/utils/configUtils";

function getVistorId(): string {
    let cookieData: Record<string, any> | null;
    try {
        const cookiestr = getCookieItem(VISITOR_COOKIE_NAME);
        cookieData = cookiestr ? JSON.parse(cookiestr) : null;
        return cookieData?.visitor_id;
    } catch (e) {
        return "";
    }
}

export const getProductComment = (
    id: number,
    data: Record<string, any>,
    visitorId?: string,
    headers?: Record<string, any>,
) => {
    const visitor_id = visitorId ?? getVistorId();
    const type = 1;
    if (!getEnv("JAVA_REVIEW")) {
        console.log("=========================");
        console.log("ES API不存在");
        console.log("评论获取老接口");
        console.log("=========================");
        return HandleJavaOpencvFetch.get(
            PRODUCT_COMMENT_API + id,
            { visitor_id, type, ...data },
            headers,
        );
    }

    try {
        return HandleJavaReviewFetch.get(
            "/front/comment/all/" + id,
            { visitor_id, type, ...data },
            headers,
        );
    } catch (e) {
        console.log("=========================");
        console.log("ES 接口报错：", e);
        console.log("评论获取老接口");
        console.log("=========================");

        return HandleJavaOpencvFetch.get(PRODUCT_COMMENT_API + id, {
            visitor_id,
            type,
            ...data,
        });
    }
};

export const getProductPreviewCommentOrQuestion = (
    id: number,
    visitorId?: string,
    cookie?: string,
    headers?: Record<string, any>,
) => {
    const visitor_id = visitorId ?? getVistorId();
    if (
        !(typeof window !== "undefined"
            ? getEnv("JAVA_REVIEW")
            : process.env.JAVA_REVIEW_URL)
    ) {
        console.log("=========================");
        console.log("ES API不存在");
        console.log("评论获取老接口");
        console.log("=========================");
        return HandlePhpFetch.get(
            COMMET_PREVIEW_API + id,
            {
                visitor_id,
                cookie,
            },
            headers,
        );
    }

    try {
        return HandleJavaReviewFetch.get(
            "front/comment/" + id,
            { visitor_id, cookie },
            { headers },
        );
    } catch (error) {
        console.log("=========================");
        console.log("ES 接口报错：", error);
        console.log("评论获取老接口");
        console.log("=========================");

        return HandleJavaOpencvFetch.get(
            COMMET_PREVIEW_API + id,
            {
                visitor_id,
                cookie,
            },
            headers,
        );
    }
};
