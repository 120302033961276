"use client";
import { useContext } from "react";
import { ToastContext, ToastContextType } from "./Provider";

export const useToastContext = (): ToastContextType => {
    const context = useContext(ToastContext);
    if (context === undefined) {
        throw new Error("useToast must be used within a ToastProvider");
    }
    return context;
};

export { ToastProvider } from "./Provider";
