import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import {
  ISignInModalStateAndAction,
  initialState,
  MODALPAGE,
} from "./initialState";

export const useSignInModalStore = create<ISignInModalStateAndAction>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        openModal(loginCallback, modalPage = MODALPAGE.LOGINPANEL) {
          set({ modalPage, visible: true, loginCallback });
        },

        closeModal() {
          set({ modalPage: MODALPAGE.LOGINPANEL, visible: false });
        },

        changePage(modalPage: number) {
          set({ modalPage });
        },
      }),
      {
        name: "sign-in-modal-storage",
        // storage: createJSONStorage(() => sessionStorage),
      },
    ),
    {
      name: "sign-in-modal-store",
    },
  ),
);
