import { getEnv } from "../utils/configUtils";

export const fallbackLng = getEnv("LANGUAGE");
export const defaultNs = "common";
export const NAME_SPACES = [
    "account",
    "basket",
    "checkout",
    "common",
    "lens",
    "login",
    "notice",
    "productDetail",
    "refund",
    "review",
    "rx",
    "sortByFace",
    "specialSub",
    "unsub",
    "ViewMore",
    "ztPage",
] as const;
type Namespaces = typeof NAME_SPACES;
type NamespacesAtom = Namespaces[number];
export type Namespace = NamespacesAtom | NamespacesAtom[] | Namespaces;
const runsOnServerSide = typeof self === "undefined";

export function getOptions(lng = fallbackLng, ns: Namespace = defaultNs) {
    return {
        // debug: true,
        // supportedLngs: ["en", "fr"],
        fallbackLng,
        lng,
        // fallbackNS: ns,
        ns: runsOnServerSide ? NAME_SPACES : ns,
        preload: runsOnServerSide ? [fallbackLng] : [],
    };
}
