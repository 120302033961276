const UPLOAD_URL = "/upload";
import { BUILD_WEBP_URL, USER_PHOTO_UPLOAD } from "@/config/api";
import { handleErrors } from "@/src/utils/fetch/handleErrors";
import HandlePhpFetch from "@/src/utils/fetch/PhpFetch";

export type SuccessfulResponse<T> = {
    data: T;
    error?: never;
    statusCode?: number;
};
export type UnsuccessfulResponse<E> = {
    data?: never;
    error: E;
    statusCode?: number;
};

export type ApiResponse<T, E = unknown> =
    | SuccessfulResponse<T>
    | UnsuccessfulResponse<E>;

export const uploadImg = async (params: Record<string, any>) => {
    const appendData: Record<string, any> = {
        formats: "webp",
        encode: "true",
        ...params,
    };
    const formData = new FormData();
    for (let key in appendData) {
        formData.append(key, appendData[key]);
    }
    try {
        const response = await fetch(`/api/upload${UPLOAD_URL}`, {
            method: "POST",
            body: formData,
            cache: "no-store",
        });
        return handleErrors(response);
    } catch (e) {
        console.error("Error:", e);
        throw e;
    }
};

//发送post请求，生产webp
export const buildWebp = (params: any) => {
    return HandlePhpFetch.post(BUILD_WEBP_URL, params);
};

// tryOn模块上传图片

/**
 * 上传图片到服务器。获得图片的id、path、瞳孔坐标
 * @param fileName
 * @param fileType
 * @param file
 * @param photoType
 * @param customersPd
 * @param photoCustomerType
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postPhotoJava = async (
    fileName: any,
    file: any,
    photoType = "1",
    customersPd = "63",
    photoCustomerType: any,
) => {
    let formData = new FormData();
    formData.append("key", fileName);
    formData.append("acl", "public-read");
    formData.append("Content-Type", "image/jpeg");
    formData.append("Cache-Control", "max-age=31536000");
    formData.append("file", file);
    formData.append("path", fileName);
    formData.append("formats", "webp");
    formData.append("encode", "true");
    formData.append("photoType", photoType);
    formData.append("customersPd", customersPd);
    formData.append("photoCustomerType", photoCustomerType);
    try {
        const response = await fetch(`/api/opencvUpload${USER_PHOTO_UPLOAD}`, {
            method: "POST",
            body: formData,
            cache: "no-store",
        });
        if (response.status === 200) {
            return response.json();
        }
        return handleErrors(response);
    } catch (e) {
        console.error("Error:", e);
        throw e;
    }
};
