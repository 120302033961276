"use client";
import {
    IUserInfoProps,
    UserInfoStore,
    createUserInfoStore,
} from "@/store/modules/userInfo";
import { createContext, useRef } from "react";
export const UserInfoContext = createContext<UserInfoStore | null>(null);

interface UserInfoProviderProps
    extends React.PropsWithChildren<Partial<IUserInfoProps>> {}

export function UserInfoProvider({
    children,
    ...props
}: UserInfoProviderProps) {
    const storeRef = useRef<UserInfoStore>();
    if (!storeRef.current) {
        storeRef.current = createUserInfoStore(props);
    }
    return (
        <UserInfoContext.Provider value={storeRef.current}>
            {children}
        </UserInfoContext.Provider>
    );
}
