import React, { useCallback, useMemo } from "react";
import { jointImg } from "@/lib/commonService";
import { getWebpUrl, getProductDetialHref } from "@/src/utils";
import { IStyleProps } from "@/src/app/interface";
import stl from "./wishItem.module.scss";
import classnames from "classnames";
import { FmIcon, FmImage, FmLink } from "@/ui-component";
import GlassColor from "../../ColorSelect/GlassColor";
import { useRateContext } from "@/src/provider/Rate/context";
import { IWishItem } from "@/store/modules/account";
import { useTranslation } from "@/src/i18n/client";
import { defaultImg } from "@/config/constConf";

//商品链接
function productHref(data: Record<string, any>) {
    const color_id = data?.products_info?.color_id || "";
    return `${getProductDetialHref(data.products_id)}${
        color_id ? `?color=${color_id}` : ""
    }`;
}
interface IProps extends IStyleProps {
    deleteWish: (favoriteId: number) => void;
    data: IWishItem;
}
const WishItem: React.FC<IProps> = (props) => {
    const { t } = useTranslation("account");
    //产品颜色
    //产品颜色
    const { data, deleteWish } = props;
    const imgSrc = useMemo(() => {
        return data.img && data.id
            ? jointImg({
                  productId: data.id,
                  size: "small",
                  imgTotal: 2,
                  name: data.img,
              })
            : "";
    }, [data.id, data.img]);

    const [getPrice] = useRateContext((state) => [state.getPrice]);

    const handleDeleteWish = useCallback(
        (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
            e.stopPropagation();
            deleteWish(data.favoriteId);
        },
        [data.favoriteId, deleteWish],
    );
    return (
        <div className={stl["wish-item-box"]}>
            <div
                className={classnames(
                    stl["wish-item-close-btn"],
                    "wish-item-close-icon",
                )}
            >
                <FmIcon
                    onClick={(e) => handleDeleteWish(e)}
                    icon="icon--close"
                    className={stl["delete-icon"]}
                />
            </div>
            <FmLink href={productHref(data)} className={stl["wish-item-img"]}>
                <FmImage image={getWebpUrl(imgSrc)} defaultImage={defaultImg} />
            </FmLink>
            <div className={stl["desc"]}>
                {/* 商品名 */}
                {data.model && data.price && (
                    <span className={stl["fill"]}>{data.model}</span>
                )}
                {/* 商品颜色 */}
                <GlassColor
                    color_name={data.color_name}
                    color_id={data.color_id}
                />
                {/* 商品价格 || sold out */}
                <p
                    className={classnames(
                        stl["wish-item-name"],
                        stl["text-center"],
                        {
                            [stl["wish-item-price"]]: data.price,
                            [stl["slod_out"]]: !data.price,
                        },
                    )}
                    dangerouslySetInnerHTML={{
                        __html: data.price
                            ? getPrice(data.price, undefined)
                            : t("account:Favorite:SoldOut"),
                    }}
                />
            </div>
        </div>
    );
};

export default WishItem;
