"use client";
import { ExtendButton, FmBadge } from "@/ui-component";
import classnames from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import stl from "./styles/appBar.module.scss";
import variable from "@/src/styles/variables.module.scss";
import { headerStore } from "@/store/pageModules";
import { account, loginState } from "@/store";
import WishShoppingCart from "../../menuComponent/WishShoppingCart";

interface IProps {}
const FavoriteDrawerClient: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const [wishFavoriteOpen, setWishFavoriteOpen] = useState(false);
    const closeOtherPanels = headerStore.useHeaderStore(
        (state) => state.closeOtherPanels,
    );
    const handleFavoritePanelShowOrNot = useCallback(
        (favoritePanelStatus: boolean) => {
            if (favoritePanelStatus) {
                closeOtherPanels();
            }

            setWishFavoriteOpen(favoritePanelStatus);
        },
        [closeOtherPanels],
    );
    const isLogin = loginState.useLoginStore((state) => state.isLogin);
    const favoriteTotal = account.useAccountStore(
        (state) => state.pagination.total,
    );
    const badgeContent = useMemo(() => {
        if (isLogin && favoriteTotal !== 0) {
            return favoriteTotal > 99 ? 99 : favoriteTotal;
        }

        return null;
    }, [isLogin, favoriteTotal]);
    return (
        <>
            <ExtendButton>
                <FmBadge
                    badgeContent={badgeContent}
                    className={classnames(stl["bar-right-icon"])}
                    classes={{
                        badge: classnames(stl["badge-class"], stl.badge),
                    }}
                    color={variable.favoriteColor}
                    onClick={() => handleFavoritePanelShowOrNot(true)}
                >
                    {props.children}
                </FmBadge>
            </ExtendButton>
            <WishShoppingCart
                open={wishFavoriteOpen}
                onClose={() => handleFavoritePanelShowOrNot(false)}
            />
        </>
    );
};

export default FavoriteDrawerClient;
