"use client";
import React, {
    forwardRef,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from "react";
import { Icon, IconProps } from "@mui/material";
import stl from "../styles/icon.module.scss";
import classnames from "classnames";
import { EIconType } from "@/public/static/interface";
import { IStyleProps } from "../interface";

export interface IIconProps extends IconProps, IStyleProps {
    icon: EIconType;
}
const FmIcon = forwardRef(function FmIcon(props: IIconProps, ref) {
    const { classes, style, ...rest } = props;
    const [touch, setTouch] = useState(false);
    const iconRef = useRef<HTMLSpanElement>(null);
    useImperativeHandle(
        ref,
        () => {
            return iconRef.current;
        },
        [],
    );
    const classesRes = useMemo(() => {
        return {
            root: classnames(
                "iconfont",
                stl["cursor-icon"],
                classes?.root,
                props.className,
            ),
            [`color${props.color}`]: classnames(),
            [`fontSize${props.fontSize}`]: classnames(),
        };
    }, [classes?.root, props.className, props.color, props.fontSize]);
    return (
        <Icon
            {...rest}
            ref={iconRef}
            classes={classesRes}
            className={classnames(props.icon, props.className)}
            color={props.color ?? "inherit"}
            onTouchStart={() => setTouch(true)}
            onTouchEnd={() => setTouch(false)}
            style={{
                ...style,
                opacity: touch ? 0.5 : 1,
            }}
        />
    );
});

export default FmIcon;
