"use client";
import { IStyleProps } from "@/src/app/interface";
import { gaTopAdClick } from "@/src/utils/gtagEvents";
import React from "react";

interface IProps extends IStyleProps {}
// 添加gaTopAdClick的方法
const AddGaTopAdClickClient: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    // Your component logic goes here

    return (
        <div onClick={gaTopAdClick} {...props}>
            {props.children}
        </div>
    );
};

export default AddGaTopAdClickClient;
