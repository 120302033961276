import React, { useCallback, useEffect, useMemo, useState } from "react";
import Text from "../common/Text";
import { FmIcon, FmButton, FmCheckbox, ICheckboxProps } from "@/ui-component";
import stl from "./shareWithCustomers.module.scss";
import classnames from "classnames";
import { useTranslation } from "@/src/i18n/client";
interface IProps<T> {
    onChange: ICheckboxProps<T>["onChange"];
    subscription: number[];
}
const Share: React.FC<IProps<string | number>> = (props) => {
    const [isAngle, setIsAngle] = useState(false);
    const { t } = useTranslation("login");
    const [checkedValue, setCheckedValue] = useState(props.subscription);
    useEffect(() => {
        setCheckedValue(props.subscription);
    }, [props.subscription]);
    const toggleShow = useCallback(() => {
        setIsAngle(!isAngle);
    }, [isAngle]);

    const radioList = useMemo(() => {
        return [4, 2, 3, 5, 1, 9, 7, 6].map((val) => {
            return {
                label: t(`login:share:${val}`),
                value: val,
                formControlLabelClassName: stl["label-item"],
            };
        });
    }, [t]);

    return (
        <div className={stl["share-container"]}>
            <FmButton
                onClick={toggleShow}
                variant={"text"}
                fullWidth
                className={stl["button-container-wrapper"]}
            >
                <div className={stl["button-container"]}>
                    <Text
                        text={t("login:shareTitle")}
                        type={"discrible"}
                        color={"title"}
                    />
                    <FmIcon
                        icon={"icon--arrow-down"}
                        className={classnames(stl["icon-arrow-down"], {
                            [stl["margin-size"]]: isAngle,
                        })}
                        style={{
                            transform: `rotate(${!isAngle ? 0 : 180}deg)`,
                        }}
                    />
                </div>
            </FmButton>
            <div
                className={classnames(stl["firmoo-register-share"], {
                    ["hidden"]: !isAngle,
                })}
            >
                <FmCheckbox<string | number>
                    dataSource={radioList}
                    values={checkedValue}
                    onChange={props.onChange}
                />
            </div>
        </div>
    );
};

export default Share;
