"use client";
import React, {
    useCallback,
    useDebugValue,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import Image, { ImageProps } from "next/image";
import { IStyleProps } from "../interface";
import classnames from "classnames";
import stl from "../styles/image.module.scss";
import getWebpUrl from "../utils/getWebpUrl";
import {
    StaticImageData,
    StaticRequire,
} from "next/dist/shared/lib/get-img-props";
const defaultImg =
    "https://df5apg8r0m634.cloudfront.net/images/2024/0710/52fd185aa6af2160e3d393614712400c.jpg";

// Image默认属性

interface IFmClasses {
    lazy?: {
        root?: string;
    };
}
export interface IProps extends IStyleProps, Omit<ImageProps, "src" | "alt"> {
    image?: ImageProps["src"];
    defaultImage?: ImageProps["src"];
    alt?: ImageProps["alt"];
    onClick?: () => void;
    lazyLoadingAnimation?: boolean; // 是否添加缓动动效
    fmClasses?: IFmClasses;
}
const keyStr =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
const triplet = (e1: number, e2: number, e3: number) =>
    keyStr.charAt(e1 >> 2) +
    keyStr.charAt(((e1 & 3) << 4) | (e2 >> 4)) +
    keyStr.charAt(((e2 & 15) << 2) | (e3 >> 6)) +
    keyStr.charAt(e3 & 63);
const rgbDataURL = (r: number, g: number, b: number) =>
    `data:image/gif;base64,R0lGODlhAQABAPAA${
        triplet(0, r, g) + triplet(b, 255, 255)
    }/yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==`;
// 找出默认属性中同时存在于被继承者的属性，并添加前缀
// type AddImageProps = ExtractT2FrT1<IProps, ILinkProps, "image">;
// type AnotherProps = ExcludeT1FrT2<IProps, ILinkProps>;
// type IImageProps = AddImageProps & AnotherProps & Partial<ILinkProps>;
// 1.找出继承的props在被继承者props里重复的属性，并为其添加前缀

export function isStaticRequire(obj: any): obj is StaticRequire {
    return (
        typeof obj === "object" &&
        obj !== null &&
        typeof obj.default === "object"
    );
}
export function isStaticImageData(obj: any): obj is StaticImageData {
    return typeof obj === "object" && obj !== null;
}
export function getSrcWhenImgHaveSomeOtherTypes(
    image: string | undefined | StaticRequire | StaticImageData,
) {
    if (isStaticRequire(image)) {
        return image.default.src;
    } else if (isStaticImageData(image)) {
        return image.src;
    }
    return image;
}
const FmImage: React.FC<IProps> = (props) => {
    // TODO：剖离props时要注意剖离出对应的属性，不要乱剖
    const {
        image,
        className,
        defaultImage,
        onClick,
        onLoad,
        onError,
        loading,
        unoptimized = true,
        priority = false, // 图片优先加载
        lazyLoadingAnimation = false,
        fmClasses,
        ...rest
    } = props;
    const [useWebp, setUseWebp] = useState(true);
    const [showImage, setShowImage] = useState(image || defaultImage);
    const [isFirstRender, setIsFirstRender] = useState(true);
    const imgIsRenderedRef = useRef<any[]>([]);
    // 原图后缀支持格式存储
    const getType = useMemo(() => {
        return getSrcWhenImgHaveSomeOtherTypes(image || defaultImage);
    }, [defaultImage, image]);
    const imageTypeRef = useRef(getType?.split(/\.(\w+)$/)[1]);
    useEffect(() => {
        const imgItem = getWebpUrl(getType);
        setUseWebp(imgItem.webp); // 使用webp
        setShowImage(image || defaultImage);
        imageTypeRef.current = getType?.split(/\.(\w+)$/)[1];
    }, [defaultImage, getType, image]);

    const [mackImageShow, setMackImageShow] = useState(true); // 控制图片未加载完成或者加载失败时显示默认图片且无图片加载失败后显示默认图片的过度行为
    const handleLoad = useCallback(
        (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
            setMackImageShow(true);
            onLoad?.(e);
            lazyLoadingAnimation && setOnceAnimation(true);
            !imgIsRenderedRef.current.includes(showImage) &&
                imgIsRenderedRef.current.push(showImage);
        },
        [lazyLoadingAnimation, onLoad, showImage],
    );
    const handleErrors = useCallback(
        (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
            setMackImageShow(false);
            // 图片渲染失败：用的是webp，再给一次用正常图片的机会
            const img = useWebp
                ? (showImage ?? defaultImg)
                : (defaultImage ?? defaultImg);
            setShowImage((prev) => {
                // 寻找已经加载的图（一般是default图），此时经过一系列操作之后，img最终还是加载失败到最后一张，next的image无法更新，则加时间戳，否则不加时间戳处理
                if ((e.target as any)["data-loaded-src"] === img) {
                    return `${img}?${Date.now()}`;
                }
                return img;
            });
            imageTypeRef.current = (img as string).split(/\.(\w+)$/)[1];
            setUseWebp(false);
            onError?.(e);
        },
        [defaultImage, useWebp, showImage, onError],
    );

    const [onceAnimation, setOnceAnimation] = useState(false);
    const hasImages = useMemo(() => {
        return !!(image || defaultImage);
    }, [defaultImage, image]);

    useEffect(() => {
        setIsFirstRender(false);
    }, []);

    useEffect(() => {
        if (isFirstRender) {
            setIsFirstRender(false);
        } else {
            setShowImage(image || defaultImage);
            imageTypeRef.current = getType?.split(/\.(\w+)$/)[1];
            !imgIsRenderedRef.current.includes(image || defaultImage) &&
                setOnceAnimation(false);
        }
    }, [defaultImage, getType, image, isFirstRender]);

    const defaultImageType = useMemo(() => {
        const type = getSrcWhenImgHaveSomeOtherTypes(defaultImage);
        return `image/${type?.split(/\.(\w+)$/)[1]}`;
    }, [defaultImage]);

    const imageContainer = useMemo(() => {
        return (
            typeof showImage !== "undefined" && (
                <picture className={stl["picture-label"]}>
                    <source
                        srcSet={getWebpUrl(showImage as string, useWebp).src}
                        type="image/webp"
                    ></source>
                    <source
                        srcSet={showImage as string}
                        type={`image/${imageTypeRef.current}`}
                    ></source>
                    {defaultImage && (
                        <source
                            srcSet={defaultImage as string}
                            type={defaultImageType} // defaultImg是jpg
                        ></source>
                    )}
                    <source
                        srcSet={defaultImg}
                        type={"image/jpg"} // defaultImg是jpg
                    ></source>
                    <Image
                        {...rest}
                        onLoad={handleLoad}
                        onError={handleErrors}
                        onClick={(img) => onClick?.()}
                        alt={props.alt ?? ""}
                        src={getWebpUrl(showImage as string, useWebp).src}
                        width={props.width ?? 500}
                        height={props.height ?? 500}
                        className={classnames(stl.image, className, {
                            [stl["visibility-hidden"]]: !mackImageShow,
                            [stl.defaultWidth]: !props.width,
                        })}
                        blurDataURL={rgbDataURL(238, 238, 238)}
                        // placeholder={"blur"}
                        priority={!!priority}
                        loading={loading ?? "lazy"}
                        unoptimized={unoptimized}
                    />
                </picture>
            )
        );
    }, [
        showImage,
        defaultImage,
        defaultImageType,
        handleLoad,
        handleErrors,
        props.alt,
        props.width,
        props.height,
        useWebp,
        className,
        mackImageShow,
        priority,
        loading,
        unoptimized,
        rest,
        onClick,
    ]);

    const imageContent = useMemo(() => {
        return hasImages ? (
            imageContainer
        ) : (
            <Image
                {...rest}
                width={props.width ?? 500}
                height={props.height ?? 500}
                src={defaultImg}
                alt={props.alt ?? ""}
                className={classnames(
                    stl.image,
                    { [stl.defaultWidth]: !props.width },
                    className,
                )}
                priority={!!priority}
                loading={loading}
                unoptimized={unoptimized}
            />
        );
    }, [
        className,
        hasImages,
        imageContainer,
        loading,
        priority,
        props.alt,
        props.height,
        props.width,
        rest,
        unoptimized,
    ]);
    const lazyLoadingContainer = useMemo(() => {
        return lazyLoadingAnimation ? (
            <div
                className={classnames(
                    stl["before-animation"],
                    {
                        [classnames(
                            stl["product-img-load"],
                            "product-img-load",
                        )]: onceAnimation || isFirstRender, // 缓动动效
                    },
                    fmClasses?.lazy?.root,
                )}
            >
                {imageContent}
            </div>
        ) : (
            imageContent
        );
    }, [
        lazyLoadingAnimation,
        onceAnimation,
        isFirstRender,
        fmClasses?.lazy?.root,
        imageContent,
    ]);
    return lazyLoadingContainer;
};

export default FmImage;
