"use client";
import React, { ReactNode, useCallback, useMemo } from "react";
import {
    getCookieItem,
    formattingUrl,
    getUrlArgStr,
    setCookieItem,
} from "@/lib/commonService";
import { FmAccordion, FmButton } from "@/ui-component";
import { LANGUAGE_NAME, CURRENCY_COOKIE_NAME } from "@/config/constConf";
import Cookies from "js-cookie";
import classnames from "classnames";
import stl from "./languageChange.module.scss";

import { getEnv } from "@/src/utils/configUtils";
import { gaCommonEvent } from "@/src/utils/gtagEvents";
import { useRateContext, useCommonContext } from "@/src/provider";
import { RateDataProps } from "@/store/modules/rate";
import { isObject } from "@/src/utils";
import { useTranslation } from "@/src/i18n/client";

//语言配置
const languages = [
    {
        text: "English",
        flag: "en",
    },
    {
        text: "Español",
        flag: "es",
    },
];

interface IProps {}
// 获取支持语种=> 实现切换语种
const LanguageChange: React.FC<IProps> = (props) => {
    const { host, site } = useCommonContext();
    const { t } = useTranslation("common");

    const current = useMemo(() => {
        return typeof window !== "undefined"
            ? Cookies.get(CURRENCY_COOKIE_NAME)
            : getEnv("CURRENCY", host);
    }, [host]);
    const [rateData] = useRateContext((state) => [state.rateData]);

    const handleChangeLang = useCallback((lang: string) => {
        Cookies.set("firmoo_lan", lang);
        window.location.reload();
    }, []);
    const languageList = useMemo(() => {
        const children = languages.map((item) => {
            return (
                <FmButton
                    variant="text"
                    onClick={() => handleChangeLang(item.flag)}
                    key={item.flag}
                    width={"full"}
                    classes={{ root: classnames(stl.language_p, "language_p") }}
                >
                    {item.text}
                </FmButton>
            );
        });
        return {
            id: `${t("common:LANGUAGE")}(${
                getCookieItem(LANGUAGE_NAME) ?? "en"
            })`,
            title: `${t("common:LANGUAGE")}(${
                getCookieItem(LANGUAGE_NAME) ?? "en"
            })`,
            details: {
                children: (
                    <div className={stl["classify-panel"]}>{children}</div>
                ),
            },
        };
    }, [handleChangeLang, t]);
    // 货币AccordionList

    const handleCurrencyClick = useCallback(
        (curRate: RateDataProps) => {
            if (curRate.code === "GBP" && site != "uk") {
                gaCommonEvent("GBP to Firmoo UK");
                window.open("https://www.firmoo.co.uk/");
                return;
            }
            let qury = getUrlArgStr();
            if (qury.currency) {
                qury.currency = curRate.code;
                formattingUrl(qury, location.origin);
            }
            setCookieItem(CURRENCY_COOKIE_NAME, curRate.code);
            location.reload();
        },
        [site],
    );
    const rateList = useMemo(() => {
        const rateKeys = Object.keys(rateData);
        let childrenList: ReactNode[] = [];
        if (!rateKeys.length) {
            childrenList = [];
        }

        childrenList = rateKeys
            .filter(
                (key) => !rateData[key].is_deleted && isObject(rateData[key]),
            )
            .map((key) => {
                const currentRate = rateData[key];
                return (
                    <FmButton
                        className={classnames(stl.language_p, "rate-item")}
                        variant="text"
                        key={key}
                        width={"full"}
                        onClick={() => handleCurrencyClick(currentRate)}
                    >
                        {`${currentRate.title}(${currentRate.symbol_left})`}
                    </FmButton>
                );
            });
        const title = `${rateData[current]?.code}(${rateData[current]?.symbol_left})`;
        return {
            id: title,
            key: title,
            title,
            details: {
                children: (
                    <div
                        className={classnames(
                            stl["classify-panel"],
                            "classify-panel",
                        )}
                    >
                        {childrenList}
                    </div>
                ),
            },
        };
    }, [current, handleCurrencyClick, rateData]);

    const accrodionList = useMemo(() => {
        if (site === "us") {
            return [
                ...(Array.isArray(rateList) ? rateList : [rateList]),
                ...(Array.isArray(languageList)
                    ? languageList
                    : [languageList]),
            ];
        } else {
            return [...(Array.isArray(rateList) ? rateList : [rateList])];
        }
    }, [languageList, site, rateList]);

    return (
        accrodionList.length > 1 && (
            <FmAccordion
                rootClassName={classnames(
                    stl["accordion-container"],
                    "accordion-container",
                )}
                rootExpandedClassName={classnames(
                    stl.root_expanded,
                    "root_expanded",
                )}
                accordionSummaryClassName={classnames(
                    stl.accordion_summary,
                    "accordion_summery",
                )}
                accordionDetailsClassName={classnames(
                    stl.accordion_details,
                    stl.accorion_children_container,
                    "accordion-details",
                )}
                accordions={accrodionList}
            ></FmAccordion>
        )
    );
};

export default LanguageChange;
