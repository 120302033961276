"use client";
import {
    ProductDetailsStore,
    createProductDetailsStore,
    IinitProps,
    IProductDetailsState,
} from "@/store/modules/productDetails";
import { createContext, useRef } from "react";
export const ProductDetailsContext = createContext<ProductDetailsStore | null>(
    null,
);

interface ProductDetailsProviderProps
    extends React.PropsWithChildren<IinitProps> {}

export function ProductDetailsProvider({
    children,
    ...props
}: ProductDetailsProviderProps) {
    const storeRef = useRef<ProductDetailsStore>();
    if (!storeRef.current) {
        storeRef.current = createProductDetailsStore(props);
    }
    return (
        <ProductDetailsContext.Provider value={storeRef.current}>
            {children}
        </ProductDetailsContext.Provider>
    );
}
