// type和初始值initialState
import { create } from "zustand";
import { devtools } from "zustand/middleware";
export interface IState {
    expanded: string;
}

export type Action = {
    // 改变汇率
    changeExpanded: (expanded: string) => void;
};

const initialState: IState = {
    expanded: "-1",
};

type StateAction = IState & Action;

export const useAccordionStore = create<StateAction>()(
    devtools(
        (set, get) => ({
            ...initialState,
            changeExpanded(expanded: string) {
                set({ expanded });
            },
        }),
        {
            name: "accordion-store",
        },
    ),
);
