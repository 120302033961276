import { CSSProperties } from "react";

const filterNavConfig = [
    { id: 0, title: "Color", hasVal: false, useCount: 0 },
    { id: 1, title: "Size", hasVal: false, useCount: 0 },
    { id: 2, title: "Gender", hasVal: false, useCount: 0 },
    // { id: 3, title: "AgeRange", hasVal: false, useCount: 0 },
    { id: 4, title: "Material", hasVal: false, useCount: 0 },
    { id: 5, title: "Shape", hasVal: false, useCount: 0 },
    { id: 6, title: "Rim", hasVal: false, useCount: 0 },
    { id: 7, title: "Feature", hasVal: false, useCount: 0 },
];

//颜色选择
const colorConfig = [
    { id: 0, title: "Black", color: "#000", active: false, val: "Black" },
    { id: 1, title: "White", color: "#F2F4F5", active: false, val: "White" },
    { id: 2, title: "Clear", color: "#dcdcdc", active: false, val: "Clear" },
    {
        id: 3,
        title: "Antique",
        color: "#4a3c29",
        active: false,
        val: "Antique",
    },
    { id: 4, title: "Blue", color: "#00f", active: false, val: "Blue" },
    { id: 5, title: "Brown", color: "#a52a2a", active: false, val: "Brown" },
    {
        id: 6,
        title: "Burgundy",
        color: "#5c0323",
        active: false,
        val: "Burgundy",
    },
    { id: 7, title: "Gold", color: "#9a7a3f", active: false, val: "Gold" },
    { id: 8, title: "Grain", color: "#f5deb3", active: false, val: "Grain" },
    { id: 9, title: "Green", color: "#008000", active: false, val: "Green" },
    { id: 10, title: "Grey", color: "#808080", active: false, val: "Grey" },
    {
        id: 11,
        title: "Gunmetal",
        color: "#655742",
        active: false,
        val: "Gunmetal",
    },
    { id: 12, title: "Matt", color: "#313131", active: false, val: "Matt" },
    {
        id: 13,
        title: "Multicolor",
        color: "#000",
        img: "https://df5apg8r0m634.cloudfront.net/images/c7379ae37120e916fcad50074b9b77fa.jpg?inline",
        active: false,
        val: "Multicolor",
    },
    { id: 14, title: "Orange", color: "#ffa500", active: false, val: "Orange" },
    {
        id: 15,
        title: "Pattern",
        color: "#000",
        img: "https://df5apg8r0m634.cloudfront.net/images/5c523101cdfc80928ff71dd3179cc987.jpg?inline",
        active: false,
        val: "Pattern",
    },
    { id: 16, title: "Pink", color: "#ffc0cb", active: false, val: "Pink" },
    { id: 17, title: "Purple", color: "#800080", active: false, val: "Purple" },
    { id: 18, title: "Red", color: "#f00", active: false, val: "Red" },
    {
        id: 19,
        title: "Bright Pink",
        color: "#ff007e",
        active: false,
        val: "Bright Pink",
    },
    { id: 20, title: "Silver", color: "#c0c0c0", active: false, val: "Silver" },
    {
        id: 21,
        title: "Tortoise",
        color: "rgba(0, 0, 0, 0)",
        img: "https://df5apg8r0m634.cloudfront.net/images/e2e229733483885b0d0b83c2946eb75c.png?inline",
        active: false,
        val: "Tortoise",
    },
    { id: 22, title: "Yellow", color: "#ff0", active: false, val: "Yellow" },
    {
        id: 23,
        title: "Chocolate",
        color: "#d2691e",
        active: false,
        val: "Chocolate",
    },
    { id: 24, title: "Ivory", color: "#f6f6cb", active: false, val: "Ivory" },
    {
        id: 25,
        title: "Rose Gold",
        color: "#c6807e",
        active: false,
        val: "Rose Gold",
    },
    {
        id: 26,
        title: "Rainbow",
        color: "rgba(0, 0, 0, 0)",
        img: "https://df5apg8r0m634.cloudfront.net/images/2023/0417/f298fa671eac97cbe9a30eb10ec0e2d8.png",
        active: false,
        val: "Rainbow",
    },
    {
        id: 27,
        title: "Firmoo Color",
        color: "rgba(0, 0, 0, 0)",
        img: "https://df5apg8r0m634.cloudfront.net/images/2023/0417/48a47409df5e501b36018303b185f652.png",
        active: false,
        val: "Firmoo Color",
    },
];

//单选
const genderConfig = [
    { title: "Unisex", val: 2, id: 0, active: false },
    { title: "Men", val: 1, id: 1, active: false },
    { title: "Women", val: 0, id: 2, active: false },
    { title: "Kids", val: 3, id: 3, active: false },
];

//多选
const materialConfig = [
    {
        title: "Plastic",
        data: [
            { id: 0, title: "Acetate", val: "p", active: false },
            { id: 1, title: "TR", val: "tr", active: false },
            { id: 2, title: "Memory Plastic", val: "mp", active: false },
            { id: 3, title: "Ultem", val: "stss", active: false },
            { id: 4, title: "Silica Gel", val: "sg", active: false },
        ],
    },
    {
        title: "Metal",
        data: [
            { id: 5, title: "Metal", val: "m", active: false },
            { id: 6, title: "Memory Metal", val: "mm", active: false },
            { id: 7, title: "Titanium", val: "t", active: false },
        ],
    },
    {
        title: "Mixed Materials",
        data: [{ id: 8, title: "Mixed Materials", val: "mx", active: false }],
    },
];

const shapeConfig = [
    {
        id: 0,
        title: "Rectangle",
        img: "/static/icon/phone/shape_rectangle.png",
        imgW: 1.22,
        active: false,
        val: 1,
    },
    {
        id: 1,
        title: "Round",
        img: "/static/icon/phone/shape_round.png",
        imgW: 1.22,
        active: false,
        val: 3,
    },
    {
        id: 2,
        title: "Square",
        img: "/static/icon/phone/shape_square.png",
        imgW: 1.22,
        active: false,
        val: 2,
    },
    {
        id: 3,
        title: "Oval",
        img: "/static/icon/phone/shape_oval.png",
        imgW: 1.22,
        active: false,
        val: 4,
    },
    {
        id: 4,
        title: "Aviator",
        img: "/static/icon/phone/shape_aviator.png",
        imgW: 1.22,
        active: false,
        val: 5,
    },
    {
        id: 5,
        title: "Geometric",
        img: "/static/icon/phone/shape_polygon.png",
        imgW: 1.22,
        active: false,
        val: 7,
    },
    {
        id: 6,
        title: "Butterfly",
        img: "/static/icon/phone/shape_horn.png",
        imgW: 1.22,
        active: false,
        val: 6,
    },
    {
        id: 7,
        title: "Cat Eye",
        img: "/static/icon/phone/shape_cat.png",
        imgW: 1.22,
        active: false,
        val: 8,
    },
    {
        id: 8,
        title: "Heart",
        img: "/static/icon/phone/shape_heart.svg",
        imgW: 1.22,
        active: false,
        val: 9,
    },
    {
        id: 9,
        title: "Browline",
        img: "/static/icon/phone/shape_browline.svg",
        imgW: 1.22,
        active: false,
        val: 10,
    },
];

const sizeConfig = [
    {
        title: "Total Width",
        data: [
            { id: 0, title: `Small(<129mm)`, active: false, val: "s" },
            { id: 1, title: "Medium(129mm to 135mm)", active: false, val: "m" },
            { id: 2, title: "Large(>135mm)", active: false, val: "l" },
            { id: 3, title: "Custom", active: false, val: "" },
        ],
        customData: [
            {
                id: 4,
                title: "Total Width",
                img: "/static/icon/phone/size_total.png",
                leftTotal: 100,
                rightTotal: 150,
                leftVal: 100,
                rightVal: 150,
                key: "totalWidth",
            },
            {
                id: 5,
                title: "Lens Width",
                img: "/static/icon/phone/size_lens_w.png",
                leftTotal: 30,
                rightTotal: 140,
                leftVal: 30,
                rightVal: 140,
                key: "lensWidth",
            },
            {
                id: 6,
                title: "Lens Height",
                img: "/static/icon/phone/size_lens_h.png",
                leftTotal: 20,
                rightTotal: 60,
                leftVal: 20,
                rightVal: 60,
                key: "lensHeight",
            },
            {
                id: 7,
                title: "Bridge Width",
                img: "/static/icon/phone/size_bridge.png",
                leftTotal: 9,
                rightTotal: 30,
                leftVal: 9,
                rightVal: 30,
                key: "bridgeDistance",
            },
            {
                id: 8,
                title: "Diagonal Length",
                img: "/static/icon/phone/size_diagonal.png",
                leftTotal: 40,
                rightTotal: 70,
                leftVal: 40,
                rightVal: 70,
                key: "diagonal",
            },
            {
                id: 9,
                title: "Temple Length",
                img: "/static/icon/phone/size_temple.png",
                leftTotal: 115,
                rightTotal: 165,
                leftVal: 115,
                rightVal: 165,
                key: "templeLength",
            },
        ],
    },
];

const sortByConfig = [
    { title: "Featured", id: 1, active: false, val: null },
    { title: "Newest Arrivals", id: 2, active: false, val: "news" },
    { title: "Best Sellers", id: 3, active: false, val: "hot" },
    { title: "High to Low", id: 4, active: false, val: "price_high" },
    { title: "Low to High", id: 5, active: false, val: "price_low" },
];

const frameConfig = [
    {
        id: 0,
        title: "Full-Rim",
        img: "/static/icon/phone/rirm_full.png",
        imgW: 1.2,
        active: false,
        val: "f",
    },
    {
        id: 1,
        title: "Semi-Rimless",
        img: "/static/icon/phone/rim_semi.png",
        imgW: 1.2,
        active: false,
        val: "s",
    },
    {
        id: 2,
        title: "Rimless",
        img: "/static/icon/phone/rim_less.png",
        imgW: 1.2,
        active: false,
        val: "r",
    },
];

const featureConfig = [
    {
        id: 0,
        title: "Spring Hinge",
        active: false,
        val: "products_have_spring_hinge",
    },
    {
        id: 1,
        title: "Adjustable nose pads",
        active: false,
        val: "adjust_nose_pads",
    },
    { id: 2, title: "Bifocal & Progressive", active: false, val: "bifocal" },
    { id: 3, title: "Folding readers", active: false, val: "folding" },
    { id: 4, title: "Neckglasses", active: false, val: "neckglasses" },
    {
        id: 5,
        title: "Without nose pads",
        active: false,
        val: "without_adjust_nose_pads",
    },
];

export const ShapeConfig = [
    {
        id: 0,
        title: "Rectangle",
        img: "static/icon/phone/shape_rectangle.png",
        imgW: 1.22,
        active: false,
        val: 1,
    },
    {
        id: 1,
        title: "Round",
        img: "static/icon/phone/shape_round.png",
        imgW: 1.22,
        active: false,
        val: 3,
    },
    {
        id: 2,
        title: "Square",
        img: "static/icon/phone/shape_square.png",
        imgW: 1.22,
        active: false,
        val: 2,
    },
    {
        id: 3,
        title: "Oval",
        img: "static/icon/phone/shape_oval.png",
        imgW: 1.22,
        active: false,
        val: 4,
    },
    {
        id: 4,
        title: "Aviator",
        img: "static/icon/phone/shape_aviator.png",
        imgW: 1.22,
        active: false,
        val: 5,
    },
    {
        id: 5,
        title: "Geometric",
        img: "static/icon/phone/shape_polygon.png",
        imgW: 1.22,
        active: false,
        val: 7,
    },
    {
        id: 6,
        title: "Butterfly",
        img: "static/icon/phone/shape_horn.png",
        imgW: 1.22,
        active: false,
        val: 6,
    },
    {
        id: 7,
        title: "Cat Eye",
        img: "static/icon/phone/shape_cat.png",
        imgW: 1.22,
        active: false,
        val: 8,
    },
    {
        id: 8,
        title: "Heart",
        img: "static/icon/phone/shape_heart.svg",
        imgW: 1.22,
        active: false,
        val: 9,
    },
    {
        id: 9,
        title: "Browline",
        img: "static/icon/phone/shape_browline.svg",
        imgW: 1.22,
        active: false,
        val: 10,
    },
];

function getColorByName(name: string): Record<string, any> | string {
    // 在config里能找到，说明做了特殊处理，否则直接返回可获取的颜色
    let colorArr = colorConfig.filter((item) => item.title === name);
    if (colorArr.length) {
        return colorArr[0];
    }

    return name;
}

const colorSelectInit = (name: string | undefined) => {
    if (!name) {
        return {};
    }
    let nameArr = name.split(",");
    // 拿到对应颜色||图片 => 图片？设置图片 => 颜色.length > 1 ? 渐变色 ： 普通色
    let colorClass: string[] = [];
    let colorStyle: CSSProperties = {
        backgroundSize: "cover",
        backgroundImage: "none",
        border: "none",
    };
    nameArr.forEach((val) => {
        let _color: Record<string, any> | string = getColorByName(val);

        // 颜色确定（来自config || 自己本身的颜色）
        if (typeof _color === "string" || !_color.img) {
            return colorClass.push(
                typeof _color === "string"
                    ? _color
                    : _color.color || _color.title,
            );
        }
        if (_color.img || _color.boderColor) {
            colorClass.push(_color.color || _color.title);
            _color.boderColor &&
                (colorStyle.border = `1px solid ${_color.boderColor}`);
            _color.img &&
                (colorStyle.backgroundImage = 'url("' + _color.img + '")'); // 不要问我为什么不用``, 问就是会转义
        }
    });
    if (colorStyle.backgroundImage === "none") {
        const resColor =
            colorClass.length > 1
                ? colorClass.join(",")
                : `${colorClass}, ${colorClass}`;
        colorStyle.background = `linear-gradient(${resColor})`;
    }
    // 过滤掉不正常的css如#ddd || rgba?xxx
    const colorRes = colorClass.filter(
        (item) => !(item.startsWith("#") || /rgba?/.test(item)),
    );
    return {
        colorClass: colorRes,
        colorStyle,
    };
};

const KidsAgeRangeConfig = [
    { title: "4-7", val: 1, id: 0, active: false },
    { title: "8-12", val: 2, id: 1, active: false },
    { title: "13-15", val: 3, id: 2, active: false },
];

export {
    filterNavConfig,
    sortByConfig,
    colorConfig,
    genderConfig,
    materialConfig,
    shapeConfig,
    sizeConfig,
    frameConfig,
    featureConfig,
    colorSelectInit,
    KidsAgeRangeConfig,
};
