import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { State, StateAction, initialState } from "./initialState";

export const useHeaderStore = create<StateAction>()(
    devtools(
        (set, get) => ({
            ...initialState,
            setHeaderOptions(options: Partial<State>[] | Partial<State>) {
                // [{ isShowUserContent: show }]
                Array.isArray(options)
                    ? options.forEach((item) => {
                          set({ ...item });
                      })
                    : set({ ...options });
            },
            closeOtherPanels() {
                set({ isShowUserContent: false }); // 目前只有这一个弹窗
            },
        }),
        {
            name: "header-storage",
        },
    ),
);
