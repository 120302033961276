/**
 * @description: store中处理筛选的函数
 * @author: zhouyh
 * @time: 2023/8/9 13:14
 */
import {
    CustomSizeItem,
    FilterConditionProps,
    FilterNavConfig,
    initialState,
    SizeItem,
} from "@/store/modules/filterConditions/initialState";
import { cloneDeepWith } from "lodash";
import { sizeConfig } from "@/config/filterConfig";

// 颜色
export function filterColor(state: FilterConditionProps, obj: any) {
    const { colorData } = state;
    const color: any[] = [];
    colorData.forEach((element) => {
        element.id === obj.id && (element.active = !element.active);
        element.active && color.push(element);
    });

    const filterNavConfig = cloneDeepWith(state.filterNavConfig);
    for (let i = 0; i < filterNavConfig.length; i++) {
        if (filterNavConfig[i].title === "Color") {
            filterNavConfig[i].hasVal = color.length > 0;
            filterNavConfig[i].useCount = color.length;
            break;
        }
    }

    return {
        ...state,
        filterNavConfig,
        color,
        colorData,
        isFilter: checkFilter(filterNavConfig),
    };
}

// 尺寸
export function filterSize(state: FilterConditionProps, id: any) {
    let size: any;
    let sizeId = -1;
    let { sizeData } = state;
    let _arr = cloneDeepWith(sizeConfig);
    sizeData.forEach((arg) => {
        arg.data.forEach((element) => {
            if (element.id === id) {
                element.active = !element.active;
            } else {
                element.active = false;
            }

            if (element.active) {
                size = element;
                sizeId = element.id;
            }
        });

        arg.customData = _arr[0].customData;
    });
    const filterNavConfig = cloneDeepWith(state.filterNavConfig);
    for (let i = 0; i < filterNavConfig.length; i++) {
        if (filterNavConfig[i].title === "Size") {
            filterNavConfig[i].hasVal = !!size;
            filterNavConfig[i].useCount = !!size ? 1 : 0;
            break;
        }
    }

    return {
        ...state,
        filterNavConfig,
        isFilter: checkFilter(filterNavConfig),
        size,
        sizeId,
        sizeData,
        totalWidth: "",
        lensWidth: "",
        lensHeight: "",
        bridgeDistance: "",
        diagonal: "",
        templeLength: "",
    };
}

// 尺寸滑动选择
export function filterSizeSlider(state: FilterConditionProps, sliderObj: any) {
    let totalWidth = "";
    let lensWidth = "";
    let lensHeight = "";
    let bridgeDistance = "";
    let diagonal = "";
    let templeLength = "";
    state.sizeData.forEach((arg) => {
        arg.data.forEach((element) => {
            if (element.id != 3) {
                element.active = false;
            }
        });
        arg.customData.forEach((element) => {
            element.active = false;
            if (element.id === sliderObj.id) {
                element.leftVal = sliderObj.leftVal;
                element.rightVal = sliderObj.rightVal;
            }

            if (
                element.leftTotal != element.leftVal ||
                element.rightTotal != element.rightVal
            ) {
                if (element.id === 4) {
                    totalWidth = element.leftVal + "-" + element.rightVal;
                }
                if (element.id === 5) {
                    lensWidth = element.leftVal + "-" + element.rightVal;
                }
                if (element.id === 6) {
                    lensHeight = element.leftVal + "-" + element.rightVal;
                }
                if (element.id === 7) {
                    bridgeDistance = element.leftVal + "-" + element.rightVal;
                }
                if (element.id === 8) {
                    diagonal = element.leftVal + "-" + element.rightVal;
                }
                if (element.id === 9) {
                    templeLength = element.leftVal + "-" + element.rightVal;
                }
            }
        });
    });
    const filterNavConfig = cloneDeepWith(state.filterNavConfig);
    for (let i = 0; i < filterNavConfig.length; i++) {
        if (filterNavConfig[i].title === "Size") {
            filterNavConfig[i].hasVal = true;
            filterNavConfig[i].useCount = 1;
            break;
        }
    }

    return {
        ...state,
        filterNavConfig,
        num: new Date().getTime(),
        isFilter: checkFilter(filterNavConfig),
        size: "",
        totalWidth,
        lensWidth,
        lensHeight,
        bridgeDistance,
        templeLength,
        diagonal,
    };
}

// 性别
export function filterGender(state: FilterConditionProps, id: any) {
    const gender: any[] = [];
    state.genderData.forEach((element) => {
        if (element.id === id) {
            element.active = !element.active;
        }

        if (element.active) {
            gender.push(element);
        }
    });
    const filterNavConfig = cloneDeepWith(state.filterNavConfig);
    for (let i = 0; i < filterNavConfig.length; i++) {
        if (filterNavConfig[i].title === "Gender") {
            filterNavConfig[i].hasVal = gender.length > 0;
            filterNavConfig[i].useCount = gender.length;
            break;
        }
    }

    return {
        ...state,
        filterNavConfig,
        isFilter: checkFilter(filterNavConfig),
        gender,
    };
}

// 儿童镜的年龄选择，在选择了kid之后出现
export function filterAgeRange(state: FilterConditionProps, id: any) {
    const ageRange: any[] = [];
    state.kidsAgeRangeData.forEach((element) => {
        if (element.id === id) {
            element.active = !element.active;
        }

        if (element.active) {
            ageRange.push(element);
        }
    });
    const filterNavConfig = cloneDeepWith(state.filterNavConfig);
    for (let i = 0; i < filterNavConfig.length; i++) {
        if (filterNavConfig[i].title === "AgeRange") {
            filterNavConfig[i].hasVal = ageRange.length > 0;
            filterNavConfig[i].useCount = ageRange.length;
            break;
        }
    }

    return {
        ...state,
        filterNavConfig,
        isFilter: checkFilter(filterNavConfig),
        ageRange,
    };
}

// 材质
export function filterMaterial(state: FilterConditionProps, id: any) {
    const material: any[] = [];
    state.materialData.forEach((arg) => {
        arg.data.forEach((element) => {
            if (element.id === id) {
                element.active = !element.active;
            }

            if (element.active) {
                material.push(element);
            }
        });
    });
    const filterNavConfig = cloneDeepWith(state.filterNavConfig);
    for (let i = 0; i < filterNavConfig.length; i++) {
        if (filterNavConfig[i].title === "Material") {
            filterNavConfig[i].hasVal = material.length > 0;
            filterNavConfig[i].useCount = material.length;
            break;
        }
    }
    return {
        ...state,
        filterNavConfig,
        isFilter: checkFilter(filterNavConfig),
        material,
    };
}

// 风格
export function filterShape(state: FilterConditionProps, id: any) {
    const shape: any[] = [];
    state.shapeData.forEach((element) => {
        if (element.id === id) {
            element.active = !element.active;
        }

        if (element.active) {
            shape.push(element);
        }
    });
    const filterNavConfig = cloneDeepWith(state.filterNavConfig);
    for (let i = 0; i < filterNavConfig.length; i++) {
        if (filterNavConfig[i].title === "Shape") {
            filterNavConfig[i].hasVal = shape.length > 0;
            filterNavConfig[i].useCount = shape.length;
            break;
        }
    }

    return {
        ...state,
        filterNavConfig,
        isFilter: checkFilter(filterNavConfig),
        shape,
    };
}

// 框型
export function filterRim(state: FilterConditionProps, id: any) {
    const frame: any[] = [];
    state.frameData.forEach((element) => {
        if (element.id === id) {
            element.active = !element.active;
        }

        if (element.active) {
            frame.push(element);
        }
    });
    const filterNavConfig = cloneDeepWith(state.filterNavConfig);
    for (let i = 0; i < filterNavConfig.length; i++) {
        if (filterNavConfig[i].title === "Rim") {
            filterNavConfig[i].hasVal = frame.length > 0;
            filterNavConfig[i].useCount = frame.length;
            break;
        }
    }
    return {
        ...state,
        filterNavConfig,
        isFilter: checkFilter(filterNavConfig),
        frame,
    };
}

// feature
export function filterFeature(state: FilterConditionProps, id: any) {
    const feature: any[] = [];
    state.featureData.forEach((element) => {
        if (element.id === id) {
            element.active = !element.active;
        }

        if (element.active) {
            feature.push(element);
        }
    });
    const filterNavConfig = cloneDeepWith(state.filterNavConfig);
    for (let i = 0; i < filterNavConfig.length; i++) {
        if (filterNavConfig[i].title === "Feature") {
            filterNavConfig[i].hasVal = feature.length > 0;
            filterNavConfig[i].useCount = feature.length;
            break;
        }
    }
    return {
        ...state,
        filterNavConfig,
        isFilter: checkFilter(filterNavConfig),
        feature,
    };
}

// 选中的选项
export function filterActive(state: FilterConditionProps) {
    const filterCondition = {
        color: state.color,
        frame: state.frame,
        gender: state.gender,
        material: state.material,
        shape: state.shape,
        feature: state.feature,
        size: state.size,
        ageRange: state.ageRange,
    };
    const conditionArr = handleFilterActive(filterCondition);
    const filterItem = filterItemJson(filterCondition);
    const flatFilterArr = flatFilter(filterCondition);

    return {
        ...state,
        filterItem,
        filterArr: conditionArr,
        flatFilterArr,
        filterSubItem: conditionArr.join(","),
    };
}

// 从URL中解析
export function fromQuery(state: FilterConditionProps, reqCondition: any) {
    const _state = handleReqCondition(reqCondition);
    return {
        ..._state,
    };
}

// change
export function fCChange(state: FilterConditionProps, conditions: any) {
    return {
        ...state,
        ...conditions,
    };
}

//检查是否有筛选项
const checkFilter = (_filterNavConfig: FilterNavConfig) => {
    if (!_filterNavConfig) {
        return false;
    }

    let isFilter = false;
    _filterNavConfig.forEach((element) => {
        if (element.hasVal) {
            isFilter = true;
        }
    });

    return isFilter;
};

// 处理请求条件
export const handleReqCondition = (reqCondition: any) => {
    const _initState: any = cloneDeepWith(initialState);
    const conditions = [
        { key: "products_have_spring_hinge", featureIndex: 0 },
        { key: "adjust_nose_pads", featureIndex: 1 },
        { key: "bifocal", featureIndex: 2 },
        { key: "folding", featureIndex: 3 },
        { key: "neckglasses", featureIndex: 4 },
    ];

    for (const condition of conditions) {
        if (reqCondition[condition.key]) {
            _initState.feature.push(
                _initState.featureData[condition.featureIndex],
            );
            _initState.featureData[condition.featureIndex].active = true;
            _initState.filterNavConfig[6].hasVal = true;
            _initState.filterNavConfig[6].useCount += 1;
            _initState.isFilter = true;
        } else {
            _initState.isFilter = false;
        }
    }
    const handleSingleCondition = (
        key: string,
        dataKey: string,
        index: number,
        filterIndex: number,
    ) => {
        if (reqCondition[key]) {
            _initState[dataKey] = filterValInArr(
                reqCondition[key],
                _initState[dataKey + "Data"],
            );
            _initState.isFilter = true;
            _initState.filterNavConfig[filterIndex].hasVal = true;
            _initState.filterNavConfig[filterIndex].useCount =
                reqCondition[key].split(",").length;
        }
    };

    handleSingleCondition("colors", "color", 0, 0);
    handleSingleCondition("products_frame", "frame", 5, 5);
    handleSingleCondition("products_shape", "shape", 4, 4);
    handleSingleCondition("gender", "gender", 2, 2);
    handleSingleCondition("products_metal", "material", 3, 3);

    if (reqCondition.size) {
        _initState.size = filterValInArr(
            reqCondition.size,
            _initState.sizeData,
        )[0];
        _initState.filterNavConfig[1].hasVal = true;
        _initState.filterNavConfig[1].useCount = 1;
    }

    if (reqCondition.order_by) {
        const Bobj = {
            title: "Featured",
            id: 1,
            active: false,
            val: "B",
        };
        _initState.sortBy =
            reqCondition.order_by === "B"
                ? Bobj
                : filterValInArr(
                      reqCondition.order_by,
                      _initState.sortByData,
                  )[0];
    }
    if (reqCondition.page) {
        _initState.page = reqCondition.page;
    }

    const sliderKeys = [
        "totalWidth",
        "lensWidth",
        "lensHeight",
        "bridgeDistance",
        "templeLength",
        "diagonal",
    ];

    for (const key of sliderKeys) {
        filterSliderValInArr(reqCondition[key], key, _initState);
    }

    // if (reqCondition.ageRange) {
    //     _initState.ageRange = filterValInArr(
    //         reqCondition.ageRange,
    //         _initState.ageRangeData,
    //     );
    //     _initState.isFilter = true;
    //     _initState.filterNavConfig[3].hasVal = true;
    // }

    _initState.filterArr = handleFilterActive(_initState);
    _initState.filterItem = filterItemJson({
        color: _initState.color,
        frame: _initState.frame,
        gender: _initState.gender,
        material: _initState.material,
        shape: _initState.shape,
        feature: _initState.feature,
        size: _initState.size,
        ageRange: _initState.ageRange,
    });
    _initState.filterSubItem = _initState.filterArr.join(",");
    // AB模版的初始化
    if (reqCondition.flowTemplateTag)
        _initState.flowTemplateTag = reqCondition.flowTemplateTag;
    return _initState;
};

//处理url滑动筛选状态
function filterSliderValInArr(target: string, key: string, initial: any) {
    if (!target) {
        return;
    }
    initial.sizeId = 3;
    initial[key] = target;
    initial.isFilter = true;
    initial.filterNavConfig[1].hasVal = true;
    initial.filterNavConfig[1].useCount = 1;
    const targetArr = target.split("-");
    initial.sizeData[0].customData.forEach((element: CustomSizeItem) => {
        if (element.key === key) {
            element.leftVal = Number(targetArr[0]);
            element.rightVal = Number(targetArr[1]);
        }
    });
}

// 处理url参数筛选状态
export function filterValInArr(target: string, initial: any[]) {
    const result: any[] = [];

    if (target.indexOf(",") > -1) {
        const targetArr = target.split(",");
        targetArr.forEach((arg) => {
            initial.forEach((element) => {
                if (element.data && typeof element === "object") {
                    element.data.forEach((value: any) => {
                        if (value.val == arg) {
                            value.active = true;
                            result.push(value);
                        }
                    });
                } else {
                    if (element.val == arg) {
                        element.active = true;
                        result.push(element);
                    }
                }
            });
        });
    } else {
        initial.forEach((element) => {
            if (element.data && typeof element === "object") {
                element.data.forEach((value: any) => {
                    if (value.val == target) {
                        value.active = true;
                        result.push(value);
                    }
                });
            } else {
                if (element.val == target) {
                    element.active = true;
                    result.push(element);
                }
            }
        });
    }

    return result;
}

//展示选中条件的名字
function handleFilterActive(condition: FilterItemJsonProps) {
    const { color, frame, material, shape, size, gender, feature, ageRange } =
        condition;
    const conditionArr = [
        ...getTitle(color),
        ...getTitle(frame),
        ...getTitle(gender),
        ...getTitle(material),
        ...getTitle(shape),
        ...getTitle(feature),
        ...getTitle(ageRange),
    ];
    size && conditionArr.push(size.title);
    return conditionArr;
}

// 获取title
function getTitle(arr: any[]) {
    const val: any = [];
    arr.forEach((element) => {
        val.push(element.title);
    });

    return val;
}

type FilterItemJsonProps = {
    color: any[];
    frame: any[];
    gender: any[];
    material: any[];
    shape: any[];
    feature: any[];
    size?: SizeItem;
    ageRange: any[];
};
export type FilterType = keyof FilterItemJsonProps;
// 获得筛选项
function filterItemJson(filterItem: FilterItemJsonProps): string {
    const { color, frame, gender, material, shape, feature, size, ageRange } =
        filterItem;
    const filter_item_arr: string[] = [];

    if (color.length > 0) filter_item_arr.push("Color");
    if (frame.length > 0) filter_item_arr.push("Rim");
    if (gender.length > 0) filter_item_arr.push("Gender");
    if (material.length > 0) filter_item_arr.push("Material");
    if (feature.length > 0) filter_item_arr.push("Feature");
    if (shape.length > 0) filter_item_arr.push("Shape");
    if (size) filter_item_arr.push("Size");
    if (ageRange.length > 0) filter_item_arr.push("AgeRange");

    return filter_item_arr.join(",");
}

function flatFilter(filterItem: FilterItemJsonProps) {
    const flatArr: any[] = [];
    for (const key in filterItem) {
        if (Object.prototype.hasOwnProperty.call(filterItem, key)) {
            const element = filterItem[key as keyof FilterItemJsonProps];

            if (Array.isArray(element)) {
                element.forEach((item) => {
                    flatArr.push({ ...item, key });
                });
            } else if (element) {
                flatArr.push({ ...element, key });
            }
        }
    }
    return flatArr;
}
