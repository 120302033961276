import React, { ReactNode } from "react";
import stl from "../styles/loading.module.scss";
import { IStyleProps } from "../interface";
import classnames from "classnames";
export interface IProps extends IStyleProps {
    visible: boolean;
    text?: string | ReactNode;
}
const FmLoading: React.FC<IProps> = (props) => {
    return (
        <div
            className={classnames(
                stl["loading-animation-wrapper"],
                {
                    ["hidden"]: !props.visible,
                },
                props.className,
            )}
            style={props.style}
        >
            <div className={stl["loading-animation-container"]}>
                <div className={stl["loading-animation-content"]}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <p className={stl["loading-text"]}>{props.text || "Loading..."}</p>
        </div>
    );
};

export default FmLoading;
export { type IProps as ILoadingProps };
