export interface ISignInModalProps {
  visible: boolean;
  modalPage: number;
  loginCallback?: () => any;
}

export interface IReturnAction {
  type: string;
  loginCallback?: () => any;
  modalPage?: number;
}

// 对应modalPage是登录还是注册模块
export enum MODALPAGE {
  LOGINPANEL,
  REGISTERPANAL,
}

export type State = ISignInModalProps;

export type ISignInModalStateAndAction = State & ISignInModalAction;
export type ISignInModalAction = {
  openModal: (loginCallback?: () => any, modalPage?: number) => void;

  closeModal: () => void;

  changePage: (modalPage: number) => void;
};

export const initialState: State = {
  visible: false,
  modalPage: MODALPAGE.LOGINPANEL,
};
