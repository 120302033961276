"use client";
import { useEffect, useState } from "react";
import i18next from "i18next";
import {
    initReactI18next,
    UseTranslationOptions,
    useTranslation as useTranslationOrg,
} from "react-i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { Namespace, getOptions } from "./settings";
import { useCommonContext } from "../provider";

const runsOnServerSide = typeof self === "undefined";

//
i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(
        resourcesToBackend(async (language: string, namespace: string) => {
            if (runsOnServerSide) {
                return globalThis.APOLLO_DATA.langConfig[language][
                    `${namespace}.json`
                ];
            }
            return await (
                await fetch(`/api/locales/${language}/${namespace}.json`)
            ).json();
        }),
    )
    .init({
        ...getOptions(),
        lng: undefined, // let detect the language on client side
        detection: {
            order: ["cookie", "querystring", "LanguageDetector"],
            lookupCookie: "firmoo_lan",
            lookupQuerystring: "lang",
        },
        load: "currentOnly",
    });

export function useTranslation(
    ns?: Namespace,
    options?: UseTranslationOptions<undefined> | undefined,
) {
    const lang = useCommonContext().lang;
    // if (runsOnServerSide) options = { ...options, lng: lang };
    const ret = useTranslationOrg(ns, options);
    const { i18n } = ret;
    if (runsOnServerSide && lang && i18n.resolvedLanguage !== lang) {
        i18n.changeLanguage(lang);
    } else {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [activeLng, setActiveLng] = useState(i18n.resolvedLanguage);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
            if (activeLng === i18n.resolvedLanguage) return;
            setActiveLng(i18n.resolvedLanguage);
        }, [activeLng, i18n.resolvedLanguage]);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
            if (!lang || i18n.resolvedLanguage === lang) return;
            i18n.changeLanguage(lang);
        }, [lang, i18n]);
    }
    return ret;
}
