"use client";
import { IPackageInfoAction } from "@/store/modules/packageInfo";
import { useContext } from "react";
import { useStore } from "zustand";
import { PackageInfoContext } from "./Provider";

export function usePackageInfoContext<T>(
    selector: (state: IPackageInfoAction) => T,
): T {
    const store = useContext(PackageInfoContext);
    if (!store)
        throw new Error("Missing PackageInfoContext.Provider in the tree");
    return useStore(store, selector);
}

export { PackageInfoProvider } from "./Provider";
