"use client";
import {
    ReviewAndFqStore,
    IRequestAndFqAndAction,
    createReviewAndFqStore,
} from "@/store/modules/reviewAndFq";
import { createContext, useRef } from "react";
export const ReviewAndFqContext = createContext<ReviewAndFqStore | null>(null);

interface ReviewAndFqProviderProps
    extends React.PropsWithChildren<IRequestAndFqAndAction> {}

export function ReviewAndFqProvider({
    children,
    ...props
}: ReviewAndFqProviderProps) {
    const storeRef = useRef<ReviewAndFqStore>();
    if (!storeRef.current) {
        storeRef.current = createReviewAndFqStore(props);
    }
    return (
        <ReviewAndFqContext.Provider value={storeRef.current}>
            {children}
        </ReviewAndFqContext.Provider>
    );
}
