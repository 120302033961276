import React, { useCallback, useMemo } from "react";
import {
    removeLocalItem,
    removeCookieItem,
    setSession,
} from "@/lib/commonService";
import classnames from "classnames";
import stl from "./userNav.module.scss";

import {
    JWT_COOKIE_NAME,
    MIRROR_BOX_FREE,
    MIRROR_BOX_FREE_ADDON,
    ADD_ON_THIS_DEVICE,
    WINDOW_SESSION_KEYS,
    IS_OLD_CUSTOMER_COOKIE_NAME,
} from "@/config/constConf";
import { gaReferral, referralClick } from "@/src/utils/gtagEvents";
import { FmButton } from "@/ui-component";
import { IStyleProps } from "@/src/app/interface";
import { useTranslation } from "@/src/i18n/client";
interface ILoginProps extends IStyleProps {
    userInfo: Record<string, any>;
    isShowFirmooAssets: any;
    makeMoneyHref: string;
    pageChange: (url: string, _url?: Record<string, any>) => void;
}

const LoginComponent: React.FC<ILoginProps> = (props) => {
    const { t } = useTranslation("common");
    const signOut = useCallback(() => {
        removeLocalItem(MIRROR_BOX_FREE);
        removeLocalItem(MIRROR_BOX_FREE_ADDON);
        removeLocalItem(ADD_ON_THIS_DEVICE);
        removeCookieItem(JWT_COOKIE_NAME);
        removeCookieItem(IS_OLD_CUSTOMER_COOKIE_NAME);
        localStorage.removeItem("email");
        location.reload();
        //清空 sessionSt
        setSession(WINDOW_SESSION_KEYS.BASKET_NUM_SESSION, "");
    }, []);

    const handleShowfirmooClick = useCallback(() => {
        referralClick({
            page: "index",
            is_old_customer: props.userInfo.is_old_customer,
        });
        gaReferral("referral_rentrance_navigation", "top");
        props.pageChange(props.makeMoneyHref, {});
    }, [props]);

    const showFirmooAssets = useMemo(() => {
        return (
            props.isShowFirmooAssets && (
                <FmButton
                    onClick={handleShowfirmooClick}
                    variant={"text"}
                    fullWidth
                    iconLeft
                    icon={"icon--masonry"}
                    className={classnames(
                        stl["common-nav-btn"],
                        "common-nav-btn",
                    )}
                >
                    {t("common:nav:MakeMoney")}
                </FmButton>
            )
        );
    }, [handleShowfirmooClick, props.isShowFirmooAssets, t]);
    return (
        <div
            className={classnames(
                stl["user-nav-container"],
                "user-nav-container",
                props.className,
            )}
        >
            <div className={classnames(stl["user-nav-sign-container"])}>
                <h1
                    className={classnames(stl["title"], {
                        ["hidden"]: !props.userInfo.customers_firstname,
                    })}
                >
                    {t("common:nav:Hi")} {props.userInfo.customers_firstname}
                </h1>
                <div className={stl["logined-btn-container"]}>
                    <FmButton
                        onClick={() => {
                            location.href = "/account/myAccount";
                        }}
                        variant={"text"}
                        fullWidth
                        iconLeft
                        icon={"icon--user"}
                        className={classnames(
                            stl["common-nav-btn"],
                            "common-nav-btn",
                        )}
                        contentClassName={stl["content-icon"]}
                    >
                        {t("common:nav:MyAccount")}
                    </FmButton>
                    <FmButton
                        onClick={() => props.pageChange("/account")}
                        variant={"text"}
                        fullWidth
                        iconLeft
                        icon={"icon--nav-maorder"}
                        className={classnames(
                            stl["common-nav-btn"],
                            "common-nav-btn",
                        )}
                        contentClassName={stl["content-icon"]}
                    >
                        {t("common:nav:MyOrders")}
                    </FmButton>

                    {showFirmooAssets}
                </div>
            </div>
            <div className={stl["sign-out-btn"]}>
                <FmButton
                    onClick={signOut}
                    variant={"text"}
                    className={classnames(stl["logout"], stl["logout-btn"])}
                >
                    <p>{t("common:nav:LogOut")}</p>
                </FmButton>
            </div>
        </div>
    );
};

export default LoginComponent;
