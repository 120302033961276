"use client";
/**
 * 1. header中间滚动动效：tips被滚动到底部贴顶（header的顶部贴顶），header变为fixed；应该是设置zindex, 底部内容：zIndex = 弹窗
 */
import React, { useEffect, useMemo, useRef } from "react";
import { headerStore } from "@/store/pageModules";
import { signInModal } from "@/store";
import AppBar from "../../AppBar";
import classnames from "classnames";
import LoginModal from "../../LoginDialog/LoginModal";
import stl from "./styles/appBar.module.scss";

export interface IProps {
    hiddenWhenNoFixed?: boolean; // 是否在header不被定住时隐藏
}

const AppBarClient: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    // 处理当header被fixed时其parent无法获取正确高度问题
    const headerContentRef = useRef<HTMLDivElement>(null);
    const [fixedHeader, setHeaderOptions] = headerStore.useHeaderStore(
        (state) => [
            state.fixedHeader,
            state.setHeaderOptions,
            // state.closeOtherPanels,
        ],
    ); // user-login显示or隐藏
    const headerPosition = useMemo(() => {
        return fixedHeader ? "fixed" : "static";
    }, [fixedHeader]);

    useEffect(() => {
        const height = headerContentRef.current?.clientHeight ?? null;
        setHeaderOptions({
            headerContentHeight: height,
        });
    }, [setHeaderOptions]);
    const [closeModal, visible] = signInModal.useSignInModalStore((state) => [
        state.closeModal,
        state.visible,
    ]);
    const hiddenHeaderContent = useMemo(() => {
        return !!props.hiddenWhenNoFixed && !fixedHeader;
    }, [fixedHeader, props.hiddenWhenNoFixed]);
    return (
        <>
            <AppBar
                ref={headerContentRef}
                position={headerPosition}
                className={classnames(stl["app-bar-container"], {
                    ["hidden"]: hiddenHeaderContent, // 兼容商品详情页的header在不被定住时则隐藏
                })}
            >
                {props.children}
            </AppBar>
            <LoginModal open={visible} onClose={closeModal} />
        </>
    );
};

export default AppBarClient;
