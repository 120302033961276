"use client";
import { StackProps, Stack } from "@mui/material";
import React, { forwardRef, useImperativeHandle, useRef } from "react";

const FmStack = forwardRef(function FmStack(props: StackProps, ref) {
    const stackRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(
        ref,
        () => {
            return stackRef.current;
        },
        [],
    );
    return <Stack ref={stackRef} {...props}></Stack>;
});

export default FmStack;
