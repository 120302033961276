import React, { ReactNode, CSSProperties } from "react";
import classnames from "classnames";
import stl from "./text.module.scss";
interface IProps {
  type?: "title" | "main" | "nav" | "discrible" | "sub" | "explain";
  color?: "main" | "title" | "sub" | "three" | "succeed" | "warn" | "error";
  text?: ReactNode;
  style?: CSSProperties;
}

const defaultProps = {
  type: "main",
  color: "title",
  text: "",
};
const Text: React.FC<IProps> = (props) => {
  return (
    <span
      className={classnames(
        stl[`firmoo-text-${props.type}`],
        stl[`firmoo-text-color-${props.color}`],
      )}
      style={props.style}
    >
      {props.text}
    </span>
  );
};

export default Text;
