import { FmToolbar } from "@/ui-component";
import UserNav from "../LoginDialog/UserNav";

interface IProps {
    userInfo: Record<string, any>;
    closeUserContent(): void;
    height: number;
}

const UserLoginContent: React.FC<IProps> = (props) => {
    return (
        <>
            <FmToolbar
                className={"tool-bar-of-user-login"}
                style={{ minHeight: props.height }}
            />
            <UserNav
                onClose={props.closeUserContent}
                userInfo={props.userInfo}
            />
        </>
    );
};

export default UserLoginContent;
