import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import {
    IUserInfoStateAndAction,
    IUserInfoProps,
    initialState,
} from "./initialState";
import { setCookieItem } from "@/lib/commonService";
import { IS_OLD_CUSTOMER_COOKIE_NAME } from "@/config/constConf";
import { getAccount } from "@/src/models/account";
import { isLogin } from "@/src/models";

export type UserInfoStore = ReturnType<typeof createUserInfoStore>;

export const createUserInfoStore = (initProps?: Partial<IUserInfoProps>) => {
    const init = Object.assign(initialState, initProps);

    return create<IUserInfoStateAndAction>()(
        devtools(
            persist(
                (set, get) => ({
                    ...init,
                    getUserInfo: async () => {
                        if (!isLogin()) {
                            return;
                        }
                        if (get().data?.customers_id) {
                            return;
                        }
                        if (get().isLoading) {
                            return;
                        }
                        set({ isLoading: true });
                        const userInfoRes = await getAccount();
                        // 设置cookie来判断是什么用户类型
                        setCookieItem(
                            IS_OLD_CUSTOMER_COOKIE_NAME,
                            userInfoRes.is_old_customer ? "1" : "0",
                            {
                                expires: 7,
                            },
                        );
                        set({ data: userInfoRes, isLoading: false });
                    },
                }),
                {
                    name: "user-info-storage",
                    storage: createJSONStorage(() => sessionStorage),
                },
            ),
            {
                name: "user-info-store",
            },
        ),
    );
};
