"use client";
import React, { useCallback, ReactNode } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionDetailsProps,
    AccordionSummaryProps,
} from "@mui/material";
import stl from "../../styles/accordion.module.scss";
import classnames from "classnames";
import { IIconProps } from "../FmIcon";
import { accordion } from "@/ui-component/store";
import { IClass } from ".";
// 重写title属性：一个接口方法，干掉要覆盖的属性，并上重新编写的属性接口
type ITitleProps = Partial<
    Omit<AccordionSummaryProps, "children" | "expandIcon" | "title">
> & {
    title: ReactNode;
    expandIcon?: Partial<IIconProps>;
};

type IDetailProps = Omit<AccordionDetailsProps, "children"> & {
    children: ReactNode;
};

// accordion === false, 则只有title, 没有children
interface INormalReactNode {
    isAccordion: false; // 因为有的会在accordion里穿插一些其他组件，即不会用上accordion的伸缩功能，此时不能直接另外启一个，因为无法管控当前展开属性的tabIndex, 故而需要这个属性以作区分
    title: ReactNode;
    details?: never;
}

interface ISummaryTitle extends ITitleProps {
    isAccordion?: true; // 因为有的会在accordion里穿插一些其他组件，即不会用上accordion的伸缩功能，此时不能直接另外启一个，因为无法管控当前展开属性的tabIndex, 故而需要这个属性以作区分
    // title: ReactNode;
    // expandIcon?: Partial<IIconProps>;
    details: IDetailProps;
}

export type ISingleAccordion = (INormalReactNode | ISummaryTitle) &
    AdditonAccordion;

export interface AdditonAccordion {
    // 不管是普通title 还是 summarytitle都要附带这个属性
    id: string;
    noBottomTitleLine?: boolean;
}
export interface IFmAccordionProps extends IClass {
    accordions: ISingleAccordion[];
    noBottomTitleLine?: boolean; // 是否有title-bottom-line, 默认item为先， 此为后，默认存在line
}

type Expanded = string | false;
interface IItemProps<T> extends IClass {
    item: ISummaryTitle & AdditonAccordion;
    noBottomTitleLine?: boolean; // false
}
const AccordionItemClient: React.FC<
    React.PropsWithChildren<IItemProps<Expanded>>
> = (props) => {
    const [expanded, changeExpanded] = accordion.useAccordionStore((state) => [
        state.expanded,
        state.changeExpanded,
    ]);

    const handleChange = useCallback(
        (expand: boolean, panel: string) => {
            changeExpanded?.(expand ? panel : "-1");
        },
        [changeExpanded],
    );
    const checkWhetherHasBottomLine = useCallback(
        (item: ISingleAccordion) => {
            return item.noBottomTitleLine ?? props.noBottomTitleLine ?? false;
        },
        [props.noBottomTitleLine],
    );
    return (
        <Accordion
            key={props.item.id}
            expanded={!!(expanded && expanded === props.item.id)}
            onChange={(e, expand) => handleChange(expand, props.item.id)}
            classes={{
                expanded: classnames(
                    stl.expanded,
                    stl.accordion_wrapper,
                    stl["expanded-class"],
                    props.rootExpandedClassName,
                ),
                root: classnames(
                    stl.accordion_root,
                    stl.addIndex,
                    "accordion_root",
                    props.rootClassName,
                    {
                        [classnames(
                            stl.expanded,
                            "expanded-root",
                            props.rootExpandedClassName,
                        )]: !!(expanded && expanded === props.item.id),
                    },
                ),
            }}
            color="inherit"
            square
        >
            {props.children}
            <AccordionDetails
                classes={{
                    root: classnames(
                        stl.children_container,
                        "children_container",
                        {
                            [stl["hidden-bottom-line"]]:
                                checkWhetherHasBottomLine(props.item),
                        },
                        props.accordionDetailsClassName,
                        props.item.details.classes?.root,
                    ),
                }}
                color="inherit"
            >
                {React.Children.map(props.item.details.children, (child) => {
                    if (React.isValidElement(child)) {
                        return React.cloneElement(child, {
                            className: classnames(
                                stl["child-font"],
                                "child-font",
                            ),
                        } as any);
                    }
                    return child;
                })}
                {/* {props.item.details.children} */}
            </AccordionDetails>
        </Accordion>
    );
};
export default AccordionItemClient;
