"use client";
import { ProductDetailsAction } from "@/store/modules/productDetails";
import { useContext } from "react";
import { useStore } from "zustand";
import { ProductDetailsContext } from "./Provider";

export function useProductDetailsContext<T>(
    selector: (state: ProductDetailsAction) => T,
): T {
    const store = useContext(ProductDetailsContext);
    if (!store)
        throw new Error("Missing ProductDetailsContext.Provider in the tree");
    return useStore(store, selector);
}

export { ProductDetailsProvider } from "./Provider";
