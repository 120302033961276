import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { PageInfo, PageInfoState, initialState } from "./initialState";

export type PageInfoStore = ReturnType<typeof createPageInfoStore>;

export const createPageInfoStore = (initProps?: Partial<PageInfoState>) => {
    const init = handlePageInfo(initialState, initProps);
    return create<PageInfo>()(
        devtools(
            (set, get) => ({
                ...init,
                setPageInfo(update: Record<string, any>) {
                    set(Object.assign(get(), update));
                },
                updateProductsListLayout: (isSingleCol: boolean) => {
                    set({ isSingleCol });
                },
            }),
            {
                name: "pageInfo-store",
            },
        ),
    );
};

export const handlePageInfo = (pageInfo: PageInfoState, temp: any) => {
    const {
        display_products_allow_page,
        display_products_hide_price,
        display_products_allow_filter,
        show_bubble,
        is_secondary,
        is_personalize,
        layout_type,
        openNewTab,
        show_sku,
        error,
        page_id,
        flowTemplateTag,
        list_layout_type,
        is_package,
        is_page_des,
    } = temp;

    return Object.assign(pageInfo, {
        pageData: temp,
        showPagination: display_products_allow_page === "Y",
        showPrice: display_products_hide_price === "N",
        showFilter: display_products_allow_filter === "Y",
        hideBubble: show_bubble === "N",
        is_secondary,
        is_personalize,
        layout_type,
        openNewTab,
        is_sku: show_sku === "Y",
        error: error,
        page_id,
        isSingleCol: list_layout_type === 0 && flowTemplateTag === "B",
        // 套餐包，若为true，url上带isPackge={page_id}
        isPackage: is_package || is_page_des,
    });
};
