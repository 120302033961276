import {
    PAYPAY_SUCCESS_CALLBACK,
    OCEANPAY_SUCCESS_CALLBACK,
    POST_COUPON,
    ORDER_STATUS,
    ORDERS,
    UNPAID_ORDERS_NUM,
    PAYMENT_ORDERS,
    ORDER_LOGS,
    ORDERS_REORDER,
} from "@/config/api";
// import { getCookieItem } from "@/lib/commonService";
// import { UNIONCHILDCODE_COOKIE_NAME } from "@/config/constConf";
import HandlePhpFetch from "@/src/utils/fetch/PhpFetch";
// 如果是优惠券用其他的
export const showOrderByInfo = (
    currency: string | undefined,
    addressId: number,
    shippingMethodId: number,
    coupon: string,
    isCoupon: boolean,
) => {
    const path = coupon ? POST_COUPON : "orders/showTotal";
    const params = {
        address_book_id: addressId,
        shipping_method: shippingMethodId,
        coupon_code: coupon,
        currency,
    };
    return HandlePhpFetch.get(path, params);
};

//重新下单
export const reReOrder = (orderId: number) => {
    return HandlePhpFetch.post(`${ORDERS}${orderId}/${ORDERS_REORDER}`);
};

//获取客户待支付订单数量
export const getUnpaidOrderNum = () => {
    return HandlePhpFetch.get(`${UNPAID_ORDERS_NUM}`);
};
// export const createOrder = ({
//   addressId,
//   shippingMethodId,
//   paymentId,
//   currency,
//   remark,
//   coupon,
//   billAddress = "",
//   show_payment_method = "",
// }) => {
//   //获取设备参数 //pc 1，移动， 2 ,平板 3
//   function getEquipment() {
//     var os = (function () {
//       var ua = navigator.userAgent,
//         isWindowsPhone = /(?:Windows Phone)/.test(ua),
//         isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
//         isAndroid = /(?:Android)/.test(ua),
//         isFireFox = /(?:Firefox)/.test(ua),
//         isChrome = /(?:Chrome|CriOS)/.test(ua),
//         isTablet =
//           /(?:iPad|PlayBook)/.test(ua) ||
//           (isAndroid && !/(?:Mobile)/.test(ua)) ||
//           (isFireFox && /(?:Tablet)/.test(ua)),
//         isPhone = /(?:iPhone)/.test(ua) && !isTablet,
//         isPc = !isPhone && !isAndroid && !isSymbian;
//       return {
//         isTablet: isTablet,
//         isPhone: isPhone,
//         isAndroid: isAndroid,
//         isPc: isPc,
//       };
//     })();
//     if (os.isAndroid || os.isPhone) {
//       // 手机
//       return 2;
//     } else if (os.isTablet) {
//       // 平板
//       return 3;
//     } else if (os.isPc) {
//       // pc
//       return 1;
//     }
//   }
//   let billaddress_info = billAddress ? getBillAdress(billAddress) : {};
//   const params = {
//     address_book_id: addressId,
//     shipping_method: shippingMethodId,
//     payment_method: paymentId,
//     show_payment_method: show_payment_method ? show_payment_method : paymentId,
//     coupon_code: coupon,
//     union_child_code: getCookieItem(UNIONCHILDCODE_COOKIE_NAME),
//     remark,
//     currency,
//     ...billaddress_info,
//     client_driver: getEquipment(), //1,2,3
//   };
//   return HandlePhpFetch.post("orders/create", params);
// };

export const paypalPayed = (order_number: number) => {
    return HandlePhpFetch.post(PAYPAY_SUCCESS_CALLBACK, { order_number });
};

export const oceanPayed = (order_number: number) => {
    return HandlePhpFetch.post(OCEANPAY_SUCCESS_CALLBACK, { order_number });
};

//关闭带支付订单
export const closeOrder = (order_id: number) => {
    return HandlePhpFetch.patch(ORDERS + order_id + ORDER_STATUS);
};

//获取订单支付信息
export const getPayment = (
    order_id: number,
    payment_method: string,
    show_payment_method?: string,
) => {
    return HandlePhpFetch.get(
        `${PAYMENT_ORDERS}${order_id}?payment_method=${payment_method}&show_payment_method=${
            show_payment_method ?? payment_method
        }`,
    );
};
// //获取客户待支付订单数量
// export const getUnpaidOrderNum = () => {
//   return HandlePhpFetch.get(`${UNPAID_ORDERS_NUM}`);
// };

// //修改订单信息
// export const changeOrder = (order_id, data) => {
//   return HandlePhpFetch.put(`${ORDERS}${order_id}`, data);
// };

// //创建订单日志
// export const creatOrderLogs = (order_id, data) => {
//   return HandlePhpFetch.post(`${ORDERS}${order_id}${ORDER_LOGS}`, data);
// };

// export const inputCoupon =
//   (coupon) => (currency, addressId, shippingMethodId, coupon) => {
//     return HandlePhpFetch.get(POST_COUPON, {
//       params: {
//         address_book_id: addressId,
//         shipping_method: shippingMethodId,
//         coupon_code: coupon,
//         currency,
//       },
//     });
//   };

// function getBillAdress(address) {
//   if (address.address_book_id) {
//     return {
//       bill_address_book_id: address.address_book_id,
//     };
//   }
//   return {
//     gender: address.entry_gender,
//     first_name: address.entry_firstname,
//     last_name: address.entry_lastname,
//     country: address.entry_country_id ? parseInt(address.entry_country_id) : "",
//     state_province: address.entry_zone_id
//       ? address.entry_zone_id
//       : address.entry_state,
//     post_zip_code: address.entry_postcode,
//     city: address.entry_city,
//     street_address: address.entry_street_address,
//     suburb: address.entry_suburb,
//     telephone: address.entry_telephone,
//     company: address.entry_company,
//     entry_cpf: address.entry_cpf,
//     mobile_number: address.entry_mobile_number,
//   };
// }
