// 处方模块，匹配web项目的rx模块

export interface IInsertGetPrescription {
    od_sph: number | null | string;
    od_cyl: number | null | string;
    od_axis: number | null | string;
    os_sph: number | null | string;
    os_cyl: number | null | string;
    os_axis: number | null | string;
    add: number | null | string;
    right_pd: number | null | string;
    left_pd: number | null | string;
    birth_year: number | null | string;
    pd: number | null | string;
    image_file_url: string | null;
    rx_type_val: string | null;
}

export interface IRenamedPerscription {
    prescription_name: string;
    twoPD?: boolean;
}
export interface IPrescriptionDataItem extends IRenamedPerscription {
    pres_id: number | null;
    pres_name?: string;
    prescription: Partial<IInsertGetPrescription>;
    pres_content?: string | null;
    type: number;
    have_pd?: number | null;
    date_added?: number | null;
    attachment: string | null;
    ukAcceptTerms?: any;
}
export interface IPrescriptionData {
    default_pre_id: number;
    prescription: IPrescriptionDataItem[];
}
export interface IPrescriptionState {
    isLoading: boolean;
    data: IPrescriptionData | null;
    activeData: IPrescriptionDataItem;
    error: string;
}

export type State = IPrescriptionState;

export type Action = {
    getRxS: (id?: number) => Promise<void>;
    setPrescription: (data: Partial<IInsertGetPrescription>) => void;
    setActiveData: (data: Partial<IPrescriptionDataItem>) => void;
};
export type PrescriptionAction = State & Action;
export const initActiveData = {
    twoPD: false,
    type: 0,
    pres_id: null,
    prescription_name: "", // 手动重命名
    attachment: null,
    pres_content: null,
    date_added: null,
    have_pd: null,
    prescription: {
        image_file_url: null,
        od_sph: null,
        od_cyl: null,
        od_axis: null,
        os_sph: null,
        os_cyl: null,
        os_axis: null,
        add: null,
        right_pd: null,
        left_pd: null,
        birth_year: null,
        pd: null,
    },
};
export const initialState: State = {
    isLoading: false,
    data: null,
    activeData: { ...initActiveData },
    error: "",
};
