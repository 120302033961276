"use client";
import React, {
    forwardRef,
    useCallback,
    useImperativeHandle,
    useRef,
} from "react";
import stl from "../../styles/tab.module.scss";
import { Tabs, TabsProps } from "@mui/material";
import classnames from "classnames";
interface IProps extends TabsProps {
    tabsClassName?: string;
    hiddenIndicator?: boolean;
}
const TabsClient = forwardRef(function TabsClient(
    props: React.PropsWithChildren<IProps>,
    ref,
) {
    const tabsRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(ref, () => {
        return tabsRef;
    }, []);
    const { onChange, tabsClassName, classes, ...rest } = props;
    const handleChange = useCallback(
        (e: React.SyntheticEvent<Element, Event>, value: number) => {
            onChange?.(e, value);
        },
        [onChange],
    );

    return (
        <Tabs
            classes={props.classes}
            onChange={(e, value) => handleChange(e, value)}
            indicatorColor="secondary"
            textColor="inherit"
            ref={tabsRef}
            {...rest}
        >
            {props.children}
        </Tabs>
    );
});

export default TabsClient;
