import classnames from "classnames";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import stl from "./appbar.module.scss";
import { IStyleProps } from "@/src/app/interface";
import FmThemeProvider, {
    createTheme,
} from "@/ui-component/basic/FmThemeProvider";
import FmAppBar, { AppBarProps } from "@/ui-component/basic/FmAppBar";
import variable from "@/src/styles/variables.module.scss";
interface IProps extends AppBarProps, IStyleProps {}
const darkTheme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: variable.text,
        },
    },
});

const AppBar = forwardRef(function AppBar(
    props: React.PropsWithChildren<IProps>,
    ref,
) {
    const appbarRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(
        ref,
        () => {
            return appbarRef;
        },
        [],
    );
    return (
        <FmThemeProvider theme={darkTheme}>
            <FmAppBar
                position={props.position ?? "relative"}
                color="primary"
                className={classnames(
                    stl.app_bar_container,
                    stl.app_content_wrapper,
                    stl["normal-header"],
                    "app-bar-container",
                    props.className,
                )}
                ref={appbarRef}
            >
                {props.children}
            </FmAppBar>
        </FmThemeProvider>
    );
});

export default AppBar;
