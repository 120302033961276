import {
    ES_SEARCH,
    ES_RRCENT_SEARCHES,
    ES_POPULAR_SEARCHES,
    ES_PRODUCT_SUGGESTIONS,
    CLEAN_RRCENT_SEARCHES,
    ES_ARTICLES_SUGGESTIONS,
    ES_SEARCHES_COUNT,
} from "@/config/javaApi";
import HandleJavaSearchFetch from "@/src/utils/fetch/JavaSearchFetch";
import { searchCotentClick } from "../utils";

export interface IEsSearch {
    search: string;
    type?: number;
    page?: number;
    pageSize?: number;
    filter?: Record<string, any>;
    searchType?: number;
}
// 同样是参数写这么多但是屁用没有
const defaultEsSearchData = {
    type: 0,
    page: 1,
    pageSize: 20,
    searchType: 1,
};
//es搜索接口
//es搜索接口
//searchType:0为实时搜索，1为点击搜索

export const SearchGaType = (type?: number) => {
    switch (type) {
        case 1:
            return "Products";
        case 2:
            return "Articles";
        case 3:
            return "Help";
        case 4:
            return "Orders";
        default:
            return "All";
    }
};

//searchType:0为实时搜索，1为点击搜索
export function esSearchData(esProps: IEsSearch) {
    const props: IEsSearch = {
        ...defaultEsSearchData,
        ...esProps,
        pageSize:
            esProps.type === 1
                ? 12
                : esProps.pageSize || defaultEsSearchData.pageSize,
    };
    return HandleJavaSearchFetch.post(ES_SEARCH, props).then((res) => {
        const { articles = {}, help = {}, products = {} } = res.data;
        let articles_t = articles.pagination ? articles.pagination.total : 0;
        let help_t = help.pagination ? help.pagination.total : 0;
        let products_t = products.pagination ? products.pagination.total : 0;
        searchCotentClick({
            search_category: SearchGaType(esProps.type),
            search_result_num:
                Number(articles_t) + Number(help_t) + Number(products_t),
            search_term: esProps.search,
        });
        return res;
    });
}

//热搜词搜索数据统计
export function getCountSearches(search: string) {
    return HandleJavaSearchFetch.post(ES_SEARCHES_COUNT, { search });
}

//获取用户搜索记录
export function getRecentSearches() {
    return HandleJavaSearchFetch.post(ES_RRCENT_SEARCHES);
}

//获取用户搜索记录
export function cleanRecentSearches() {
    return HandleJavaSearchFetch.post(CLEAN_RRCENT_SEARCHES);
}

//获取热搜搜索记录
export function getPopularSearches() {
    return HandleJavaSearchFetch.post(ES_POPULAR_SEARCHES);
}

//获取热销商品推荐
export function getProductSuggestions() {
    return HandleJavaSearchFetch.post(ES_PRODUCT_SUGGESTIONS);
}

//获取文章推荐
export function getArticlesSuggestions() {
    return HandleJavaSearchFetch.post(ES_ARTICLES_SUGGESTIONS);
}
