import { getSite } from "@/src/utils/configUtils";

// TODO: 这里可以直接改成site
export function openChat(host: string) {
    if (getSite(host) !== "fm") {
        if (parent.LC_API) {
            parent.LC_API.open_chat_window({ source: "minimized" });
        }
    } else {
        if (
            window.zE &&
            window.zE.activate &&
            typeof window.zE.activate === "function"
        ) {
            window.zE.activate();
        }
    }
}
