import React from "react";
import { IStyleProps } from "../app/interface";
import classnames from "classnames";
const HeightBar: React.FC<IStyleProps> = (props) => {
    return (
        <div
            className={classnames("height_bar", props.className)}
            style={props.style}
        ></div>
    );
};

export default HeightBar;
