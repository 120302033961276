"use client";
import { AppBar, AppBarProps } from "@mui/material";
import React, { forwardRef, useImperativeHandle, useRef } from "react";

const FmAppBar = forwardRef(function FmAppBar(props: AppBarProps, ref) {
    const appbarRef = useRef<HTMLElement>(null);
    useImperativeHandle(
        ref,
        () => {
            return appbarRef.current;
        },
        [],
    );
    return <AppBar {...props} ref={appbarRef}></AppBar>;
});

export default FmAppBar;
export { type AppBarProps };
