import React, { forwardRef, ReactNode, useMemo } from "react";
import classnames from "classnames";
import stl from "../../styles/viewList.module.scss";
import FmLoading from "../FmLoading";
import FooterClient, { IFooterProps } from "./FooterClient";

interface IRenderFooter {
    renderFooter: ReactNode;
}

// render-footer
const Footer = forwardRef(function Footer(
    props: IFooterProps & IRenderFooter,
    ref,
) {
    const { renderFooter, ...rest } = props;
    const noManualDom = useMemo(() => {
        return (
            props.loading &&
            (renderFooter ?? (
                <FmLoading
                    visible={props.loading}
                    className={classnames(
                        stl["set-no-margin"],
                        "set-no-margin",
                    )}
                />
            ))
        );
    }, [props.loading, renderFooter]);
    return (
        <FooterClient ref={ref} {...rest}>
            {noManualDom}
        </FooterClient>
    );
});

export default Footer;
