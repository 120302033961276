import classnames from "classnames";
import React, { forwardRef, ReactNode } from "react";
import stl from "../../styles/viewList.module.scss";
import ShowContentClient from "./ShowContentClient";
import { IChildren } from "@/ui-component/interface";
import FmListItem from "../FmListItem";
export interface IShowContentProps<T> extends IListChildrenProps, IChildren {
    noDataTips?: ReactNode; // 没有data时的提示
    hasListItem?: boolean;
    total: number;
}

interface IListChildrenProps {}

const ShowContent = forwardRef(function ShowContent<T>(
    props: IShowContentProps<T>,
    ref: React.Ref<unknown> | undefined,
) {
    return (
        <>
            {!props.total ? ( // 此处已经加了一个footer进来了，所以是至少有一个
                props.noDataTips ?? "no Data..."
            ) : (
                <ShowContentClient ref={ref}>
                    {props.hasListItem ?? false
                        ? props.children
                        : React.Children.map(
                              props.children,
                              (item: ReactNode, index: number) => {
                                  return index <
                                      React.Children.count(props.children) -
                                          1 ? (
                                      <FmListItem
                                          key={index}
                                          className={classnames(
                                              stl["list-item-container"],
                                          )}
                                      >
                                          {item}
                                      </FmListItem>
                                  ) : (
                                      item
                                  );
                              },
                          )}
                </ShowContentClient>
            )}
        </>
    );
});

export default ShowContent;
