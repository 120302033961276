import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import {
    PrescriptionAction,
    initialState,
    IInsertGetPrescription,
    IPrescriptionData,
    IPrescriptionDataItem,
} from "./initialState";
import { getRxN } from "@/src/models/lens/lensClient";
export type PrescriptionStore = ReturnType<typeof createPrescriptionStore>;
export const createPrescriptionStore = (initProps?: Record<string, any>) => {
    const init = { ...initialState, ...initProps };
    return create<PrescriptionAction>()(
        devtools(
            persist(
                (set, get) => ({
                    ...init,
                    getRxS: async (id?: number) => {
                        const result = await getRxN(id);
                        set({
                            data: result,
                            activeData: getDefaultData(result),
                            isLoading: false,
                            error: "",
                        });
                    },
                    setPrescription(data: Partial<IInsertGetPrescription>) {
                        const rest = {
                            ...get().activeData.prescription,
                            ...data,
                        };

                        set({
                            activeData: {
                                ...get().activeData,
                                prescription: rest,
                            },
                        });
                    },
                    setActiveData(
                        data: Partial<
                            IPrescriptionDataItem & IInsertGetPrescription
                        >,
                    ) {
                        const tempData = { ...get().activeData, ...data };
                        const {
                            twoPD,
                            type,
                            pres_id,
                            prescription_name,
                            attachment,
                            pres_content,
                            date_added,
                            have_pd,
                            prescription,
                            image_file_url = null,
                            od_sph = null,
                            od_cyl = null,
                            od_axis = null,
                            os_sph = null,
                            os_cyl = null,
                            os_axis = null,
                            add = null,
                            right_pd = null,
                            left_pd = null,
                            birth_year = null,
                            pd = null,
                            ...restTemp
                        } = tempData;
                        // 严格按照active原本的格式来处理对象，不要随意添加额外的属性值：1.剖出prescription属性，塞到prescription里面

                        set({
                            activeData: {
                                twoPD:
                                    twoPD ??
                                    !!(
                                        (!prescription.pd &&
                                            prescription.left_pd) ||
                                        prescription.right_pd
                                    ),
                                type,
                                pres_id,
                                prescription_name,
                                attachment,
                                pres_content,
                                date_added,
                                have_pd,
                                prescription: {
                                    image_file_url,
                                    od_sph,
                                    od_cyl,
                                    od_axis,
                                    os_sph,
                                    os_cyl,
                                    os_axis,
                                    add,
                                    right_pd,
                                    left_pd,
                                    birth_year,
                                    pd,
                                    ...prescription,
                                },
                                ...restTemp,
                            },
                        });
                    },
                }),
                {
                    name: "perscription-storage",
                    storage: createJSONStorage(() => sessionStorage),
                },
            ),
            {
                name: "perscription-store",
            },
        ),
    );
};

function getDefaultData(data: IPrescriptionData) {
    let formData =
        data.prescription?.filter(
            (item) => item.pres_id === data.default_pre_id,
        )[0] ?? [];

    if (!formData) {
        if (data.prescription.length === 0) {
            formData = { ...initialState.activeData, ...{ type: 0 } };
        } else {
            formData = data.prescription[data.prescription.length - 1];
            data.default_pre_id = formData.pres_id!;
        }
    }

    formData.prescription_name = formData.pres_name ?? "";
    formData.prescription = {
        ...initialState.activeData.prescription,
        ...formData.prescription,
    };
    formData = { ...initialState.activeData, ...formData };
    return formData;
}
