import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { login, traceKey, tryGo } from "@/src/models/loginModel";
import { bindTouristId } from "@/src/models/basket";
import { FmButton, FmInput, FmToast, FmCheckbox } from "@/ui-component";
import stl from "./loginModal.module.scss";
import { ISignInModalStateAndAction } from "@/store/modules/signInModals";
import classnames from "classnames";
import { emailValidate } from "@/src/utils";
import { useTranslation } from "@/src/i18n/client";
import acquisition from "@/src/components/LoadAnalysis/analysis/DataAcquisition";
import { setLocalItem } from "@/lib/commonService";
import { splitString, encryptValToen } from "@/src/utils/encrypt";
const rememberMeKey = "keeplogged";
interface IProps {
    loginCallback: ISignInModalStateAndAction["loginCallback"];
    hideAndSetLogin?: () => void;
    forgetPass: () => void;
}
const LoginContent: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { t } = useTranslation("login");
    const [loginEmail, setLoginEmail] = useState("");
    const [loginPassword, setLoginPassword] = useState("");

    const [keepLogin, setKeepLogin] = useState<
        string | number | (string | number | undefined)[] | undefined
    >(rememberMeKey);
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const autoFocusRef = useRef<HTMLInputElement>(null);
    const [showToast, setShowToast] = useState(false);
    const [causeError, setCauseError] = useState("");

    //页面切换自动定位到当前页面的第一个输入框，不用input的autofocus：tab显示会出错，且浏览器兼容性不好。
    useEffect(() => {
        const timer = setTimeout(() => {
            autoFocusRef.current?.focus();
        }, 300);
        return () => {
            clearTimeout(timer);
        };
    }, []);

    const emailBlur = useCallback(() => {
        setEmailError("");
        const res = emailValidate(loginEmail);
        res.error && setEmailError(t(res.message));
    }, [loginEmail, t]);

    const passwordBlur = useCallback(() => {
        setPasswordError("");
        if (!loginPassword) {
            setPasswordError(t("login:error:password"));
            return;
        }
        if (loginPassword.length < 5) {
            setPasswordError(t("login:error:incorrect password"));
        }
    }, [loginPassword, t]);

    const toggleKeepLogin = useCallback(
        (
            value:
                | string
                | number
                | (string | number | undefined)[]
                | undefined,
        ) => {
            setKeepLogin(value);
        },
        [],
    );

    // 清除所有error
    const clearAllError = useCallback(() => {
        setEmailError("");
        setPasswordError("");
        setCauseError("");
    }, []);

    const handleChange = useCallback(
        (
            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            name: string,
        ) => {
            clearAllError();
            switch (name) {
                case "login_email":
                    setLoginEmail(e.target.value);
                    break;
                case "login_password":
                    setLoginPassword(e.target.value);
                    break;
                default:
            }
        },
        [clearAllError],
    );

    const handleLogin = useCallback(
        async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setShowToast(true);
            emailBlur();
            passwordBlur();
            try {
                const traceId = await tryGo();
                const [firstHalf = "", secondHalf = ""] = splitString(traceId);
                const loginParams = {
                    email: loginEmail,
                    password: loginPassword,
                    sn: traceId[traceKey],
                    trace_id: firstHalf,
                    timer_id: secondHalf,
                };

                const res = await login(loginParams, !!keepLogin);
                setLocalItem("email", loginEmail);
                await bindTouristId();
                acquisition.login("UserId ", res?.customersId);
                props.hideAndSetLogin?.();
                props.loginCallback?.();
                !props.loginCallback && location.reload();
            } catch (e: any) {
                setCauseError(
                    e?.message ??
                        "there is something wrong in function handleLogin",
                ); // message
                return;
            } finally {
                setShowToast(false);
            }
        },
        [emailBlur, keepLogin, loginEmail, loginPassword, passwordBlur, props],
    );

    const handleFocus = useCallback((errorType: "email" | "password") => {
        switch (errorType) {
            case "email":
                setEmailError("");
                break;
            case "password":
                setPasswordError("");
                break;
            default:
        }
    }, []);

    const btnDisable = useMemo(
        () => !!(!loginEmail || !loginPassword || emailError || passwordError),
        [emailError, loginEmail, loginPassword, passwordError],
    );
    return (
        <>
            <div
                className={classnames(
                    stl["firmoo-login-container"],
                    "firmoo-login-container",
                )}
            >
                <div
                    className={classnames(stl["firmoo-register-error"], {
                        ["hidden"]: !causeError,
                    })}
                >
                    {causeError}
                </div>
                <FmInput
                    // focused
                    ref={autoFocusRef}
                    label={t("login:Email")}
                    value={loginEmail}
                    type="email"
                    name={"login_email"}
                    onBlur={emailBlur}
                    onChange={(e) => handleChange(e, "login_email")}
                    helperText={emailError}
                    onFocus={() => handleFocus("email")}
                    placeholder={t("login:EmailText")}
                    errorPosition={"top"}
                    className={stl["input-content"]}
                />
                <FmInput
                    label={t("login:Password")}
                    type="password"
                    value={loginPassword}
                    name={"login_password"}
                    onChange={(e) => handleChange(e, "login_password")}
                    helperText={passwordError}
                    onBlur={passwordBlur}
                    onFocus={() => handleFocus("password")}
                    placeholder={t("login:PasswordText")}
                    className={stl["input-content"]}
                />
                <div className={stl["radio-content"]}>
                    <FmCheckbox<string | number | undefined>
                        dataSource={[
                            {
                                label: t("login:KeepLogged"),
                                value: rememberMeKey,
                            },
                        ]}
                        onChange={(value) => {
                            toggleKeepLogin(value);
                        }}
                        values={keepLogin}
                    ></FmCheckbox>
                </div>
                <FmButton
                    disabled={btnDisable}
                    onClick={(e) => handleLogin(e)}
                    className={classnames(
                        stl["login-content"],
                        stl["login-btn"],
                        "login-btn",
                    )}
                    fullWidth
                    size={"large"}
                >
                    {t("login:Login")}
                </FmButton>
                <FmButton
                    onClick={props.forgetPass}
                    variant={"text"}
                    fullWidth
                    size="large"
                    className={stl["forget-password-text"]}
                >
                    {t("login:ForgetPassword")}
                </FmButton>
                <FmToast visible={showToast} type={"loading"} />
            </div>
            {props.children}
        </>
    );
};
export default LoginContent;
