import {
    IStyleProps,
    WholePropsIncludeAddPreFixAndOtherProps,
} from "../../interface";
import { LinkProps } from "next/link";
import React, { ReactNode } from "react";
import FmIcon, { IIconProps } from "../FmIcon";
import stl from "../../styles/link.module.scss";
import classnames from "classnames";
import FmThemeProvider from "../FmThemeProvider";
import Client from "./Client";
export type RenamedIconProps = WholePropsIncludeAddPreFixAndOtherProps<
    ILinkProps,
    Partial<IIconProps>,
    "icon"
>;

export interface ILinkProps extends LinkProps, IStyleProps {
    title?: string;
    children?: ReactNode;
    color?: string;
    target?: "_blank" | "_self" | "_parent" | "_top";
    dangerouslySetInnerHTML?: { __html: string | TrustedHTML };
    onClickCapture?: (e: any) => void;
}

export type INewLinkProps = ILinkProps & RenamedIconProps;
const LinkWithTheme = (props: INewLinkProps) => {
    // 至少隔离出带icon-前缀的属性
    const iconPre: Partial<RenamedIconProps> = {};
    const rest: Partial<ILinkProps> = {};
    for (let key in props) {
        if (key.toString().startsWith("icon")) {
            iconPre[key as keyof RenamedIconProps] =
                props[key as keyof INewLinkProps];
        } else {
            rest[key as keyof ILinkProps] = props[key as keyof INewLinkProps];
        }
    }

    return (
        <Client {...props} className={props.className}>
            {rest.children}
            {iconPre.icon && (
                <FmIcon
                    icon={iconPre.icon}
                    className={classnames(stl["link-icon"], "link-icon")}
                    {...iconPre}
                    fontSize={"inherit"}
                />
            )}
        </Client>
    );
};

const FmLink = (props: INewLinkProps) => (
    <FmThemeProvider>
        <LinkWithTheme {...props} />
    </FmThemeProvider>
);

export default FmLink;
