"use client";
import React, { useCallback, useMemo, useRef } from "react";
import { signInModal } from "@/store";
import {
    defaultHeaderContentHeight,
    isShowUserContentClass,
    useHeaderStore,
} from "@/store/pageModules/header";
import { useUserInfoContext } from "@/src/provider/UserInfo/context";
import UserLogin from "../../UserLogin";
import { isLogin } from "@/src/models/loginModel";
import { useShallow } from "zustand/react/shallow";
import { ExtendButton } from "@/ui-component";

interface IProps {}
const UserIconClient: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const [
        isShowUserContent,
        fixedHeader,
        headerContentHeight,
        headerContainerHeight,
        setHeaderOptions,
    ] = useHeaderStore(
        useShallow((state) => [
            state.isShowUserContent,
            state.fixedHeader,
            state.headerContentHeight,
            state.headerContainerHeight,
            state.setHeaderOptions,
        ]),
    ); // user-login显示or隐藏

    const data = useUserInfoContext((state) => state.data);
    const userLoginToolbarHeight = useMemo(() => {
        // 做这个isShowUserContent的前提验证是因为不知道怎么，在哪一步，当fixedHeader为true时，得到的高度不是整体高度而是tips高度，所以在点击展开那一刻重新获取值，拿到的是对的
        if (!isShowUserContent) {
            return 0;
        }
        return fixedHeader
            ? headerContentHeight ?? defaultHeaderContentHeight // 贴顶了没关系，因为高度是比较固定的
            : headerContainerHeight - window.scrollY; // 注意偏移量隐藏问题
    }, [
        fixedHeader,
        headerContainerHeight,
        headerContentHeight,
        isShowUserContent,
    ]);

    const userRef = useRef<HTMLDivElement>(null);
    const visible = signInModal.useSignInModalStore((state) => state.visible);
    const handleChangeUserContent = useCallback(
        (
            e: React.MouseEvent<HTMLSpanElement, MouseEvent> | null,
            show: boolean,
        ) => {
            // 点击的是头像 => 登录态 || 非登录态 > 显示showUserContent || 关闭showUsercontent ===> header的zIndex > 弹窗zIndex
            //1. e == null
            // 2. e => !null
            if (
                (e &&
                    userRef.current?.contains(e.target as Node) &&
                    (isLogin() || show || (!show && !visible))) ||
                !show
            ) {
                // 点击的是头像 => 登录态 || 非登录态 > 显示showUserContent || 关闭 || 点击的是非头像 => 收起来showContent
                setHeaderOptions({ starkSx: isShowUserContentClass });
            }

            setHeaderOptions({ isShowUserContent: show });
        },
        [setHeaderOptions, visible],
    );
    return (
        <>
            <ExtendButton
                ref={userRef}
                onClick={(e) => handleChangeUserContent(e, !isShowUserContent)}
            >
                {props.children}
            </ExtendButton>
            <UserLogin
                height={userLoginToolbarHeight}
                userInfo={data || {}}
                open={isShowUserContent}
                onChangeUserContent={handleChangeUserContent}
            />
        </>
    );
};

export default UserIconClient;
