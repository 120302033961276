/**
 * 骨架屏：供给header的search模块的products | all两个tab
 */
import React from "react";
import stl from "./serchProductsSkeleton.module.scss";
import classnames from "classnames";
import { FmSkeleton } from "@/ui-component";

const SingleImgSkeleton: React.FC = () => (
    <div className={stl["single-img-skeleton"]}>
        <FmSkeleton variant="rectangular" width={"100%"}>
            <div className={stl["rectangular-img"]}></div>
        </FmSkeleton>
        <FmSkeleton variant="text" style={{ marginTop: 10 }} />
    </div>
);
const Title: React.FC<{ title: string }> = (props) => {
    return (
        <FmSkeleton
            variant="text"
            style={{
                marginBottom: "0.2rem",
                display: "inline-block",
            }}
        >
            <div
                style={{
                    fontSize: "0.28rem",
                    marginBottom: "0.3rem"
                }}
            >
                {props.title}
            </div>
        </FmSkeleton>
    );
};
const SearchProductsSkeleton: React.FC = () => {
    return (
        // Your JSX code goes here
        <div
            className={classnames(
                stl.products_wrapper,
                stl["search-products-skeleton-container"],
            )}
        >
            <div className={stl["products-container"]}>
                <Title title={"Recent Searches:"} />
                <div className={stl["hot-search-container"]}>
                    {Array(3)
                        .fill("")
                        .map((item, index) => (
                            <FmSkeleton
                                variant="rectangular"
                                height={"0.4rem"}
                                width={"1.6rem"}
                                style={{
                                    margin: "0 0.2rem 0.2rem 0",
                                }}
                                key={index}
                            />
                        ))}
                </div>
            </div>
            <div className={stl["products-container"]}>
                {/* 用户搜索记录，未登录用户获取缓存的值，已登陆用户获取缓存+接口返回 */}
                {/* {recentSearchChildren} */}
                {/* 热搜关键词展示，前三个展示“火”图标 */}
                {/* 显示9个 */}
                <Title title={"Popular Searches:"} />
                <div
                    className={classnames(
                        stl["hot-search-container"],
                        "hot-search-container",
                    )}
                >
                    {Array(9)
                        .fill("")
                        .map((item, index) => (
                            <FmSkeleton
                                variant="rectangular"
                                height={"0.4rem"}
                                width={"1.6rem"}
                                style={{
                                    margin: "0 0.2rem 0.2rem 0",
                                }}
                                key={index}
                            />
                        ))}
                </div>
            </div>
            {/* 热销商品展示 */}
            <div
                className={classnames(
                    stl["hot-product-container"],
                    "hot-products-container",
                )}
            >
                <Title title={"best sellers:"} />
                <div className={stl.product_data_wrapper}>
                    {Array(6)
                        .fill("")
                        .map((item, index) => (
                            <SingleImgSkeleton key={index} />
                        ))}
                </div>
            </div>
        </div>
    );
};

export default SearchProductsSkeleton;
