/**
 * 为解决某些webview里无法正常facebook登录的问题
 */
import React, { useState, useEffect, CSSProperties, useCallback } from "react";
import { getEnv } from "@/src/utils/configUtils";
import stl from "./syncFacebookLogin.module.scss";
import { useCommonContext } from "@/src/provider";
import { useSearchParams } from "next/navigation";

interface IProps {
    style?: CSSProperties;
}
const FacebookBtn: React.FC<IProps> = (props) => {
    const [path, setPath] = useState<string | string[]>("");
    const { host } = useCommonContext();
    const redirect_uri = getEnv("BASE_URL", host) + "facebooklogin";
    const client_id = getEnv("FB_LOGIN", host);
    const searchParams = useSearchParams();
    const goToLogin = useCallback(
        (client_id: any, redirect_uri: string, backpath: string | string[]) => {
            if (!client_id || !redirect_uri || !backpath) return;
            let uri = `https://www.facebook.com/v3.3/dialog/oauth?client_id=${client_id}&redirect_uri=${redirect_uri}&state={"backpath":"${backpath}"}&scope=email`;
            window.location.href = encodeURI(uri);
        },
        [],
    );
    useEffect(() => {
        const url = searchParams.get("url");
        if (url) {
            setPath(url);
        } else {
            setPath("/");
        }
    }, [searchParams]);

    return (
        <div
            className={stl["facebook-btn"]}
            style={props.style}
            onClick={() => goToLogin(client_id, redirect_uri, path)}
        >
            Login With Facebook
        </div>
    );
};

export default FacebookBtn;
