//由于商品图片后端没有返回绝对路径给前端
//前端需要自己根据后端返回的数据进行组装图片 src
// 组装规则
/**
 * 1、图片
  - products_color_relation 里面 color_image_total 值，代表这个颜色有多少图片。 

 例如文档里面，color_image_total 为3 说明 该图片有三张图片 (分别为：1，2，3)

    照片 有三种尺寸，small- middle- 原图

****产品图片拼接:****
    小图：p/{products_id}/small-{color_image_total}-ogxf0w0l.jpg

    color_image_total 例如

   中图：p/{products_id}/middle-1-ogxf0w0l.jpg

   最大图：p/{products_id}/1-ogxf0w0l.jpg

****试戴图片拼接 /p/{products_id}/tryon-{color_id}.png****

****模特图说明****

每个产品下有一个字段 products_model 来表示 SPU 的模特图，里面有两个值，sort 和 image ，sort表示图片的顺序，image是图片

在每个色号下都有一个字段 products_color_model 来表示模特图，里面有两个值，sort 和 image ，sort表示图片的顺序，image是图片
 */
//商品

import { BASE_URL, IMG_HOST, S3_URL } from "@/config/api";

/**
 * 获取商品详情 模特图
 * 规则：数据源first_use_productes_model为空，则使用spare_products_model，两个为空方位空数组
 * @param {productId} 商品id
 * @param {imgSize} 图片尺寸  small/middle/large
 * @param {first_use_productes_model} 商品详情里面的products_color_relation 单项里面的products_model数组
 * @param {spare_products_model} 商品详情里面的products_model 数组备用显示
 */
function getProductMododelImgs(
    productId: string,
    imgSize = "middle",
    first_use_productes_model: any[] = [],
    spare_products_model: any[] = [],
) {
    if (!first_use_productes_model.length && !spare_products_model.length)
        return [];
    const baseData =
        (first_use_productes_model.length && first_use_productes_model) ||
        spare_products_model;
    //图片尺寸
    const size = `${imgSize === "large" ? "" : imgSize}`;
    const baseUrl = BASE_URL();
    //图片域名
    let imgHost = ["http://localhost:3000", "https://test.firmoo.com"].includes(
        baseUrl ?? "",
    )
        ? S3_URL()
        : IMG_HOST();
    //去掉最后一个 /

    const isLastHas = imgHost.lastIndexOf("/") === imgHost.length - 1;
    imgHost = isLastHas ? imgHost.substr(0, imgHost.length - 1) : imgHost;
    return (
        (baseData.map &&
            baseData.map((item) => {
                const { sort, image } = item;
                return `${imgHost}/p/${productId}/${
                    size ? `${size}-` : ""
                }${sort}-${image}`;
            })) ||
        []
    );
}

export { getProductMododelImgs };
