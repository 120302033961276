"use client";
import React from "react";
import { IStyleProps } from "@/src/app/interface";
import classnames from "classnames";
import stl from "./color.module.scss";

interface IProps extends IStyleProps, IAddtionProps {
    color_id: number;
}

export interface IAddtionProps {
    onChange?: (
        e: React.MouseEvent<HTMLLIElement, MouseEvent>,
        value: number,
    ) => void;
    selected?: number;
}
const GlassColorClient: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    return (
        <li
            className={classnames(
                stl["product-select-item-content"],
                "product-select-item-content-render-color-item",
                `selected-${props.selected}`,
                props.color_id,
                {
                    [stl["pruduct-select-item-active"]]:
                        props.selected === props.color_id,
                },
            )}
            data-selected={props.selected}
            style={props.style}
            onClick={(e) => props.onChange?.(e, props.color_id)}
        >
            {props.children}
        </li>
    );
};

export default GlassColorClient;
