import { uniqueArray } from "@/lib/commonService";
import { GenderConfig } from "@/store/modules/filterConditions/initialState";

export function outputCondition(condition: any) {
    const {
        color,
        frame,
        material,
        shape,
        size,
        gender,
        feature,
        page,
        ageRange,
    } = condition;

    const reqCondition: any = {
        colors: getVal(color),
        size: size ? size.val : "",
        products_frame: getVal(frame),
        products_metal: getVal(material),
        products_shape: getVal(shape),
        gender: getVal(gender),
        products_sex: getGender(gender),
        ageRange: getVal(ageRange),
        order_by: condition.sortBy.val,
        totalWidth: condition.totalWidth,
        lensWidth: condition.lensWidth,
        lensHeight: condition.lensHeight,
        bridgeDistance: condition.bridgeDistance,
        templeLength: condition.templeLength,
        diagonal: condition.diagonal,
        page: page,
        flowTemplateTag: condition.flowTemplateTag,
    };

    feature.forEach((element: any) => {
        reqCondition[element.val] = "Y";
    });
    return reqCondition;
}

function getGender(arr: GenderConfig) {
    let val: any[] = [];

    arr.forEach((element) => {
        val.push(element.val);
        if (element.val === 1) {
            val.push(1, 2);
        }
        if (element.val === 0) {
            val.push(0, 2);
        }
    });
    val = uniqueArray(val);

    return val.join(",");
}

function getVal(arr: any[]) {
    let val: any[] = [];
    arr.forEach((element) => {
        val.push(element.val);
    });

    return val.join(",");
}
