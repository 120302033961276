import React from "react";
import { FmSkeleton } from "@/ui-component";
import stl from "./helpcenterSkeleton.module.scss";

function HelpCenterItem() {
    return (
        <div className={stl["help-center-item"]}>
            <FmSkeleton variant="rectangular">
                <p>shipping and tracking</p>
            </FmSkeleton>
            <FmSkeleton variant="rectangular" width={"0.3rem"} />
        </div>
    );
}
const HelpCenterSkeleton: React.FC = () => {
    return (
        <div className={stl["help-center-skeleton-container"]}>
            {Array(8)
                .fill("")
                .map((item, index) => (
                    <HelpCenterItem key={index} />
                ))}
        </div>
    );
};

export default HelpCenterSkeleton;
