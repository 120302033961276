import { IProductPrice } from "@/src/components/ColorSelect/GlassColor";
import { ExcludeT1FrT2, ExtractT2FrT1 } from "@/src/app/interface";

// type和初始值initialState
export const defaultColor = {
    name: "",
    id: 0,
    // price: "",
    colorNum: 0,
    qty: 0,
    status: 0,
    follow: false,
    is_favorite: false,
    outOfStore: false,
    colorUnderMarket: false,
    is_notify: false,
    // onsale: {},
};

// IproductsColorRelation中的一部分属性会被改变成IActiveObj中的某些属性，所以那些被改变的属性在IProductsColorRelation中就不能要了，要干掉
export interface IDeletedProductsColorRelation {
    color_name: string;
    color_id: number;
    product_price: IProductPrice;
    color_number: number;
    color_status: number;
    color_qty: number;
    color_name_trans: string;
    follow?: {
        status: boolean;
    } | null;
}

export interface IActiveObj {
    index?: number;
    name: string;
    id: number;
    price?: IProductPrice;
    colorNum: number;
    qty: number;
    status: number;
    follow: boolean;
    is_favorite: boolean;
    outOfStore: boolean;
    colorUnderMarket: boolean;
    is_notify: boolean;
    onsale?: { price: string | number; showDiscount: boolean; salePer: number };
    color_name_trans?: string;
}

export interface IProductsColorRelation extends IDeletedProductsColorRelation {
    products_id: number;
    color_image: string;
    color_image_total: number;
    tryon_image: string;
    try_on: number;
    frame_image: string;
    frame_image_total: number;
    is_favorite: boolean;
    products_color_model: any[];
}

interface IProductsModelShow {
    sort: number;
    image: string;
    is_ai_generate: number;
}

// 传入的是这个接口属性，会对请求的这个接口的属性进行加工变成state的属性
export interface IProductDetailsInfo {
    products_id: number;
    products_price_org: string;
    products_price: string;
    products_price_sorter: string;
    brand_id: number;
    products_type: number;
    products_style: number;
    products_sex: number;
    products_metal: string;
    products_frame: string;
    products_shape: number;
    product_is_sunglasses: number;
    products_quantity: number;
    products_model: string;
    products_image: string;
    products_virtual: number;
    products_date_added: string;
    products_last_modified: string;
    products_date_available: null;
    products_weight: number;
    products_status: number;
    products_tax_class_id: number;
    manufacturers_id: number;
    products_ordered: number;
    products_quantity_order_min: number;
    products_quantity_order_units: number;
    products_priced_by_attribute: number;
    product_is_free: number;
    product_is_call: number;
    products_quantity_mixed: number;
    product_is_always_free_shipping: number;
    products_qty_box_status: number;
    products_quantity_order_max: number;
    products_sort_order: number;
    products_discount_type: number;
    products_discount_type_from: number;
    master_categories_id: number;
    products_mixed_discount_quantity: number;
    metatags_title_status: number;
    metatags_products_name_status: number;
    metatags_model_status: number;
    metatags_price_status: number;
    metatags_title_tagline_status: number;
    dimensions_lens: number;
    dimensions_bridge: number;
    dimensions_temple: number;
    dimensions_height: number;
    dimensions_total_width: number;
    dimensions_diagonal: number;
    dimensions_base_curve: number;
    dimensions_images: string;
    products_have_spring_hinge: number;
    product_is_featured: number;
    product_is_bifocal: number;
    products_have_sunmode: number;
    adjust_nose_pads: string;
    products_texing: string;
    youtube_url: string;
    youtube_sort: number;
    products_description_extra: string;
    products_months_ordered: number;
    products_display_number: string;
    products_pr: string;
    topic_total: number;
    multimedia_total: number;
    director_code: string;
    procurement_channel: number;
    hb_lens_width: string;
    hb_lens_height: string;
    hb_lens_diagonal: string;
    hb_lens_bridge: string;
    replaceable_lens: number;
    have_clips: number;
    deleted_at: null;
    sale_tag: number;
    have_screw: number;
    dimensions_2d_width: number;
    origin_products_metal: string;
    products_is_ce_mark: number;
    package: any[];
    products_date_added_stortime: number;
    products_model_show: IProductsModelShow[];
    products_reviews: number;
    products_sex_trans: string;
    recommend_sort: number;
    outside_processing_channel: false;
    products_frame_trans: string;
    products_name: string;
    products_description: string;
    products_qa: number;
    products_avg_rating: number;
    products_description_relation: {
        products_id: number;
        products_name: string;
        products_keyword: string;
        products_description: string;
        products_reviews: number;
        products_avg_rating: number;
        products_qa: number;
        products_ordered: number;
        products_recent_ordered: number;
        products_name_attribute: string | null;
        detailed_description: string;
    };
    products_color_relation: IProductsColorRelation[];
    products_video_list: any[];
    ageRange?: string;
}

export interface IInitedInfo {
    p_under_market: boolean;
    no_rx: boolean;
    isNeckglasses: boolean;
    review_num: number;
    avg_rating: number;
    is_hot: boolean;
    is_kids: boolean;
    is_new: boolean;
    isSun: boolean;
    rimless: boolean;
    is_show_3d_try_on: boolean;
    products_name_attribute: string | null;
    meedPackage: boolean;
}

export interface IProductDetailsState {
    loading: boolean;
    productDetail: Partial<IInitedInfo & IProductDetailsInfo>;
    activeObj: Partial<ColorItem>;
    errors: string;
    packageId: number | null;
    favorite: any[];
    isRiskControl: boolean;
    isPc: boolean;
    modelImgList: any[];
    productImgList: any[];
    closePanel: null;
    tabKey: string;
    colorList: ColorItem[]; // 有两个一样的属性，对原始属性做前缀处理
    video: string;
}

// 1.分离出要被rename的属性 => renamed(IActiveObj) & noRenamedProps;
// 2. noRenamedProps要处理掉和renamed(IActiveObj)重名的属性，添加前缀;
// 3. 三部分并起来 => ColorItem
type NoRenamedProps = ExcludeT1FrT2<
    IProductsColorRelation,
    IDeletedProductsColorRelation
>;
type PrevNoRenamedProps = ExtractT2FrT1<
    IActiveObj,
    NoRenamedProps,
    "productDetails_"
>;
export type ColorItem = PrevNoRenamedProps & NoRenamedProps & IActiveObj;

export type State = IProductDetailsState;

export type Action = {
    getProductsDetail: (id: number) => Promise<void>;
    changeColor: (colorId: string | number, status?: string) => void;
    changeFollow: (colorId: string | number) => void;
    changeUnderMarket: (colorId: string | number) => void;
    changeStatus: (colorId: string | number) => void;
};
export type ProductDetailsAction = State & Action;

export const initialState: State = {
    loading: false,
    productDetail: {},
    activeObj: defaultColor,
    errors: "",
    packageId: 0,
    favorite: [],
    isRiskControl: false,
    isPc: false,
    modelImgList: [],
    productImgList: [],
    closePanel: null,
    tabKey: "1",
    colorList: [],
    video: "",
};
