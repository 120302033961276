// 邮箱验证
export function emailValidate(email: string): Record<string, any> {
    const res: Record<string, any> = {
        error: false,
        message: "",
    };
    const _regexp =
        /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;

    if (!email.trim().length) {
        res.message = "login:error:email";
        res.error = true;
    } else if (!_regexp.test(email)) {
        res.message = "login:error:address";
        res.error = true;
    }
    return res;
}
