"use client";
import {
    FilterConditionProps,
    FilterConditionStore,
    createFilterConditionStore,
} from "@/store/modules/filterConditions";
import { createContext, useRef } from "react";

export const FilterConditionContext =
    createContext<FilterConditionStore | null>(null);

interface FilterConditionProviderProps
    extends React.PropsWithChildren<Partial<FilterConditionProps>> {}

export function FilterConditionProvider({
    children,
    ...props
}: FilterConditionProviderProps) {
    const storeRef = useRef<FilterConditionStore>();
    if (!storeRef.current) {
        storeRef.current = createFilterConditionStore(props);
    }
    return (
        <FilterConditionContext.Provider value={storeRef.current}>
            {children}
        </FilterConditionContext.Provider>
    );
}
