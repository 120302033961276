/**
 *
 * java api 定义
 *
 *
 */

export const ES_SEARCH = "front/search";

export const ES_RRCENT_SEARCHES = "front/person/history";

export const ES_SEARCHES_COUNT = "/front/search/count/add";

export const CLEAN_RRCENT_SEARCHES = "front/person/history/clear";

export const ES_POPULAR_SEARCHES = "front/hot/word";

export const ES_PRODUCT_SUGGESTIONS = "front/product/hot";

export const ES_ARTICLES_SUGGESTIONS = "front/page/recommend";

// export const ACTIVITY_USER_POSTER_INFO = 'front/activity/userPosterInfo';

// export const ACTIVITY_SIGN_UP = "front/activity/signUp";
