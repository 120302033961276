"use client";
import { useContext } from "react";
import { useStore } from "zustand";
import { PageInfoContext } from "./Provider";
import { PageInfo } from "@/store/modules/pageInfo";

export function usePageInfoContext<T>(selector: (state: PageInfo) => T): T {
    const store = useContext(PageInfoContext);
    if (!store) throw new Error("Missing PageInfoContext.Provider in the tree");
    return useStore(store, selector);
}

export { PageInfoProvider } from "./Provider";
