import { useState, useEffect } from "react";

const useUpdateEffect = (fun: Function, deps: Array<any>) => {
  const [count, setCount] = useState(0);
  useEffect(() => {
    setCount((count) => count + 1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  useEffect(() => {
    count > 1 && fun();
  }, [count, fun]);
};

export default useUpdateEffect;
