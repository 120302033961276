/**
 * 目前只有productDetail被用上了
 */
import { Url } from "next/dist/shared/lib/router/router";
import { UrlObject } from "url";
import parse_url from "./parseUrl";

interface IConversion {
    href: string;
    query: Record<string, any> | null;
    as?: Url;
}

interface IQuery {
    products_id: string;
    lensColor: string;
    is_package: string;
    basketId: string;
    orders_oid: string;
}

interface IAsPath {
    query: IQuery;
    pathname: string;
}

export type AsPath = IAsPath | Url;
export interface IConversionReturned {
    pathname: string;
    asPath: Url;
    query: Record<string, any>;
}
export const oldPathNewMap: Record<string, any> = {
    "/account": { oldPath: "account", newPath: "/account", query: {} },
    "/account/prescription": {
        oldPath: "account_prescription",
        newPath: "/account/prescription",
        query: {},
    },
    "/account/referralProgram": {
        oldPath: "account_referralProgram",
        newPath: "/account/referralProgram",
        query: {},
    },
    "/account/myAccount": {
        oldPath: "account_overview",
        newPath: "/account/myAccount",
        query: {},
    },
    "/account/photo": {
        oldPath: "account_photo",
        newPath: "/account/photo",
        query: {},
    },
    "/account/wallet": {
        oldPath: "account_account",
        newPath: "/account/wallet",
        query: {},
    },
    "/account/favorite": {
        oldPath: "account_favorite",
        newPath: "/account/favorite",
        query: {},
    },
    "/account/personalInfo": {
        oldPath: "account_edit",
        newPath: "/account/personalInfo",
        query: {},
    },
    "/account/location": {
        oldPath: "address_book",
        newPath: "/account/location",
        query: {},
    },
    "/account/password": {
        oldPath: "account_password",
        newPath: "/account/password",
        query: {},
    },
    "/account/newsletter": {
        oldPath: "account_newsletters",
        newPath: "/account/newsletter",
        query: {},
    },
    "/passwordforgotten": {
        oldPath: "password_forgotten",
        newPath: "/passwordforgotten",
        query: {},
    },
    "/account/program": {
        oldPath: "account_union",
        newPath: "/account/program",
        query: {},
    },
    "/account/orderinfo": {
        oldPath: "account_history_info",
        newPath: "/account/orderinfo",
        query: {},
    },
    "/account/invoice": {
        oldPath: "invoice.php",
        newPath: "/account/invoice",
        query: {},
    },
    // "/basket": { oldPath: "shopping_cart", newPath: "/search", query: {} },
    "/login": { oldPath: "login", newPath: "/login", query: {} },
    "/register": { oldPath: "register", newPath: "/register", query: {} },
    "/lens": { oldPath: "lens", newPath: "/lens", query: {} },
    "/preLens": { oldPath: "preLens", newPath: "/preLens", query: {} },
    "/productDetail": {
        oldPath: "/(eyeglasses|sunglasses)-p-[0-9]*.html",
        newPath: "/productDetail",
        query: {},
    },
    "/help": { oldPath: "/help.shtml", newPath: "/help", query: {} },
    "/helpList": {
        oldPath: "/help-c-[0-9]*.shtml",
        newPath: "/helpList",
        query: {},
    },
    "/helpInfo": {
        oldPath: "/help-p-[0-9]*.shtml",
        newPath: "/helpInfo",
        query: {},
    },
    "/search": {
        oldPath: "/search-eyewear-keyword-.*.html",
        newPath: "/search",
        query: {},
    },
    "/review": { oldPath: "reviews", newPath: "/search", query: {} },
    "/review/reviewdetail": {
        oldPath: "reviews_info",
        newPath: "/search",
        query: {},
    },
    "/checkout": { oldPath: "checkout", newPath: "/checkout", query: {} },
    "/checkoutSuccess": {
        oldPath: "checkout_success",
        newPath: "/checkout",
        query: {},
    },
    "/specsubs": { oldPath: "", newPath: "/checkout", query: {} },
    "/addons": {
        oldPath: "/z/accessories.html",
        newPath: "/addons",
        query: {},
    },
};

export default function conversion(props: IConversion): IConversionReturned {
    const { href, query, as } = props;
    const newHref = url_(href);
    const path = newHref.split("?")[0].replace("/pc", ""); // 清除pc
    let asPath: Url = as ?? href;
    let pathname = path;
    let newQuery = query ?? {};
    Object.assign(newQuery, parse_url(href ?? ""));
    let asQuery: Record<string, any> = {};

    if (
        !(
            oldPathNewMap[path] ||
            /\/(productDetail|helpList|helpInfo)/.test(path)
        )
    ) {
        return {
            pathname,
            asPath,
            query: newQuery,
        };
    }
    // else ↓
    //判断是否存在，//专题页可以附加
    if (
        path.match(
            /\/(account|basket|login|register|checkout|lens|preLens|passwordforgotten)/,
        ) &&
        path.indexOf("invoice") === -1
    ) {
        asPath = {
            pathname: "/index.php",
            query: { main_page: oldPathNewMap[path].oldPath, ...newQuery },
        };
    } else if (oldPathNewMap[path] === "/checkoutSuccess") {
        asPath = {
            pathname: "/index.php",
            query: { main_page: oldPathNewMap[path].oldPath, ...newQuery },
        };
    } else if (path.match(/\/(review)/)) {
        asPath = {};
    } else if (path.indexOf("productDetail") !== -1) {
        //产品页面有三种URL
        let glasses = "eyeglasses";

        const glassesTypeObj = {
            goggles: ["3", "4", "9"],
            sunglasses: ["6", "7"],
            eyeglasses: ["1"],
        };
        for (let obj of Object.entries(glassesTypeObj)) {
            if (obj[1].includes(newQuery.master_categories_id)) {
                glasses = obj[0];
                break;
            }
        }
        let _query = { ...newQuery };
        if (!_query.color) {
            delete _query.color;
        }
        delete _query.master_categories_id;
        if (!_query.is_package) {
            delete _query.is_package;
        }

        asPath = {
            pathname: `/${glasses}-p-${path.split("/")[2]}.html`,
            query: _query,
        };
    } else if (path.indexOf("helpList") !== -1) {
        //帮助
        asPath = `/help-c-${path.split("/")[3]}.shtml`;
        newQuery.help = "helpList";
        newQuery.helpId = path.split("/")[3];
    } else if (path.indexOf("helpInfo") !== -1) {
        //帮助
        asPath = `/help-p-${path.replace(/[^0-9]*/, "")}.shtml`;
        newQuery.help = "helpInfo";
        newQuery.helpId = path.split("/")[3];
    } else if (path.indexOf("search") !== -1) {
        //搜索
        const match = /\?keyword=(.+)&type=/.exec(href);
        if (match) {
            newQuery.keyword = match[1];
        }
        asPath = {
            pathname: `/search-eyewear-keyword-${
                newQuery.keyword ? newQuery.keyword : ""
            }.html`,
        };
    } else if (path.includes("/specsubs") || path.includes("/specSub")) {
        asPath = as || path;
        if (typeof as === "object") asPath = as;
        if (typeof as === "string") asPath = { pathname: as };
        asQuery = { ...newQuery };
        if (asQuery.id) delete asQuery.id;
    } else {
        asPath = oldPathNewMap[path].oldPath;
    }

    if (Object.prototype.toString.call(asPath) === "[object Object]") {
        Object.assign(
            (asPath as UrlObject).query as Record<string, any>,
            asQuery,
        );
        (asPath as UrlObject).pathname = url_(
            (asPath as UrlObject).pathname as string,
        );
        pathname = (asPath as UrlObject).pathname!;
    } else {
        let param = "",
            currentParam = "";
        for (let name in asQuery) {
            currentParam = `${name}=${asQuery[name]}`;
            param += `${param.length ? "&" : ""}${currentParam}`;
        }
        asPath += `${(asPath as string).indexOf("?") < 0 ? "?" : "&"}${param}`;

        asPath = url_(asPath as string);
        pathname = path;
    }

    return {
        pathname: pathname,
        asPath: asPath,
        query: newQuery,
    };
}

function url_(url: string) {
    if (!/^\//.test(url)) {
        return `/${url}`;
    }
    return url; // 有'/'
}
