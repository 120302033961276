import { ES_SEARCH_TYPE } from "@/config/constConf";
import { IEsSearch } from "@/src/models/esSearch";
// type和初始值initialState
interface ISearchTypeProps {
    label: "All" | "Products" | "Articles" | "HelpCenter" | "orders";
    value: number;
    placeholder: string;
}
export interface ISearchInfoProps {
    code: number;
    isLoading: boolean;
    type: number; //0:所有，1:商品 2：帮助中心 3:文章 4:order
    type_loading: boolean;
    search: string;
    keywords: string; //搜索页面记录原始搜索内容
    key_type: number; //搜索页面记录原始搜索类型
    search_type_list: ISearchTypeProps[];
    data: Record<string, any>;
    linkUrl: string;
    isShowOrder: boolean; //展示订单搜索内容
    help: {
        pagination: Record<string, any>;
        data: any[];
    };
    articles: {
        pagination: Record<string, any>;
        data: any[];
    };
    products: {
        pagination: Record<string, any>;
        data: any[];
    };
    hot_products: any[]; // search-component
    resent_keys: any[]; // search-component
    hot_keys: any[];
    hot_articles: any[];
    ad_data: any[];
    error_tip: string;
    empty_error: boolean;
    isShowError: boolean; //搜索不到内容，展示错误提示
    errors: string;
}

export type State = ISearchInfoProps;

export type ISearchInfoStateAndAction = State & Action;
export type Action = {
    setData: (data: Partial<State & IEsSearch>) => Promise<void>;
    getArticlesSuggestions: () => void;
    onSearchError: (error_tip: string) => void;
    onOrderShowChange: (isShow: boolean) => void;
    getSearchData: (data: IEsSearch) => Promise<void>;
};

export const search_type_list: ISearchTypeProps[] = [
    {
        label: "All",
        value: ES_SEARCH_TYPE.ALL,
        placeholder: "search",
    },
    {
        label: "Products",
        value: ES_SEARCH_TYPE.PRODUCTS,
        placeholder: "ProductTip",
    },
    {
        label: "Articles",
        value: ES_SEARCH_TYPE.ARTICLES,
        placeholder: "ArticlesTip",
    },

    {
        label: "HelpCenter",
        value: ES_SEARCH_TYPE.HELP,
        placeholder: "HelpTip",
    },
    {
        label: "orders",
        value: ES_SEARCH_TYPE.ORDERS,
        placeholder: "OrderTip",
    },
];

export const initialState: State = {
    code: 200, // 200应该是正常的
    isLoading: false,
    type: 1, //0:所有，1:商品 2：帮助中心 3:文章 4:order
    type_loading: false,
    search: "",
    keywords: "", //搜索页面记录原始搜索内容
    key_type: 0, //搜索页面记录原始搜索类型
    search_type_list,
    data: {},
    linkUrl: "",
    isShowOrder: false, //展示订单搜索内容
    help: {
        pagination: {},
        data: [],
    },
    articles: {
        pagination: {},
        data: [],
    },
    products: {
        pagination: {},
        data: [],
    },
    hot_products: [],
    resent_keys: [],
    hot_keys: [],
    hot_articles: [],
    ad_data: [],
    error_tip: "",
    empty_error: false,
    isShowError: false, //搜索不到内容，展示错误提示
    errors: "",
};
