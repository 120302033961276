"use client";
import React, { useMemo } from "react";
import FmToolbar from "@/ui-component/basic/FmToolbar";
import { useHeaderStore } from "@/store/pageModules/header";
import { useShallow } from "zustand/react/shallow";

const OutSideDomContainer = ({
    hiddenWhenNoFixed,
}: {
    hiddenWhenNoFixed: boolean;
}) => {
    const [fixedHeader, headerContentHeight] = useHeaderStore(
        useShallow((state) => [state.fixedHeader, state.headerContentHeight]),
    ); // user-login显示or隐藏
    const toolbarHeight = useMemo(() => {
        if (hiddenWhenNoFixed) {
            return 0;
        }
        return fixedHeader ? headerContentHeight ?? "1rem" : 0;
    }, [fixedHeader, headerContentHeight, hiddenWhenNoFixed]);

    return (
        /* 用于header脱离文档流时垫片处理，撑开实际高度 */
        <FmToolbar
            className={"tool-bar-of-user-login"}
            style={{
                margin: 0,
                minHeight:
                    typeof toolbarHeight === "string"
                        ? toolbarHeight
                        : `${toolbarHeight}px`,
            }}
        />
    );
};

export default OutSideDomContainer;
