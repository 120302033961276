import { usages } from "@/config/useages";

const singleVision = usages[0];
//看远参数
const distance = singleVision.children[0];
//看近参数
const reading = singleVision.children[2];
//镜片用途,用于镜片流程自定义
const _usages = [
    {
        name: "distance",
        distance_type: 1,
        option_id: distance.optionId,
        option_value_id: distance.value,
    },
    {
        name: "reading",
        distance_type: 2,
        option_id: reading.optionId,
        option_value_id: reading.value,
    },
    {
        name: "no-pre",
        distance_type: 0,
        option_id: 0,
        option_value_id: 0,
    },
];

interface IUsage {
    name: string;
    distance_type: number;
    option_id: number;
    option_value_id: number;
}
// type和初始值initialState
export interface IPackageInfoState {
    isLoading: boolean;
    isForceRx: boolean;
    type: Record<string, any>;
    usage: Record<string, any>;
    usages: IUsage[];
    active_package: Record<string, any>;
    isPackage: boolean;
    page_descriptions: Record<string, any>;
    is_rx: boolean;
    isLowPrice: boolean;
    packageList: any[];
    isDriving: boolean;
    error: string;
    isBlue?: boolean;
    packageId?: number;
}

export type State = IPackageInfoState;

export type Action = {
    setPackageInfo: (data: State) => void;

    //选择套餐
    selectPackage: (active_package: Record<string, any>) => void;

    //选择套餐
    selectUsage: (usage: Partial<IUsage>) => void;
};
export type IPackageInfoAction = State & Action;

export const initialState: State = {
    isLoading: false,
    isForceRx: false,
    type: {},
    usage: {},
    usages: _usages,
    active_package: {},
    isPackage: false,
    page_descriptions: {},
    is_rx: true,
    isLowPrice: false,
    packageList: [],
    isDriving: false,
    error: "",
    isBlue: false,
};
