"use client";
import {
    ProductPackageStore,
    createProductPackageStore,
    IinitProps,
} from "@/store/modules/productPackage";
import { createContext, useRef } from "react";
export const ProductPackageContext = createContext<ProductPackageStore | null>(
    null,
);

interface ProductPackageProviderProps
    extends React.PropsWithChildren<IinitProps> {}

export function ProductPackageProvider({
    children,
    ...props
}: ProductPackageProviderProps) {
    const storeRef = useRef<ProductPackageStore>();
    if (!storeRef.current) {
        storeRef.current = createProductPackageStore(props);
    }
    return (
        <ProductPackageContext.Provider value={storeRef.current}>
            {children}
        </ProductPackageContext.Provider>
    );
}
