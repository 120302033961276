import { IProps } from "@/src/productDetails/ProductPackage/ProductPackageItem";
export interface IProductPackage {
    loading: boolean;
    isShowPackBtn: boolean;
    packData: Partial<IProps["data"]>;
    package_data: any[];
    packDataIndex: number;
    pageNum: number;
    pageSize: number;
    total: number;
    errors: string;
}
export type State = IProductPackage;

export interface IProductsPackage {
    productsId: number;
    pageId: number;
}
export type Action = {
    getProductsPackage: ({
        productsId,
        pageId,
    }: IProductsPackage) => Promise<void>;
    selectPackDataIndex: (packDataIndex: number) => void;
};
export type ProductPackageAction = State & Action;

export interface IProductsPackageServerDataProps {
    data: any[];
    time: number;
    pageNum: number;
    pageSize: number;
    total: number;
}
export const initialState: State = {
    loading: false,
    isShowPackBtn: false,
    packData: {},
    package_data: [],
    packDataIndex: -1,
    pageNum: 1,
    pageSize: 12,
    total: 0,
    errors: "",
};
