"use client";
import { useContext } from "react";
import { useStore } from "zustand";
import { UserInfoContext } from "./Provider";
import { IUserInfoStateAndAction } from "@/store/modules/userInfo";

export function useUserInfoContext<T>(
    selector: (state: IUserInfoStateAndAction) => T,
): T {
    const store = useContext(UserInfoContext);
    if (!store) throw new Error("Missing UserInfoContext.Provider in the tree");
    return useStore(store, selector);
}

export { UserInfoProvider } from "./Provider";
