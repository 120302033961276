"use client";
import { IReviewAndFqAndAction } from "@/store/modules/reviewAndFq";
import { useContext } from "react";
import { useStore } from "zustand";
import { ReviewAndFqContext } from "./Provider";

export function useReviewAndFqContext<T>(
    selector: (state: IReviewAndFqAndAction) => T,
): T {
    const store = useContext(ReviewAndFqContext);
    if (!store)
        throw new Error("Missing ReviewAndFqContext.Provider in the tree");
    return useStore(store, selector);
}

export { ReviewAndFqProvider } from "./Provider";
